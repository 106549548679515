import { CourseDescriptorDto } from "@masterschool/course-builder-api"

export function uuidToShortId(uuid: string): string {
  // Step 1: Parse the UUID and remove dashes
  const cleanUUID = uuid.replace(/-/g, "")

  // Step 2: Extract a portion of the UUID for the digits
  const digitHex = cleanUUID.slice(2, 10) // Next 8 hex digits (32 bits)
  const digitNumber = parseInt(digitHex, 16) % 10000 // Map to 0-9999
  const digits = digitNumber.toString().padStart(4, "0") // Ensure 4 digits

  return `C${digits}`
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export type CourseMajorDescriptorDto = CourseDescriptorDto & {
  courseId: string
}

export function createUniqueIdForCourse(
  course: CourseDescriptorDto,
): CourseMajorDescriptorDto {
  const { id, version, createdAtTimestamp } = course
  return {
    ...course,
    courseId: id,
    version,
    id: `${id}-${version}`,
    createdAtTimestamp: new Date(createdAtTimestamp).getTime(),
  }
}
