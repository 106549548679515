import { TabIdentifierEnumeration } from "@features/coursesMenu/coursesMenuSlice"
import { FilterName, SearchAdvanceFilter } from "@features/search/searchSlice"
import { Syllabus } from "@features/syllabus/syllabusSelectors"
import {
  CourseDescriptorDto,
  CourseStatus,
  CourseType,
} from "@masterschool/course-builder-api"

export const filterCourses = (
  courses: CourseDescriptorDto[],
  syllabuses: Syllabus[],
  searchText: string,
  filters: SearchAdvanceFilter[],
  tab: TabIdentifierEnumeration,
  favoriteCourses: string[],
  selectedType: CourseType,
) => {
  const byType = courses.filter((course) => course.type === selectedType)
  const withoutOldVersions = filterCoursesOldVersions(byType)
  const bySearchText = filterCoursesBySearchText(withoutOldVersions, searchText)
  const byTab = filterCoursesByTab(bySearchText, tab, favoriteCourses)
  return filterCoursesByAdvanceFilter(byTab, filters, syllabuses)
}

export const filterCoursesOldVersions: (
  c: CourseDescriptorDto[],
) => CourseDescriptorDto[] = (courses: CourseDescriptorDto[]) =>
  Object.values(
    courses.reduce((acc: { [key: string]: any }, current) => {
      if (
        !acc[current.id] ||
        parseFloat(current.version) > parseFloat(acc[current.id].version) ||
        current.status !== CourseStatus.Draft
      ) {
        acc[current.id] = current
      }
      return acc
    }, {}),
  )

export const filterCoursesBySearchText = (
  courses: CourseDescriptorDto[],
  searchText: string,
) => {
  if (searchText === "") {
    return courses
  }

  const filteredByName = courses.filter((course) =>
    course.name.toLowerCase().includes(searchText.toLowerCase()),
  )

  const filterByDescription = courses.filter((course) =>
    course.description.toLowerCase().includes(searchText.toLowerCase()),
  )

  const filterByTitle = courses.filter((course) =>
    course?.title?.toLowerCase().includes(searchText.toLowerCase()),
  )

  const filterByShortTitle = courses.filter((course) =>
    course?.shortTitle?.toLowerCase().includes(searchText.toLowerCase()),
  )

  return [
    ...new Set([
      ...filterByDescription,
      ...filteredByName,
      ...filterByTitle,
      ...filterByShortTitle,
    ]),
  ]
}

const filterCoursesByTab = (
  courses: CourseDescriptorDto[],
  tab: TabIdentifierEnumeration,
  favoriteCourses: string[],
) => {
  if (tab === TabIdentifierEnumeration.All) {
    return courses
  }

  if (tab === TabIdentifierEnumeration.Favorites) {
    return courses.filter((course) => favoriteCourses.includes(course.id))
  }

  return courses.filter((course) => compareTabAndStatus(tab, course.status))
}

const compareTabAndStatus = (
  tab: TabIdentifierEnumeration,
  status: CourseStatus,
) => {
  if (tab === TabIdentifierEnumeration.Published) {
    return status === CourseStatus.Published
  }

  if (tab === TabIdentifierEnumeration.Draft) {
    return status === CourseStatus.Draft
  }

  if (tab === TabIdentifierEnumeration.Archived) {
    return status === CourseStatus.Archived
  }

  return false
}

function filterCoursesByAdvanceFilter(
  courses: CourseDescriptorDto[],
  filters: SearchAdvanceFilter[],
  syllabuses: Syllabus[],
) {
  const domainFilter = filters.find(
    (filter) => filter.filterName === FilterName.Domains,
  )
  const syllabusFilter = filters.find(
    (filter) => filter.filterName === FilterName.Syllabuses,
  )
  if (!domainFilter && !syllabusFilter) {
    return courses
  }

  const filteredByDomains = filterCoursesByDomains(
    courses,
    domainFilter?.selectedValues ?? [],
  )

  const filteredSyllabuses = syllabuses.filter((syllabus) =>
    syllabusFilter?.selectedValues?.includes(syllabus.id),
  )

  const filteredBySyllabuses = filterCoursesBySyllabuses(
    filteredByDomains,
    filteredSyllabuses,
  )

  return filteredBySyllabuses
}

function filterCoursesByDomains(
  courses: CourseDescriptorDto[],
  domains: string[],
) {
  if (domains.length === 0) {
    return courses
  }

  return courses.filter((course) =>
    course.domains.some((domain) => domains.includes(domain)),
  )
}

function filterCoursesBySyllabuses(
  courses: CourseDescriptorDto[],
  syllabuses: Syllabus[],
) {
  if (syllabuses.length === 0) {
    return courses
  }
  const allCourseIDs = syllabuses.flatMap((syllabus) =>
    syllabus.units.flatMap((unit) =>
      unit.courseDescriptors.map((cd) => cd.courseId),
    ),
  )

  return courses.filter((course) => allCourseIDs.includes(course.id))
}
