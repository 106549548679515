import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import { useEditTest } from "../custom-hooks/useEditElementItem"
import { useSelectedTest } from "../custom-hooks/useSelectedElementItem"
import { Topic } from "@masterschool/course-builder-api"
import { useAppSelector } from "@app/hooks"
import { MultiLanguageTitleTextField } from "../../components/multiLanguageFields/multiLanguageTitleTextField"
import MultiLanguageUrlTextField from "../../components/multiLanguageFields/multipleLanguageUrlTextField"

function TestEditor(props: { topic: Topic }) {
  const { topic } = props
  const test = useSelectedTest()
  const editTest = useEditTest()
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )

  if (!test) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <Stack direction="row" spacing={2} width="100%">
          <MultiLanguageTitleTextField
            elementId={test.id}
            defaultLanguageValue={test.title}
            extraLanguageValueRecord={test.extraLanguageTitleRecord}
            language={editingLanguage}
            debounce
            onChangeDefaultLanguage={(value) => {
              editTest(test, "title", value, topic)
            }}
            onChangeExtraLanguage={(value) => {
              editTest(test, "extraLanguageTitleRecord", value, topic)
            }}
          />
          <FormControl sx={{ minWidth: "180px" }} size="small">
            <InputLabel sx={{ fontSize: "17px" }}>Provider</InputLabel>
            <Select
              label="Provider"
              value={test.provider}
              onChange={(e) => {
                editTest(test, "provider", e.target.value, topic)
              }}
            >
              <MenuItem value={"typeform"} key={"typeform"}>
                Typeform
              </MenuItem>
              <MenuItem value={"masterschool"} key={"masterschool"}>
                Masterschool
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <TimeEstimationTextField
          elementId={test.id}
          value={test.estimatedDuration}
          debounce
          onChange={(e) => {
            editTest(test, "estimatedDuration", parseInt(e.target.value), topic)
          }}
        />
        <MultiLanguageUrlTextField
          elementId={test.id}
          defaultLanguageValue={test.url}
          extraLanguageValueRecord={test.extraLanguageUrlRecord}
          language={editingLanguage}
          debounce
          onChangeDefaultLanguage={(value) => {
            editTest(test, "url", value, topic)
          }}
          onChangeExtraLanguage={(value) => {
            editTest(test, "extraLanguageUrlRecord", value, topic)
          }}
        />
      </Box>
    </Box>
  )
}

export default TestEditor
