import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { Box } from "@mui/material"
import {
  DataGridPro,
  GridInitialState,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import { useCallback, useLayoutEffect, useState } from "react"
import { StudentAcademicStatusDTO } from "../studentAcademicStatus.dto"
import { ColumnKeys } from "./columns/column.keys"
import { STUDENTS_TABLE_COLUMNS } from "./columns/studentsTable.columns"
import { StudentTableToolbar } from "./filters/students.table.toolbar"
import { getActionsColumn } from "./studentsTable.actions"
import { FilterErrorBoundary } from "./table.error.boundary"

const STORAGE_KEY = "studentsTableGridState"

export const StudentsTableGrid = (props: {
  data: StudentAcademicStatusDTO[] | null
  onOpenProfileClicked: (studentId: string) => void
}) => {
  const { data, onOpenProfileClicked } = props
  const apiRef = useGridApiRef()
  const [initialState, setInitialState] = useState<GridInitialState>()

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState()
      localStorage.setItem(STORAGE_KEY, JSON.stringify(currentState))
    }
  }, [apiRef])

  const actionsColumn = getActionsColumn(onOpenProfileClicked)

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem(STORAGE_KEY)
    setInitialState(
      stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {},
    )

    window.addEventListener("beforeunload", saveSnapshot)

    return () => {
      window.removeEventListener("beforeunload", saveSnapshot)
      saveSnapshot()
    }
  }, [saveSnapshot])

  if (!initialState) {
    return <LoadingComponent loadedComponents="data" />
  }

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        "& .cell.is-flagged": {
          padding: 0,
          backgroundColor: "#ff000025",
        },
      }}
    >
      <FilterErrorBoundary>
        <DataGridPro
          rows={data ?? []}
          apiRef={apiRef}
          columns={[...STUDENTS_TABLE_COLUMNS, actionsColumn]}
          getRowId={(row) => row.studentId}
          slots={{ toolbar: StudentTableToolbar }}
          sx={{
            border: "none",
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                debounceMs: 300,
              },
            },
          }}
          pageSizeOptions={[10, 25, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
            pinnedColumns: {
              left: [ColumnKeys.NAME],
              right: [ColumnKeys.ACTIONS],
            },
            columns: {
              columnVisibilityModel: {
                email: false,
              },
            },
            sorting: {
              sortModel: [
                {
                  field: ColumnKeys.LAST_CSAT_SCORE,
                  sort: "asc",
                },
              ],
            },
            ...initialState,
          }}
        />
      </FilterErrorBoundary>
    </Box>
  )
}
