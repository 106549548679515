import { TrackDto } from "@masterschool/course-builder-api"
import { Box, Paper, Stack, Typography } from "@mui/material"
import { DomainChip } from "@utils/domainChip"
import { useNavigate } from "react-router-dom"
import { TrackStatusChip } from "./trackStatusChip"
import {
  DataGridPro,
  GridColDef,
  GridInitialState,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { useCallback, useLayoutEffect, useState } from "react"
import { schoolOptions } from "../../tab-views/students-tab/students-grid/columns/registration.details.columns"
import { domainToDisplayName } from "@utils/domainUtils"
import { uuidToShortId } from "../courses-table/utils"
import { TrackActionMenu } from "./trackActionMenu"

const STORAGE_KEY = "tracksTableGridState"

export const TracksTable = (props: { tracks: TrackDto[] }) => {
  const navigate = useNavigate()

  const [initialState, setInitialState] = useState<GridInitialState>()
  const apiRef = useGridApiRef()

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState()
      localStorage.setItem(STORAGE_KEY, JSON.stringify(currentState))
    }
  }, [apiRef])

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem(STORAGE_KEY)
    setInitialState(
      stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {},
    )

    window.addEventListener("beforeunload", saveSnapshot)

    return () => {
      window.removeEventListener("beforeunload", saveSnapshot)
      saveSnapshot()
    }
  }, [saveSnapshot])

  if (!initialState) {
    return <LoadingComponent loadedComponents="data" />
  }

  if (props.tracks.length === 0) {
    return <EmptyTrackTable />
  }
  return (
    <Paper sx={{ boxShadow: "none" }}>
      <DataGridPro
        apiRef={apiRef}
        rows={props.tracks}
        columns={columns}
        initialState={initialState}
        slots={{ toolbar: TracksTableToolbar }}
        onRowClick={(params) => navigate(`/track/${params.id}`)}
        disableRowSelectionOnClick
        sx={{
          border: "none",
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell": {
            justifyContent: "left",
          },
        }}
      />
    </Paper>
  )
}

const EmptyTrackTable = () => {
  return (
    <Stack pt={10} alignItems="center">
      <Typography color="text.disabled" variant="body1">
        No track versions added yet
      </Typography>
    </Stack>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EmptyModuleTable = () => {
  return (
    <Stack pt={10} alignItems="center">
      <Typography variant="body2_sb" pb={1}>
        Build master modules once, apply them everywhere
      </Typography>
      <Typography variant="body2">
        Maintain consistency and streamline your workflow with master modules.
      </Typography>
      <Typography>
        Create a master module once and apply it to multiple tracks.
      </Typography>
    </Stack>
  )
}

export const TracksTableToolbar = () => {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        alignItems: "center",
        paddingBottom: "8px",
        backgroundColor: "white",
      }}
    >
      <GridToolbarQuickFilter fullWidth sx={{ flexGrow: 1 }} />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  )
}

const columns: GridColDef<TrackDto>[] = [
  {
    field: "externalName",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "id",
    headerName: "Track ID",
    flex: 1,
    valueGetter: (_, track: TrackDto) => {
      const displayId = track.displayId
      const defaultId = uuidToShortId(track.id)
      return displayId || defaultId
    },
  },
  {
    field: "domain",
    headerName: "School",
    flex: 1,
    type: "singleSelect",
    valueOptions: schoolOptions,
    valueGetter: (_, track: TrackDto) => {
      const domain = track.domain
      if (!domain) return null
      return domainToDisplayName(domain)
    },
    renderCell: (params) => <DomainChip domain={params.row.domain || ""} />,
  },
  {
    field: "terms",
    headerName: "Terms",
    type: "number",
    flex: 1,
    headerAlign: "left",
    renderCell: (params) => (
      <Box display="flex" alignItems="center" height="100%">
        <Typography textAlign="left">
          {params.row.terms?.length || 0}
        </Typography>
      </Box>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    type: "singleSelect",
    valueOptions: ["Drafts", "Published", "Archived"],
    renderCell: (params) => <TrackStatusChip status={params.value} />,
  },
  {
    field: "action-menu",
    headerName: "",
    renderCell: (params) => <TrackActionMenu track={params.row} />,
  },
]
