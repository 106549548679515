import { DeleteIcon } from "@cmp/buttons/deleteButton"
import { CourseLanguage, CourseraTask } from "@masterschool/course-builder-api"
import { Box, SxProps, Theme, Typography } from "@mui/material"
import appTheme from "../../../../theme/appTheme"
import DragTaskIcon from "./dragTaskIcon"
import { taskCardBGColor } from "./taskCardBGColor"
import MultiLanguageUrlTextField from "../../../components/multiLanguageFields/multipleLanguageUrlTextField"

function CourseraTaskEditor(props: {
  task: CourseraTask
  editingLanguage: CourseLanguage
  index: number
  onChange: <K extends keyof CourseraTask>(
    key: K,
    value: CourseraTask[K],
  ) => void
  onDeleteClicked: () => void
  sx?: SxProps<Theme>
}) {
  const { task, index, onChange, onDeleteClicked, sx, editingLanguage } = props

  if (!task) {
    return undefined
  }

  if (!task) {
    return undefined
  }

  return (
    <Box
      key={`task ${index}`}
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "all 0.2s ease-in-out",
        padding: "8px",
        gap: "8px",
        cursor: "default",
        ...sx,
      }}
    >
      <DragTaskIcon />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          border: `1px solid ${appTheme.palette.other.outlineBorder}`,
          borderRadius: "8px",
          padding: "16px 8px",
          alignItems: "center",
          gap: "8px",
          bgcolor: taskCardBGColor,
        }}
      >
        <Typography variant="body3" color="text.disabled" paddingX="7px">
          {isNaN(index) ? `??` : `${index + 1}.`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <MultiLanguageUrlTextField
            elementId={task.id}
            language={editingLanguage}
            defaultLanguageValue={task.url}
            extraLanguageValueRecord={task.extraLanguageUrlRecord}
            debounce
            onChangeDefaultLanguage={(value) => {
              onChange("url", value)
            }}
            onChangeExtraLanguage={(value) => {
              onChange("extraLanguageUrlRecord", value)
            }}
          />
        </Box>
        <DeleteIcon onClick={onDeleteClicked} />
      </Box>
    </Box>
  )
}

export default CourseraTaskEditor
