import { Box } from "@mui/material"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import { useEditCodioProject } from "../custom-hooks/useEditElementItem"
import { useSelectedCodioProject } from "../custom-hooks/useSelectedElementItem"
import { Topic } from "@masterschool/course-builder-api"
import EditProjectType from "./editProjectType"
import EditorTextField, {
  EditorTextFieldProps,
} from "../../components/editorTextField"
import { useAppSelector } from "@app/hooks"
import { selectPublishValidationsForItem } from "@features/courseEditor/courseValidationsSelectors"
import { MultiLanguageTitleTextField } from "../../components/multiLanguageFields/multiLanguageTitleTextField"
import MultiLanguageUrlTextField from "../../components/multiLanguageFields/multipleLanguageUrlTextField"

function CodioProjectEditor(props: { topic: Topic }) {
  const { topic } = props
  const codioProject = useSelectedCodioProject()
  const editCodioProject = useEditCodioProject()
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )
  if (!codioProject) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "16px",
          }}
        >
          <MultiLanguageTitleTextField
            elementId={codioProject.id}
            defaultLanguageValue={codioProject.title}
            extraLanguageValueRecord={codioProject.extraLanguageTitleRecord}
            language={editingLanguage}
            debounce
            onChangeDefaultLanguage={(value) =>
              editCodioProject(codioProject, "title", value, topic)
            }
            onChangeExtraLanguage={(value) =>
              editCodioProject(
                codioProject,
                "extraLanguageTitleRecord",
                value,
                topic,
              )
            }
          />
          <EditProjectType topic={topic} />
        </Box>
        <TimeEstimationTextField
          elementId={codioProject.id}
          value={codioProject.estimatedDuration}
          debounce
          onChange={(e) => {
            editCodioProject(
              codioProject,
              "estimatedDuration",
              parseInt(e.target.value),
              topic,
            )
          }}
        />

        <MultiLanguageUrlTextField
          elementId={codioProject.id}
          defaultLanguageValue={codioProject.url}
          extraLanguageValueRecord={codioProject.extraLanguageUrlRecord}
          language={editingLanguage}
          debounce
          label="Project URL"
          onChangeDefaultLanguage={(value) =>
            editCodioProject(codioProject, "url", value, topic)
          }
          onChangeExtraLanguage={(value) =>
            editCodioProject(
              codioProject,
              "extraLanguageUrlRecord",
              value,
              topic,
            )
          }
        />
        <MultiLanguageUrlTextField
          elementId={codioProject.id}
          defaultLanguageValue={codioProject.taskUrl}
          extraLanguageValueRecord={codioProject.extraLanguageTaskUrlRecord}
          language={editingLanguage}
          debounce
          label="Task URL"
          onChangeDefaultLanguage={(value) =>
            editCodioProject(codioProject, "taskUrl", value, topic)
          }
          onChangeExtraLanguage={(value) =>
            editCodioProject(
              codioProject,
              "extraLanguageTaskUrlRecord",
              value,
              topic,
            )
          }
        />
        <MultiLanguageUrlTextField
          elementId={codioProject.id}
          defaultLanguageValue={codioProject.solutionUrl}
          extraLanguageValueRecord={codioProject.extraLanguageSolutionUrlRecord}
          language={editingLanguage}
          debounce
          label="Solution URL"
          onChangeDefaultLanguage={(value) =>
            editCodioProject(codioProject, "solutionUrl", value, topic)
          }
          onChangeExtraLanguage={(value) =>
            editCodioProject(
              codioProject,
              "extraLanguageSolutionUrlRecord",
              value,
              topic,
            )
          }
        />
        <PassingGradeTextField
          elementId={codioProject.id}
          value={codioProject.passingGrade}
          debounce
          onChange={(e) => {
            editCodioProject(
              codioProject,
              "passingGrade",
              parseInt(e.target.value),
              topic,
            )
          }}
        />
      </Box>
    </Box>
  )
}

const PassingGradeTextField = (
  props: EditorTextFieldProps & {
    elementId: string
    value: number | undefined
  },
) => {
  const errors = useAppSelector(
    selectPublishValidationsForItem(props.elementId),
  )
  const hasError = errors?.includes("INVALID_PASSING_GRADE")
  const errorMessage = hasError
    ? "Please enter a value between 0 and 100."
    : undefined

  return (
    <EditorTextField
      type="number"
      label="Passing grade"
      value={props.value}
      debounce
      onChange={props.onChange}
      error={errorMessage !== undefined}
      helperText={errorMessage}
    />
  )
}

export default CodioProjectEditor
