import { Box } from "@mui/material"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import { useEditCodioLesson } from "../custom-hooks/useEditElementItem"
import { useSelectedCodioLesson } from "../custom-hooks/useSelectedElementItem"
import { Topic } from "@masterschool/course-builder-api"
import EditLessonType from "./EditLessonType"
import { MultiLanguageTitleTextField } from "../../components/multiLanguageFields/multiLanguageTitleTextField"
import { useAppSelector } from "@app/hooks"
import MultiLanguageUrlTextField from "../../components/multiLanguageFields/multipleLanguageUrlTextField"

function CodioLessonEditor(props: { topic: Topic }) {
  const { topic } = props
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )
  const codioLesson = useSelectedCodioLesson()
  const editCodioLesson = useEditCodioLesson()
  if (!codioLesson) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "16px",
          }}
        >
          <MultiLanguageTitleTextField
            elementId={codioLesson.id}
            language={editingLanguage}
            defaultLanguageValue={codioLesson.title}
            extraLanguageValueRecord={codioLesson.extraLanguageTitleRecord}
            debounce
            onChangeDefaultLanguage={(value) => {
              editCodioLesson(codioLesson, "title", value, topic)
            }}
            onChangeExtraLanguage={(value) => {
              editCodioLesson(
                codioLesson,
                "extraLanguageTitleRecord",
                value,
                topic,
              )
            }}
          />
          <EditLessonType topic={topic} />
        </Box>
        <TimeEstimationTextField
          elementId={codioLesson.id}
          value={codioLesson.estimatedDuration}
          debounce
          onChange={(e) => {
            editCodioLesson(
              codioLesson,
              "estimatedDuration",
              parseInt(e.target.value),
              topic,
            )
          }}
        />
        <MultiLanguageUrlTextField
          elementId={codioLesson.id}
          language={editingLanguage}
          defaultLanguageValue={codioLesson.url}
          extraLanguageValueRecord={codioLesson.extraLanguageUrlRecord}
          debounce
          label="Project URL"
          onChangeDefaultLanguage={(newValue) => {
            editCodioLesson(codioLesson, "url", newValue, topic)
          }}
          onChangeExtraLanguage={(newExtraLanguageValueRecord) => {
            editCodioLesson(
              codioLesson,
              "extraLanguageUrlRecord",
              newExtraLanguageValueRecord,
              topic,
            )
          }}
        />
        <MultiLanguageUrlTextField
          elementId={codioLesson.id}
          language={editingLanguage}
          defaultLanguageValue={codioLesson.taskUrl}
          extraLanguageValueRecord={codioLesson.extraLanguageTaskUrlRecord}
          debounce
          label="Task URL"
          onChangeDefaultLanguage={(newValue) => {
            editCodioLesson(codioLesson, "taskUrl", newValue, topic)
          }}
          onChangeExtraLanguage={(newExtraLanguageValueRecord) => {
            editCodioLesson(
              codioLesson,
              "extraLanguageTaskUrlRecord",
              newExtraLanguageValueRecord,
              topic,
            )
          }}
        />
      </Box>
    </Box>
  )
}

export default CodioLessonEditor
