import { useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import HighlightableScrollRef from "@cmp/highlightableScrollRef"
import { selectPublishValidations } from "@features/courseEditor/courseValidationsSelectors"
import {
  CourseLanguage,
  Topic,
  UdacityLesson,
} from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  SvgIcon,
  TextField,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import SyllabusElementFactory from "@utils/syllabusElementFactory"
import { useEffect, useRef, useState } from "react"
import appTheme from "../../../theme/appTheme"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import { useEditUdacityLesson } from "../custom-hooks/useEditElementItem"
import { useSelectedUdacityLesson } from "../custom-hooks/useSelectedElementItem"
import EditLessonType from "./EditLessonType"
import UdacityTasksContainer from "./task-editors/udacityTasksContainer"
import { MultiLanguageTitleTextField } from "../../components/multiLanguageFields/multiLanguageTitleTextField"
import MultiLanguageUrlTextField from "../../components/multiLanguageFields/multipleLanguageUrlTextField"

function UdacityLessonEditor(props: { topic: Topic }) {
  const { topic } = props
  const lesson = useSelectedUdacityLesson()
  const editLesson = useEditUdacityLesson()
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )

  if (!lesson) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "16px",
        }}
      >
        <MultiLanguageTitleTextField
          elementId={lesson.id}
          language={editingLanguage}
          defaultLanguageValue={lesson.title}
          extraLanguageValueRecord={lesson.extraLanguageTitleRecord}
          debounce
          onChangeDefaultLanguage={(value) => {
            editLesson(lesson, "title", value, topic)
          }}
          onChangeExtraLanguage={(value) => {
            editLesson(lesson, "extraLanguageTitleRecord", value, topic)
          }}
        />
        <EditLessonType topic={topic} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "16px",
        }}
      >
        <MultiLanguageUrlTextField
          elementId={lesson.id}
          language={editingLanguage}
          defaultLanguageValue={lesson.url}
          extraLanguageValueRecord={lesson.extraLanguageUrlRecord}
          debounce
          onChangeDefaultLanguage={(value) => {
            editLesson(lesson, "url", value, topic)
          }}
          onChangeExtraLanguage={(value) => {
            editLesson(lesson, "extraLanguageUrlRecord", value, topic)
          }}
        />
        <TimeEstimationTextField
          elementId={lesson.id}
          value={lesson.estimatedDuration}
          debounce
          onChange={(e) => {
            editLesson(
              lesson,
              "estimatedDuration",
              parseInt(e.target.value),
              topic,
            )
          }}
          sx={{ minWidth: "180px" }}
        />
      </Box>
      <TasksEditorContainer lesson={lesson} topic={topic} />
    </Box>
  )
}

const TasksEditorContainer = (props: {
  lesson: UdacityLesson
  topic: Topic
}) => {
  const { lesson, topic } = props
  const editLesson = useEditUdacityLesson()
  const tasksEditorRef = useRef<HighlightableScrollRef>(null)
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false)
  const [isBulkEditing, setIsBulkEditing] = useState(false)
  const validations = useAppSelector(selectPublishValidations)
  const isInvalidBecauseMissingTasks =
    validations?.[lesson.id]?.includes("MISSING_TASKS") &&
    lesson.tasks.length === 0

  useEffect(() => {
    if (shouldScrollToBottom) {
      tasksEditorRef.current?.scrollToBottomAndHighlightLastTask()
    }
    setShouldScrollToBottom(false)
  }, [shouldScrollToBottom])

  const addTaskText =
    lesson.tasks.length === 0 ? "Add task (optional)" : "Add task"

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "start",
          gap: "16px",
          overflow: "hidden",
        }}
      >
        <UdacityTasksContainer ref={tasksEditorRef} topic={topic} />
        <Box>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
            }}
          >
            <Button
              variant="text"
              size="small"
              onClick={() => {
                editLesson(
                  lesson,
                  "tasks",
                  lesson.tasks.concat(SyllabusElementFactory.makeUdacityTask()),
                  topic,
                )
                setShouldScrollToBottom(true)
              }}
              startIcon={
                <SvgIcon
                  component={appIcons.plusCircle}
                  inheritViewBox
                  sx={{
                    width: "20px",
                    height: "20px",
                    fill: "none",
                    stroke: isInvalidBecauseMissingTasks
                      ? appTheme.palette.error.main
                      : appTheme.palette.icon.black,
                  }}
                />
              }
              sx={{
                color: isInvalidBecauseMissingTasks
                  ? appTheme.palette.error.main
                  : appTheme.palette.icon.black,
              }}
            >
              {addTaskText}
            </Button>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setIsBulkEditing(true)
              }}
              startIcon={
                <SvgIcon
                  component={appIcons.edit05}
                  inheritViewBox
                  sx={{
                    width: "20px",
                    height: "20px",
                    stroke: appTheme.palette.icon.black,
                    fill: "none",
                  }}
                />
              }
            >
              Bulk edit
            </Button>
            <FormHelperText
              error={isInvalidBecauseMissingTasks}
              variant="standard"
              sx={{
                textTransform: "none",
                paddingTop: "4px",
                paddingLeft: "14px",
              }}
            >
              {isInvalidBecauseMissingTasks
                ? "Lesson must have at least one task"
                : ""}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
      {isBulkEditing && (
        <BulkEditingDialog
          onClose={() => {
            setIsBulkEditing(false)
          }}
          topic={topic}
        />
      )}
    </>
  )
}

function BulkEditingDialog(props: { onClose: () => void; topic: Topic }) {
  const { onClose, topic } = props

  const lesson = useSelectedUdacityLesson()
  const editLesson = useEditUdacityLesson()
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )

  const [tasks, setTasks] = useState<string>("")

  if (!lesson) {
    return undefined
  }

  return (
    <GenericDialog
      title="Bulk edit"
      subtitle="Separate URLs using line breaks to add tasks in bulk."
      onClose={onClose}
      open={true}
      size="sm"
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "primary",
          text: "Save",
          onClick: () => {
            if (!tasks) {
              return
            }
            const newTasks = makeTasksFromConcatenatedString(
              tasks,
              editingLanguage,
            )
            editLesson(lesson, "tasks", lesson.tasks.concat(newTasks), topic)
            onClose()
          },
          disabled: !tasks,
        },
      ]}
      content={
        <TextField
          label="Tasks URL"
          multiline
          rows={6}
          fullWidth
          variant="outlined"
          value={tasks}
          onChange={(event) => {
            setTasks(event.target.value)
          }}
          sx={{
            width: "100%",
            paddingBottom: "24px",
          }}
        />
      }
    />
  )
}

function makeTasksFromConcatenatedString(
  tasks: string,
  language: CourseLanguage,
) {
  const tasksUrls = tasks.split("\n")
  return tasksUrls.msCompactMap((url) => {
    const isDefaultLanguage = language === CourseLanguage.English
    const payload = isDefaultLanguage
      ? { url }
      : { extraLanguageUrlRecord: { [language]: url } }

    return SyllabusElementFactory.makeUdacityTask(payload)
  })
}

export default UdacityLessonEditor
