import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { TrackClient } from "@clients/trackClient"
import {
  TrackDto,
  TrackDtoStatusEnum,
  TrackMetadataDto,
} from "@masterschool/course-builder-api"
import { createAppAsyncThunk } from "@app/createAppAsyncThunk"

interface TrackById {
  [key: string]: TrackDto
}

interface TrackState {
  trackRepresentativeById: TrackById
  loadingState: "idle" | "loading" | "failed"
}

const initialState: TrackState = {
  trackRepresentativeById: {},
  loadingState: "idle",
}

const trackSlice = createSlice({
  name: "track",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTracks.pending, (state) => {
        state.loadingState = "loading"
      })
      .addCase(fetchTracks.fulfilled, (state, action) => {
        state.loadingState = "idle"
        state.trackRepresentativeById = action.payload.reduce((acc, track) => {
          acc[track.id] = track
          return acc
        }, {} as TrackById)
      })
      .addCase(fetchTracks.rejected, (state, action) => {
        state.loadingState = "failed"
      })
      .addCase(saveTrack.fulfilled, (state, action) => {
        const fetchedTrack = action.payload
        const currentTrack = state.trackRepresentativeById[fetchedTrack.id]
        if (
          canOverrideCurrentVersion({
            fetchedTrack,
            currentTrack,
          })
        ) {
          state.trackRepresentativeById[fetchedTrack.id] = fetchedTrack
        }
      })
      .addCase(fetchTrackById.fulfilled, (state, action) => {
        const fetchedTrack = action.payload
        const currentTrack = state.trackRepresentativeById[fetchedTrack.id]
        if (
          canOverrideCurrentVersion({
            fetchedTrack,
            currentTrack,
          })
        ) {
          state.trackRepresentativeById[fetchedTrack.id] = fetchedTrack
        }
      })
      .addCase(publishTrack.fulfilled, (state, action) => {
        const publishedTrack = action.payload
        const currentTrack = state.trackRepresentativeById[publishedTrack.id]
        if (publishedTrack.version >= currentTrack.version)
          state.trackRepresentativeById[publishedTrack.id] = publishedTrack
      })
      .addCase(updateTrackMetadata.fulfilled, (state, action) => {
        const updatedTrackMetadata = action.payload
        const trackId = action.meta.arg.trackId
        const currentTrack = state.trackRepresentativeById[trackId]
        state.trackRepresentativeById[trackId] = {
          ...currentTrack,
          durationInMonths: updatedTrackMetadata.durationInMonths,
          displayId: updatedTrackMetadata.displayId,
        }
      })
  },
})

function canOverrideCurrentVersion({
  fetchedTrack,
  currentTrack,
}: {
  fetchedTrack: TrackDto
  currentTrack: TrackDto | undefined
}) {
  const fetchedIsMoreAdvancedThanCurrent =
    !currentTrack || fetchedTrack.version > currentTrack.version
  const responseIsNotDraft = fetchedTrack.status !== TrackDtoStatusEnum.Draft

  const hasNoCurrentVersion = !currentTrack
  const currentVersionIsTheInitialDraft = currentTrack?.version === 1
  return (
    hasNoCurrentVersion ||
    currentVersionIsTheInitialDraft ||
    (fetchedIsMoreAdvancedThanCurrent && responseIsNotDraft)
  )
}

export const fetchTracks = createAsyncThunk(
  "tracks/fetchTracks",
  async (_, { rejectWithValue }) => {
    try {
      return await TrackClient.list()
    } catch (error) {
      return rejectWithValue((error as Error).message)
    }
  },
)

export const publishTrack = createAsyncThunk(
  "tracks/publishTrack",
  async (trackId: string) => {
    return await TrackClient.publish(trackId)
  },
)

export const saveTrack = createAsyncThunk(
  "tracks/saveTrack",
  async ({ trackId, track }: { trackId: string; track: TrackDto }) => {
    return await TrackClient.save(trackId, track)
  },
)

export const updateTrackMetadata = createAppAsyncThunk(
  "tracks/updateTrackMetadata",
  async (
    payload: { trackId: string; metadata: TrackMetadataDto },
    thunkAPI,
  ) => {
    return TrackClient.updateSyllabusMetadata(payload.metadata, payload.trackId)
  },
)

export const fetchTrackById = createAsyncThunk(
  "tracks/fetchTrackById",
  async (trackId: string) => {
    return await TrackClient.getTrack(trackId)
  },
)

export const createNewDraftForTrack = createAsyncThunk(
  "tracks/createNewDraft",
  async (trackId: string) => {
    return await TrackClient.createNewDraft(trackId)
  },
)

export default trackSlice.reducer
