import { RootState } from "@app/store"
import { Middleware } from "redux"
import localStorageObserver from "./localStorageObserver"
import snackbarObserver from "../../main/snackbars/snackbarObserver"
import { accessTokenObserver } from "@features/login/accessTokenObserver"
import syllabusEditsObserver from "../../features/syllabusEditor/syllabusEditsObserver"
import { loggedInUserObserver } from "@features/login/loggedInUserObserver"
import CourseViewObserver from "@features/courseView/courseViewObserver"

const observers = [
  localStorageObserver,
  accessTokenObserver,
  loggedInUserObserver,
  snackbarObserver,
  syllabusEditsObserver,
  CourseViewObserver,
]

const observersMiddleware: Middleware = (store) => (next) => (action) => {
  const prevState = store.getState() as RootState
  next(action)
  const nextState = store.getState() as RootState

  observers.forEach((observer) => {
    observer.didUpdate(prevState, nextState, action, store.dispatch)
  })
}

export default observersMiddleware
