import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  CourseDescriptorDto,
  CourseDto,
  SyllabusDto,
  TrackDto,
} from "@masterschool/course-builder-api"
import StaleCourseModel from "../../editor/syllabus/validations/staleCoursesPopup/staleCourseModel"

export interface UiState {
  popup: PopupState | undefined
  snackbar: SnackbarState | undefined
  sessionHistory: {
    presentedStaleCoursesSnackbar: StaleCourseModel[]
  }
}

export type PopupState =
  | {
      type: PopupType.ArchiveCourseConfirmation
      courseId: string
      version: string
    }
  | {
      type: PopupType.CampusCoursePreview
      course: CourseDescriptorDto
    }
  | {
      type: PopupType.CampusSyllabusPreview
      syllabus: SyllabusDto
      programId?: string
    }
  | {
      type: PopupType.DependentSyllabuses
      syllabusDependencies: string[]
    }
  | {
      type: PopupType.Preview
      course: CourseDto
    }
  | {
      type: PopupType.CourseVersionsHistory
      courseId: string
      majorVersion: number
      context: "courseEditor" | "syllabusEditor"
    }
  | {
      type: PopupType.EditCourseDetails
      course: CourseDescriptorDto
    }
  | { type: PopupType.EditTrackDetails; track: TrackDto }
  | {
      type: PopupType.SyllabusVersionsHistory
      syllabusId: string
    }
  | {
      type: PopupType.ArchiveSyllabusConfirmation
      syllabusId: string
    }
  | {
      type: PopupType.SyllabusDeleteConfirmation
      syllabusId: string
    }
  | {
      type: PopupType.CampusSyllabusJSONPreview
      syllabusId: string
      version: number
    }
  | {
      type: PopupType.CourseInstanceVersionSwitch
      courseId: string
      courseInstanceId: string
      version: string
    }

export enum PopupType {
  CourseVersionsHistory = "course-versions-history",
  SyllabusVersionsHistory = "syllabus-versions-history",
  DependentSyllabuses = "dependent-syllabuses",
  CampusCoursePreview = "campus-course-preview",
  EditCourseDetails = "edit-course-details",
  EditTrackDetails = "edit-track-details",
  CampusSyllabusPreview = "campus-syllabus-preview",
  Preview = "preview",
  ArchiveCourseConfirmation = "archive-course-confirmation",
  ArchiveSyllabusConfirmation = "archive-syllabus-confirmation",
  SyllabusDeleteConfirmation = "syllabus-delete-confirmation",
  CampusSyllabusJSONPreview = "campus-syllabus-json-preview",
  CourseInstanceVersionSwitch = "course-instance-version-switch",
}

type SnackbarState = {
  message: string
  type: "success" | "error" | "warning" | "info"
  duration?: SnackbarDuration // defaults to long
  anchorOrigin?: SnackbarAnchorOrigin // defaults to top
  visible: boolean
}

export enum SnackbarDuration {
  short = 2000,
  long = 4000,
}

export type SnackbarAnchorOrigin = "top" | "bottom"

const initialState: UiState = {
  popup: undefined,
  snackbar: undefined,
  sessionHistory: {
    presentedStaleCoursesSnackbar: [],
  },
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    popupItemClicked: (state, action: PayloadAction<PopupState>) => {
      state.popup = action.payload
    },
    popupClosed: (state) => {
      state.popup = undefined
    },
    showSnackbarItem: (
      state,
      action: PayloadAction<Omit<SnackbarState, "visible">>,
    ) => {
      state.snackbar = { ...action.payload, visible: true }
    },
    hideSnackbar: (state) => {
      if (!state.snackbar) return
      state.snackbar.visible = false
    },
    staleCoursesSnackbarClosed: (
      state,
      action: PayloadAction<StaleCourseModel[]>,
    ) => {
      state.sessionHistory.presentedStaleCoursesSnackbar =
        state.sessionHistory.presentedStaleCoursesSnackbar.concat(
          action.payload,
        )
    },
  },
})

export const showInfoSnackbar = (message: string) =>
  uiSlice.actions.showSnackbarItem({
    message,
    type: "info",
  })

export const showSuccessSnackbar = (
  message: string,
  duration?: SnackbarDuration,
) =>
  uiSlice.actions.showSnackbarItem({
    message,
    type: "success",
    duration,
  })

export const showErrorSnackbar = (message: string) =>
  uiSlice.actions.showSnackbarItem({
    message,
    type: "error",
  })

export const {
  popupItemClicked,
  popupClosed,
  showSnackbarItem,
  hideSnackbar,
  staleCoursesSnackbarClosed,
} = uiSlice.actions

export default uiSlice.reducer
