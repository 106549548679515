import { useAppSelector } from "@app/hooks"
import { selectPublishValidationsForItem } from "@features/courseEditor/courseValidationsSelectors"
import { TitleTextFieldProps } from "../titleTextField"
import { MultiLanguageProps } from "./multiLanguage.types"
import { MultiLanguageEditorTextField } from "./multiLanguageEditorTextField"

export type MultiLanguageTitleTextFieldProps = TitleTextFieldProps &
  MultiLanguageProps

export function MultiLanguageTitleTextField(
  props: MultiLanguageTitleTextFieldProps,
) {
  const errors = useAppSelector(
    selectPublishValidationsForItem(props.elementId),
  )
  const showError = errors?.includes("MISSING_TITLE") && !props.value
  const { elementId, ...domProps } = props

  return (
    <MultiLanguageEditorTextField
      label="Name"
      error={showError}
      helperText={showError ? "This field is required" : ""}
      {...domProps}
    />
  )
}
