import { useAppDispatch, useAppSelector } from "@app/hooks"
import { ConsultantClient } from "@clients/consultantClient"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import {
  salesTeamWithShiftsSelector,
  onlineConsultantsSelector,
  selectSalesTeams,
} from "@features/sales-management/salesManagement.selectors"
import {
  fetchSalesTeams,
  fetchShifts,
  archiveConsultant,
  updateConsultantAvailability,
  updateConsultantIsPlacementExpert,
  updateConsultants,
} from "@features/sales-management/salesManagement.slice"
import { CreateConsultantDto } from "@masterschool/course-builder-api"
import PlusIcon from "@mui/icons-material/Add"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  SvgIcon,
  Switch,
  Typography,
} from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import appIcons from "@utils/appIcons"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import EmptyStateContainer from "../programs/program/components/emptyStateContainer"
import appTheme from "../theme/appTheme"
import { ConsultantCell } from "./consultantCell"
import ContactForm from "./consultantForm"
import { ShiftCell } from "./shiftCell"
import { useNavigate } from "react-router-dom"
import { OnlineStatusUpdater } from "./Models/onlineStatusUpdater"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Role } from "@features/login/loginSlice"
import { showSnackbarItem, SnackbarDuration } from "@features/ui/uiSlice"
import { flagForLanguage } from "@utils/language"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import { IconTextMenuItem } from "../main/courses-table/course-table/coursesCells"
import { AppDispatch } from "@app/store"

const EmptyState = (props: { onAddConsultantClick: () => void }) => {
  return (
    <EmptyStateContainer
      title="No consultants yet"
      subtitle="Add and manage consultants"
      icon={appIcons.bank}
      action={
        <Button variant="contained" onClick={props.onAddConsultantClick}>
          Add consultant
        </Button>
      }
    />
  )
}

export const SalesManagement = () => {
  const navigate = useNavigate()
  const consultantsTeams = useSelector(salesTeamWithShiftsSelector)
  const roles = useSelector(selectLoggedInUser)?.roles
  const isLMSAdmin = !!roles?.includes(Role.LmsAdmin)
  const [openConsultantInsertForm, setOpenConsultantInsertForm] =
    useState(false)
  const [teamExpandedState, setTeamExpandedState] = useState<
    string[] | undefined
  >(undefined)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchSalesTeams())
  }, [dispatch])

  const salesTeams = useAppSelector(selectSalesTeams)
  useEffect(() => {
    dispatch(fetchShifts())
  }, [salesTeams, dispatch])

  const onCreateConsultant = (createConsultantModel: CreateConsultantDto) => {
    ConsultantClient.create(createConsultantModel)
      .then(() => {
        setOpenConsultantInsertForm(false)
        dispatch(updateConsultants())
      })
      .catch(() => {
        dispatch(
          showSnackbarItem({
            message:
              "Failed to create consultant, please verify that the email matches Hubspot consultant email",
            type: "error",
            duration: SnackbarDuration.short,
          }),
        )
      })
  }

  const handleAvailableChange = (params: {
    consultantId: string
    newIsAvailable: boolean
  }) => {
    dispatch(
      updateConsultantAvailability({
        consultantId: params.consultantId,
        isAvailable: params.newIsAvailable,
      }),
    )
  }

  const handleIsPlacementExpertChange = (params: {
    consultantId: string
    isPlacementExpert: boolean
  }) => {
    dispatch(
      updateConsultantIsPlacementExpert({
        consultantId: params.consultantId,
        isPlacementExpert: params.isPlacementExpert,
      }),
    )
  }

  useEffect(() => {
    const onlineStatusUpdater = new OnlineStatusUpdater(1)
    onlineStatusUpdater.start()
    return () => {
      onlineStatusUpdater.stop()
    }
  }, [])
  const onlineConsultants = useSelector(onlineConsultantsSelector)
  const onlineConsultansIds =
    onlineConsultants !== "pending"
      ? onlineConsultants.map((consultant) => consultant.id)
      : []

  const updateTeamExpandedState = (teamId: string) => {
    setTeamExpandedState((prevState) => {
      if (!prevState) {
        return [teamId]
      }
      if (prevState.includes(teamId)) {
        return prevState.filter((id) => id !== teamId)
      }
      return [...prevState, teamId]
    })
  }

  useEffect(() => {
    if (
      consultantsTeams !== "pending" &&
      consultantsTeams.length > 0 &&
      teamExpandedState === undefined
    ) {
      setTeamExpandedState([consultantsTeams[0].teamLead.salesTeamLeadUserId])
    }
  }, [consultantsTeams, teamExpandedState])

  const renderEmptyState =
    consultantsTeams === "pending" ? (
      <Box />
    ) : (
      <EmptyState
        onAddConsultantClick={() => setOpenConsultantInsertForm(true)}
      />
    )
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="start"
      alignItems="center"
      height="100%"
      px="24px"
      pb="30px"
      width="100%"
      gap="24px"
    >
      <Box
        width="100%"
        paddingX="32px"
        display="flex"
        paddingTop="84px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Sales Management</Typography>
        <Box display="flex" gap="8px">
          {!!roles && roles.includes(Role.SalesTeamLead) && (
            <Button
              onClick={() => setOpenConsultantInsertForm(true)}
              variant="contained"
              size="small"
              startIcon={<PlusIcon />}
            >
              <Typography variant="body2" color="white">
                {" "}
                Add consultant
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
      {consultantsTeams === "pending" || consultantsTeams.length === 0 ? (
        renderEmptyState
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            overflow="hidden"
            paddingX="30px"
            width="100%"
            gap="24px"
          >
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              gap="24px"
              overflow="auto"
            >
              {consultantsTeams.map((team) => {
                const isExpanded =
                  teamExpandedState?.includes(
                    team.teamLead.salesTeamLeadUserId,
                  ) ?? false
                return (
                  <Accordion
                    key={team.teamLead.salesTeamLeadUserId}
                    expanded={isExpanded}
                    onChange={() => {
                      updateTeamExpandedState(team.teamLead.salesTeamLeadUserId)
                    }}
                    sx={{
                      padding: "0 !important",
                      margin: "0 !important",
                      flexShrink: 0,
                    }}
                  >
                    <AccordionSummary sx={{ paddingX: "16px" }}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography variant="h6">
                          {team.teamLead.name}`s Consultants
                        </Typography>
                        <ChevronRightIcon
                          sx={{
                            transform: isExpanded
                              ? "rotate(90deg)"
                              : "rotate(-90deg)",
                            transition: "transform 0.3s ease-in-out",
                          }}
                        />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <TableContainer
                        component={Paper}
                        sx={{ boxShadow: "none" }}
                      >
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Consultants</TableCell>
                              <TableCell align="left">Shifts</TableCell>
                              <TableCell align="left">QTFs</TableCell>
                              <TableCell align="left">Languages</TableCell>
                              <TableCell align="left">Availability</TableCell>
                              <TableCell align="left">Golden Leads</TableCell>
                              {isLMSAdmin && (
                                <TableCell align="left">Actions</TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {team.consultants.map(
                              (consultantWithShift, index) => (
                                <TableRow
                                  key={consultantWithShift.id}
                                  sx={{
                                    textDecoration: "none",
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    bgcolor: consultantWithShift.isAvailable
                                      ? appTheme.palette.eTypes.sand15
                                      : "#FEECEB",
                                  }}
                                  hover={consultantWithShift.isAvailable}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      `/sales-management/shifts/${consultantWithShift.id}`,
                                    )
                                  }
                                >
                                  <TableCell component="th" scope="row">
                                    <ConsultantCell
                                      consultant={consultantWithShift}
                                      index={index}
                                      key={consultantWithShift.id}
                                      isOnline={onlineConsultansIds.includes(
                                        consultantWithShift.id,
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell align="left">
                                    {ShiftCell(consultantWithShift.shifts)}
                                  </TableCell>
                                  <QTFsCell
                                    qtfs={
                                      consultantWithShift.isPlacementExpert
                                        ? []
                                        : consultantWithShift.acceptedQtfs
                                    }
                                  />
                                  <LanguagesCell
                                    languages={consultantWithShift.languages}
                                  />
                                  <TableCell
                                    align="left"
                                    sx={{ zIndex: "100" }}
                                  >
                                    <Switch
                                      checked={consultantWithShift.isAvailable}
                                      onChange={(event) => {
                                        event.stopPropagation()
                                        handleAvailableChange({
                                          consultantId: consultantWithShift.id,
                                          newIsAvailable:
                                            !consultantWithShift.isAvailable,
                                        })
                                      }}
                                      onClick={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{ zIndex: "100" }}
                                  >
                                    <Switch
                                      checked={
                                        consultantWithShift.isPlacementExpert
                                      }
                                      onChange={(event) => {
                                        event.stopPropagation()
                                        handleIsPlacementExpertChange({
                                          consultantId: consultantWithShift.id,
                                          isPlacementExpert:
                                            !consultantWithShift.isPlacementExpert,
                                        })
                                      }}
                                      onClick={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  </TableCell>
                                  {isLMSAdmin && (
                                    <ActionsCell
                                      consultantId={consultantWithShift.id}
                                      dispatch={dispatch}
                                    />
                                  )}
                                </TableRow>
                              ),
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </Box>
          </Box>
        </>
      )}
      <Dialog
        open={openConsultantInsertForm}
        onClose={() => setOpenConsultantInsertForm(false)}
        maxWidth="md"
      >
        <Box height="24px" />
        <DialogTitle>Add Consultant</DialogTitle>
        <Box height="2px" />
        <DialogContent>
          <ContactForm
            onCreate={onCreateConsultant}
            onClose={() => setOpenConsultantInsertForm(false)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  )
}

const QTFsCell = (props: { qtfs: number[] }) => {
  const sorted = [...props.qtfs].sort()
  const mappedToQTF = sorted.map((qtf) => (
    <Typography key={qtf} variant="body2">
      {qtf}
    </Typography>
  ))
  return (
    <TableCell align="left">
      <Box display="flex" gap="4px">
        {mappedToQTF}
      </Box>
    </TableCell>
  )
}

const LanguagesCell = (props: { languages: string[] }) => {
  const sortedAlphabetically = [...props.languages].sort()
  const mappedToFlag = sortedAlphabetically.map((language) => (
    <Typography key={language} variant="body2">
      {flagForLanguage(language)}
    </Typography>
  ))
  return (
    <TableCell align="left">
      <Box display="flex" gap="4px">
        {mappedToFlag}
      </Box>
    </TableCell>
  )
}

const ActionsCell = (props: {
  consultantId: string
  dispatch: AppDispatch
}) => {
  const { consultantId, dispatch } = props

  return (
    <TableCell component="th" scope="row">
      <OptionsButton2
        buttonModel={{
          type: "icon",
          props: {
            sx: {
              display: "flex",
            },
            size: "small",
            children: (
              <SvgIcon
                component={appIcons.dotsHorizontal}
                inheritViewBox
                sx={{ color: "primary.main" }}
              />
            ),
          },
        }}
        items={[
          {
            type: "menu-item",
            props: {
              key: "archive-consultant",
              children: (
                <IconTextMenuItem text="Archive" icon={appIcons.logIn02} />
              ),
              onClick: () =>
                dispatch(
                  archiveConsultant({
                    consultantId: consultantId,
                  }),
                ),
            },
          },
        ]}
      />
    </TableCell>
  )
}
