import { TrackDto } from "@masterschool/course-builder-api"
import { IconTextMenuItem } from "../courses-table/course-table/coursesCells"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import AppIcon from "@utils/appIcon"
import appIcons from "@utils/appIcons"
import { popupItemClicked, PopupType } from "@features/ui/uiSlice"
import { useAppDispatch } from "@app/hooks"

export function TrackActionMenu({ track }: { track: TrackDto }) {
  const dispatch = useAppDispatch()

  const onEditTrackDetailsClicked = () => {
    dispatch(
      popupItemClicked({
        track,
        type: PopupType.EditTrackDetails,
      }),
    )
  }

  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          sx: {
            marginLeft: "auto",
          },
          size: "small",
          children: <AppIcon icon={appIcons.dotsPretty} />,
        },
      }}
      items={[
        {
          type: "menu-item",
          props: {
            key: "edit-track",
            children: (
              <IconTextMenuItem
                text="Edit details"
                icon={appIcons.pencilLine}
              />
            ),
            onClick: onEditTrackDetailsClicked,
          },
        },
      ]}
    />
  )
}
