import { Topic } from "@masterschool/course-builder-api"
import { Box } from "@mui/material"
import EditProjectType from "./editProjectType"
import { useEditEmptyProject } from "../custom-hooks/useEditElementItem"
import { useSelectedEmptyProject } from "../custom-hooks/useSelectedElementItem"
import { useAppSelector } from "@app/hooks"
import { MultiLanguageTitleTextField } from "../../components/multiLanguageFields/multiLanguageTitleTextField"

function EmptyProjectEditor(props: { topic: Topic }) {
  const { topic } = props
  const project = useSelectedEmptyProject()
  const editProject = useEditEmptyProject()
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )

  if (!project) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "16px",
          }}
        >
          <MultiLanguageTitleTextField
            elementId={project.id}
            language={editingLanguage}
            defaultLanguageValue={project.title}
            extraLanguageValueRecord={project.extraLanguageTitleRecord}
            debounce
            onChangeDefaultLanguage={(value) =>
              editProject(project, "title", value, topic)
            }
            onChangeExtraLanguage={(value) =>
              editProject(project, "extraLanguageTitleRecord", value, topic)
            }
          />
          <EditProjectType topic={topic} />
        </Box>
      </Box>
    </Box>
  )
}

export default EmptyProjectEditor
