import { useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import HighlightableScrollRef from "@cmp/highlightableScrollRef"
import { selectPublishValidations } from "@features/courseEditor/courseValidationsSelectors"
import {
  CourseLanguage,
  MasterschoolLesson,
  Topic,
} from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  SvgIcon,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import SyllabusElementFactory from "@utils/syllabusElementFactory"
import { useEffect, useRef, useState } from "react"
import appTheme from "../../../theme/appTheme"
import { useEditMasterschoolLesson } from "../custom-hooks/useEditElementItem"
import { useSelectedMasterschoolLesson } from "../custom-hooks/useSelectedElementItem"
import EditLessonType from "./EditLessonType"
import MasterschoolTasksTasksEditor from "./task-editors/masterschoolTasksEditor"
import { MultiLanguageTitleTextField } from "../../components/multiLanguageFields/multiLanguageTitleTextField"
import { MultiLanguageEditorTextField } from "../../components/multiLanguageFields/multiLanguageEditorTextField"

function MasterschoolLessonEditor(props: { topic: Topic }) {
  const { topic } = props
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )
  const lesson = useSelectedMasterschoolLesson()
  const editLesson = useEditMasterschoolLesson()

  if (!lesson) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        gap: "24px",
        overflow: "hidden",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "16px",
        }}
      >
        <MultiLanguageTitleTextField
          elementId={lesson.id}
          language={editingLanguage}
          defaultLanguageValue={lesson.title}
          extraLanguageValueRecord={lesson.extraLanguageTitleRecord}
          debounce
          onChangeDefaultLanguage={(value) => {
            editLesson(lesson, "title", value, topic)
          }}
          onChangeExtraLanguage={(value) => {
            editLesson(lesson, "extraLanguageTitleRecord", value, topic)
          }}
        />
        <EditLessonType topic={topic} />
      </Box>
      <TasksEditorContainer
        lesson={lesson}
        topic={topic}
        editingLanguage={editingLanguage}
      />
    </Box>
  )
}

const TasksEditorContainer = (props: {
  lesson: MasterschoolLesson
  topic: Topic
  editingLanguage: CourseLanguage
}) => {
  const { lesson, topic, editingLanguage } = props
  const editLesson = useEditMasterschoolLesson()
  const tasksEditorRef = useRef<HighlightableScrollRef>(null)
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false)
  const [isBulkEditing, setIsBulkEditing] = useState(false)
  const validations = useAppSelector(selectPublishValidations)
  const isInvalidBecauseMissingTasks =
    validations?.[lesson.id]?.includes("MISSING_TASKS") &&
    lesson.tasks.length === 0

  useEffect(() => {
    if (shouldScrollToBottom) {
      tasksEditorRef.current?.scrollToBottomAndHighlightLastTask()
    }
    setShouldScrollToBottom(false)
  }, [shouldScrollToBottom])

  const addTaskText =
    lesson.tasks.length === 0 ? "Add task (optional)" : "Add task"

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "start",
        gap: "16px",
        overflow: "hidden",
      }}
    >
      <MasterschoolTasksTasksEditor
        ref={tasksEditorRef}
        topic={topic}
        editingLanguage={editingLanguage}
      />
      <FormControl
        sx={{
          display: "flex",
          alignItems: "start",
          flexDirection: "row",
        }}
      >
        <Button
          variant="text"
          size="small"
          onClick={() => {
            editLesson(
              lesson,
              "tasks",
              lesson.tasks.concat(
                SyllabusElementFactory.makeMasterschoolTask(),
              ),
              topic,
            )
            setShouldScrollToBottom(true)
          }}
          startIcon={
            <SvgIcon
              component={appIcons.plusCircle}
              inheritViewBox
              sx={{
                width: "20px",
                height: "20px",
                fill: "none",
                stroke: isInvalidBecauseMissingTasks
                  ? appTheme.palette.error.main
                  : appTheme.palette.icon.black,
              }}
            />
          }
          sx={{
            color: isInvalidBecauseMissingTasks
              ? appTheme.palette.error.main
              : appTheme.palette.icon.black,
          }}
        >
          {addTaskText}
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={() => {
            setIsBulkEditing(true)
          }}
          startIcon={
            <SvgIcon
              component={appIcons.edit05}
              inheritViewBox
              sx={{
                width: "20px",
                height: "20px",
                stroke: appTheme.palette.icon.black,
                fill: "none",
              }}
            />
          }
        >
          Bulk edit
        </Button>
        <FormHelperText
          error={isInvalidBecauseMissingTasks}
          variant="standard"
          sx={{
            textTransform: "none",
            paddingTop: "4px",
            paddingLeft: "14px",
          }}
        >
          {isInvalidBecauseMissingTasks
            ? "Lesson must have at least one task"
            : ""}
        </FormHelperText>
      </FormControl>
      {isBulkEditing && (
        <BulkEditingDialog
          onClose={() => {
            setIsBulkEditing(false)
          }}
          topic={topic}
        />
      )}
    </Box>
  )
}

function BulkEditingDialog(props: { onClose: () => void; topic: Topic }) {
  const { onClose, topic } = props
  const lesson = useSelectedMasterschoolLesson()
  const editLesson = useEditMasterschoolLesson()
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )
  const [tasks, setTasks] = useState<string>("")

  if (!lesson) {
    return undefined
  }

  return (
    <GenericDialog
      open
      title="Bulk edit"
      subtitle={
        "Separate tasks titles and urls using line breaks to add tasks in bulk. " +
        "Each line should have the task title followed by a tab and then the task URL."
      }
      size="sm"
      onClose={onClose}
      content={
        <MultiLanguageEditorTextField
          label="Tasks URL and title"
          multiline
          rows={6}
          fullWidth
          variant="outlined"
          language={editingLanguage}
          defaultLanguageValue={tasks}
          extraLanguageValueRecord={lesson.extraLanguageTitleRecord}
          onChangeDefaultLanguage={(newValue: string) => {
            setTasks(newValue)
          }}
          onChangeExtraLanguage={(newExtraLanguageValueRecord) => {
            setTasks(newExtraLanguageValueRecord[editingLanguage])
            editLesson(
              lesson,
              "extraLanguageTitleRecord",
              newExtraLanguageValueRecord,
              topic,
            )
          }}
          sx={{
            width: "100%",
            paddingBottom: "24px",
          }}
        />
      }
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: onClose,
        },
        {
          text: "Save",
          type: "primary",
          onClick: () => {
            if (!tasks) {
              return
            }
            const newTasks = makeTasksFromConcatenatedString(
              tasks,
              editingLanguage,
            )
            editLesson(lesson, "tasks", lesson.tasks.concat(newTasks), topic)
            onClose()
          },
          disabled: !tasks,
        },
      ]}
    />
  )
}

function makeTasksFromConcatenatedString(
  tasks: string,
  language: CourseLanguage,
) {
  const tasksDetails = tasks.split("\n")
  return tasksDetails.msCompactMap((details) => {
    const tabIndex = details.indexOf("\t")
    if (tabIndex === -1) {
      return undefined
    }
    const title = details.substring(0, tabIndex)
    const url = details.substring(tabIndex + 1)
    if (language === CourseLanguage.English) {
      return SyllabusElementFactory.makeMasterschoolTask({
        title,
        url,
      })
    } else {
      return SyllabusElementFactory.makeMasterschoolTask({
        extraLanguageTitleRecord: { [language]: title },
        extraLanguageUrlRecord: { [language]: url },
      })
    }
  })
}

export default MasterschoolLessonEditor
