import { Component, PropsWithChildren } from "react"

interface ErrorBoundaryState {
  hasError: boolean
}

export class FilterErrorBoundary extends Component<
  PropsWithChildren,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (error.message?.includes("No filter operator found for column")) {
      console.warn("Invalid filter detected, clearing stored filters")
      localStorage.removeItem("studentsTableGridState")

      window.location.reload()
    }
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}
