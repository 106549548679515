import { useAppSelector } from "@app/hooks"
import { selectPublishValidationsForItem } from "@features/courseEditor/courseValidationsSelectors"
import EditorTextField, { EditorTextFieldProps } from "./editorTextField"
import { generateUrlErrorMessage } from "./urlTextValidation"

export type UrlTextFieldProps = EditorTextFieldProps & {
  elementId: string
  missingUrl?: boolean
  invalidUrl?: boolean
}

function UrlTextField(props: UrlTextFieldProps) {
  const errors = useAppSelector(
    selectPublishValidationsForItem(props.elementId),
  )

  const errorMessage = generateUrlErrorMessage(
    props.value,
    props.label?.toString() ?? "URL",
    props.missingUrl,
    props.invalidUrl,
    errors,
  )

  const { elementId, missingUrl, invalidUrl, ...domProps } = props

  return (
    <EditorTextField
      label={props.label || "URL"}
      error={errorMessage !== undefined}
      helperText={errorMessage}
      {...domProps}
    />
  )
}

export default UrlTextField
