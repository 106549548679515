import CoursePreview from "../../editor/coursePreview"
import { useEffect, useState } from "react"
import { CourseClient } from "@clients/courseClient"
import { CourseDto } from "@masterschool/course-builder-api"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { selectLatestPublishedCourse } from "@features/coursesMenu/coursesSelectors"
import { Stack, SvgIcon, Typography } from "@mui/material"
import { parseStringVersion } from "@utils/versionUtils"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { selectCourseInstance } from "@features/courseInstance/courseInstanceSelectors"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import { popupItemClicked, PopupType } from "@features/ui/uiSlice"
import appIcons from "@utils/appIcons"
import AppIcon from "@utils/appIcon"

export const CourseInstanceContent = (props: {
  courseInstanceId: string | undefined
}) => {
  const dispatch = useAppDispatch()
  const [courseDto, setCourseDto] = useState<CourseDto | undefined>(undefined)
  const courseInstance = useAppSelector((state) =>
    selectCourseInstance(state, props.courseInstanceId),
  )
  const courseId = courseInstance?.courseId
  const majorVersion = courseInstance?.majorVersion
  const courseLatestPublishedMajor = useAppSelector(
    selectLatestPublishedCourse(courseId, "latest"),
  )
  const shouldTakeLatestMajor = courseInstance && !majorVersion
  const latestMajorForCourse = courseLatestPublishedMajor
    ? parseStringVersion(courseLatestPublishedMajor.version).major
    : undefined

  const courseMajorVersion = shouldTakeLatestMajor
    ? latestMajorForCourse
    : majorVersion

  useEffect(() => {
    if (!courseId || !courseMajorVersion) {
      return
    }
    CourseClient.getLastPublishedVersion(courseId, courseMajorVersion).then(
      (course) => {
        setCourseDto(course)
      },
    )
  }, [courseId, courseMajorVersion])

  if (!courseDto) return <LoadingComponent loadedComponents="content" />

  return (
    <Stack sx={{ width: "720px", height: "100%" }}>
      <Stack
        sx={{
          alignItems: "flex-end",
        }}
      >
        <OptionsButton2
          menuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: -57,
            },
          }}
          buttonModel={{
            type: "button",
            props: {
              children: "Actions",
              variant: "text",
              color: "inherit",
              size: "small",
              endIcon: (
                <SvgIcon
                  inheritViewBox
                  component={appIcons.chevronDown}
                  sx={{
                    stroke: "none",
                  }}
                />
              ),
            },
          }}
          items={[
            {
              type: "menu-item",
              props: {
                key: "switch-version",
                children: (
                  <Stack direction="row" gap="8px" alignItems="center">
                    <AppIcon icon={appIcons.refreshCw05} />
                    <Typography variant="body2">Switch Version</Typography>
                  </Stack>
                ),
                onClick: () => {
                  if (!props.courseInstanceId) {
                    return
                  }
                  dispatch(
                    popupItemClicked({
                      type: PopupType.CourseInstanceVersionSwitch,
                      courseId: courseDto.id,
                      courseInstanceId: props.courseInstanceId,
                      version: courseDto.version,
                    }),
                  )
                },
              },
            },
          ]}
        />
      </Stack>
      <CoursePreview course={courseDto} sx={{ padding: "0px" }} />
    </Stack>
  )
}
