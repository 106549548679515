import { RootState } from "@app/store"
import {
  CourseDescriptorDto,
  CourseDto,
  CourseStatus,
  ProgramDto,
  SyllabusDto,
  SyllabusDtoStatusEnum,
  UnitCourseDescriptor,
} from "@masterschool/course-builder-api"
import { createSelector } from "@reduxjs/toolkit"
import { areSyllabusDtosEqual } from "./syllabusUtils"
import StaleCourseModel from "../../editor/syllabus/validations/staleCoursesPopup/staleCourseModel"
import { selectPrograms } from "../program/programSliceSelectors"
import { parseStringVersion } from "@utils/versionUtils"

export type Syllabus = SyllabusDto & {
  programStartDate?: string
  programType?: string
  isActive: boolean
  programDisplayName: string | undefined
  programId: string | undefined
}

export const selectSyllabuses = createSelector(
  [(state: RootState) => state.syllabusesMenu.syllabuses, selectPrograms],
  (syllabuses: SyllabusDto[], programs: ProgramDto[]) => {
    return syllabuses.map((syllabus) =>
      enrichSyllabusWithPrograms(syllabus, programs),
    )
  },
)

export const selectLastVersionSyllabuses = createSelector(
  [selectSyllabuses],
  (syllabuses: Syllabus[]) => getLatestSyllabusVersion(syllabuses),
)

export const selectPublishedSyllabuses = createSelector(
  [selectLastVersionSyllabuses],
  (latestVersions) => {
    return latestVersions.filter(
      (syllabus) => syllabus.status === SyllabusDtoStatusEnum.Published,
    )
  },
)

export const selectSyllabusesMainPageTab = (state: RootState) =>
  state.syllabusesMenu.tab

export const selectSyllabus = (syllabusId?: string) =>
  createSelector(
    [selectSyllabuses, selectEditableSyllabus],
    (syllabuses, editableSyllabus) => {
      if (!syllabusId) {
        return undefined
      }
      if (editableSyllabus && editableSyllabus.id === syllabusId) {
        return editableSyllabus
      }
      return syllabuses.find((syllabus) => syllabus.id === syllabusId)
    },
  )

export const selectLastPublishedSyllabus = (syllabusId?: string) =>
  createSelector([selectSyllabuses], (syllabuses) => {
    if (!syllabusId) {
      return undefined
    }

    return syllabuses
      .filter(
        (syllabus) =>
          syllabus.id === syllabusId &&
          syllabus.status === SyllabusDtoStatusEnum.Published,
      )
      .sort((a, b) => b.version - a.version)[0]
  })

export const selectSyllabusIdToName = () =>
  createSelector([selectLastVersionSyllabuses], (syllabuses) => {
    return syllabuses.reduce(
      (acc: Record<string, string>, syllabus: SyllabusDto) => {
        acc[syllabus.id] = syllabus.name ?? ""
        return acc
      },
      {},
    )
  })

export const selectDraftSyllabus = (syllabusId?: string) =>
  createSelector(
    [selectSyllabuses, selectEditableSyllabus],
    (syllabuses, editable) => {
      if (!syllabusId) {
        return undefined
      }

      if (editable?.id === syllabusId) {
        return editable
      }

      return syllabuses
        .filter(
          (syllabus) =>
            syllabus.id === syllabusId &&
            syllabus.status === SyllabusDtoStatusEnum.Draft,
        )
        .sort((a, b) => b.version - a.version)[0]
    },
  )

export const selectSyllabusFromMenu = (syllabusId?: string) =>
  createSelector([selectSyllabuses], (syllabuses) => {
    if (!syllabusId) {
      return undefined
    }

    return syllabuses.find((syllabus) => syllabus.id === syllabusId)
  })

export const selectEditableSyllabus: (
  state: RootState,
) => Syllabus | undefined = createSelector(
  [(state: RootState) => state.syllabusEditor.syllabus, selectPrograms],
  (syllabus: SyllabusDto | undefined, programs: ProgramDto[]) => {
    if (syllabus === undefined) {
      return undefined
    }
    return enrichSyllabusWithPrograms(syllabus, programs)
  },
)

export const selectIsFirstUnitInEditableSyllabus = (
  unitId: string | undefined,
) =>
  createSelector([selectEditableSyllabus], (syllabus) => {
    if (!syllabus) {
      return false
    }
    return syllabus.units[0].id === unitId
  })

export function enrichSyllabusWithPrograms(
  syllabus: SyllabusDto,
  programs: ProgramDto[],
): Syllabus {
  const program = programs.find((program) => program.syllabusId === syllabus.id)

  const programDisplayName = function () {
    if (program === undefined) {
      return undefined
    }

    const date = new Date(program.startDate).toLocaleDateString("en-US", {
      year: "2-digit",
      month: "short",
    })
    return `${program.domain} ${date})`
  }

  return {
    ...syllabus,
    programStartDate: program?.startDate,
    domain: program?.domain ?? syllabus.domain,
    isActive: program !== undefined,
    programDisplayName: programDisplayName(),
    programType: program?.type,
    programId: program?.id,
  }
}

export const selectSyllabusesSortOption = (state: RootState) => {
  return state.syllabusesMenu.sortOption
}

export const selectUnit =
  (syllabusId: string, unitId: string) => (state: RootState) =>
    state.syllabusesMenu.syllabuses
      .find((syllabus) => syllabus.id === syllabusId)
      ?.units.find((unit) => unit.id === unitId)

export const selectFetchedCourses = (state: RootState) =>
  state.syllabusesMenu.courses

export const selectUnitCourses = (syllabusId: string, unitId: string) =>
  createSelector(
    [selectUnit(syllabusId, unitId), selectFetchedCourses],
    (unit, courses) => {
      if (!unit) {
        return []
      }

      return unit.courseDescriptors
        .map(
          (courseDescriptor) =>
            courses[courseDescriptor.courseId]?.[courseDescriptor.version],
        )
        .filter((c): c is CourseDto => c !== undefined)
    },
  )

export const selectUnitCourse = (descriptor: UnitCourseDescriptor) =>
  createSelector([selectFetchedCourses], (courses) => {
    if (!courses[descriptor.courseId]) {
      return undefined
    }
    const { major, minor } = parseStringVersion(descriptor.version)
    const courseVersions = Object.values(courses[descriptor.courseId] || {})
    return courseVersions.find((courseVersion) => {
      const { major: courseMajor, minor: courseMinor } = parseStringVersion(
        courseVersion.version,
      )
      return courseMajor === major && courseMinor === minor
    })
  })

export const selectDoesSyllabusChanges = () =>
  createSelector(
    [
      (state: RootState) => state.syllabusEditor.syllabus,
      (state: RootState) => state.syllabusEditor.lastSavedSyllabus,
    ],
    (syllabus, lastSavedSyllabus) => {
      if (!syllabus || !lastSavedSyllabus) return false

      return !areSyllabusDtosEqual(syllabus, lastSavedSyllabus)
    },
  )

export const selectIsSyllabusPublishable = (syllabusId: string) =>
  createSelector(
    [selectLastPublishedSyllabus(syllabusId), selectDraftSyllabus(syllabusId)],
    (lastPublished, draft) => {
      if (!lastPublished) return true
      if (
        lastPublished &&
        draft &&
        !areSyllabusDtosEqual(lastPublished, draft)
      ) {
        return true
      }

      return false
    },
  )

export const selectSyllabusStaleCourses = (syllabusId?: string) =>
  createSelector(
    [
      selectSyllabus(syllabusId),
      (state: RootState) => state.coursesMenu.courses,
    ],
    (syllabus, courses) => {
      if (!syllabus || courses === "pending" || courses === "rejected") {
        return []
      }

      return syllabus.units.flatMap((unit) => {
        return unit.courseDescriptors
          .map((descriptor) => {
            const newVersion = newerVersionOfCourseIfExists(descriptor, courses)

            if (!newVersion) {
              return undefined
            }

            return {
              syllabusId: syllabus.id,
              unitId: unit.id,
              courseId: descriptor.courseId,
              currentVersion: descriptor.version,
              newVersion: newVersion.version,
            }
          })
          .filter((c) => c !== undefined) as StaleCourseModel[]
      })
    },
  )

function newerVersionOfCourseIfExists(
  courseDescriptor: UnitCourseDescriptor,
  courses: CourseDescriptorDto[],
): CourseDescriptorDto | undefined {
  return courses.find(
    (c) =>
      c.id === courseDescriptor.courseId &&
      c.version > courseDescriptor.version &&
      c.status === CourseStatus.Published,
  )
}

export const selectIsUnitStale =
  (syllabusId: string, unitId: string) => (state: RootState) => {
    const staleCourses = selectSyllabusStaleCourses(syllabusId)(state)

    return staleCourses.some((staleCourse) => staleCourse.unitId === unitId)
  }

export const selectIsArchiveSyllabus = (syllabusId?: string) =>
  createSelector([selectSyllabuses], (syllabuses) => {
    if (!syllabusId) {
      return undefined
    }

    return (
      syllabuses.find(
        (syllabus) =>
          syllabus.id === syllabusId &&
          syllabus.status === SyllabusDtoStatusEnum.Archived,
      ) === undefined
    )
  })

export const selectSyllabusWithVersion = (
  syllabusId?: string,
  version?: number,
) =>
  createSelector([selectSyllabuses], (syllabuses) => {
    if (!syllabusId) {
      return undefined
    }

    if (version === undefined) {
      return syllabuses
        .filter((syllabus) => syllabus.id === syllabusId)
        .sort((a, b) => b.version - a.version)[0]
    }

    return syllabuses.find(
      (syllabus) => syllabus.id === syllabusId && syllabus.version === version,
    )
  })

export function getLatestSyllabusVersion(syllabuses: Syllabus[]) {
  const allSyllabusesBesideDraftsWithHighVersion = syllabuses.filter(
    (syllabus) =>
      (syllabus.status === SyllabusDtoStatusEnum.Draft &&
        syllabus.version === 1) ||
      syllabus.status !== SyllabusDtoStatusEnum.Draft,
  )

  return Object.values(
    allSyllabusesBesideDraftsWithHighVersion.reduce((acc, syllabus) => {
      const existingSyllabus = acc[syllabus.id]
      if (
        !existingSyllabus ||
        (syllabus.version ?? 1) > acc[syllabus.id].version
      ) {
        acc[syllabus.id] = syllabus
      }
      return acc
    }, {} as Record<string, Syllabus>),
  )
}

export function selectLastSyllabusVersion(syllabusId?: string) {
  return createSelector([selectSyllabuses], (syllabuses) => {
    if (!syllabusId) {
      return undefined
    }
    return syllabuses
      .filter((syllabus) => syllabus.id === syllabusId)
      .sort((a, b) => b.version - a.version)[0]
  })
}
