import { useAppDispatch, useAppSelector } from "@app/hooks"
import { editingLanguageChanged } from "@features/courseEditor/courseEditorSlice"
import { CourseLanguage } from "@masterschool/course-builder-api"
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import appTheme from "../theme/appTheme"

export const CourseLanguageToggle = () => {
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )
  const dispatch = useAppDispatch()

  const onTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    type: CourseLanguage | null,
  ) => {
    if (!type) return
    dispatch(editingLanguageChanged(type))
  }

  return (
    <ToggleButtonGroup
      value={editingLanguage}
      exclusive
      onChange={onTypeChange}
      sx={{
        height: "32px",
      }}
    >
      <LanguageToggleButton
        value={CourseLanguage.English}
        isSelected={editingLanguage === CourseLanguage.English}
      />
      <LanguageToggleButton
        value={CourseLanguage.German}
        isSelected={editingLanguage === CourseLanguage.German}
      />
    </ToggleButtonGroup>
  )
}

export const LanguageToggleButton = (props: {
  value: CourseLanguage
  isSelected: boolean
}) => {
  const prefixFlag = props.value === CourseLanguage.English ? "🇬🇧" : "🇩🇪"
  return (
    <ToggleButton
      value={props.value}
      sx={{
        width: "128px",
        borderColor: appTheme.palette.other.outlineBorder,
        borderRadius: "2px",
        "&.Mui-selected": {
          backgroundColor: appTheme.palette.eTypes.lightGreen,
        },
      }}
    >
      <Typography
        variant={props.isSelected ? "body2_sb" : "body2"}
        color="text.primary"
      >
        {`${prefixFlag} ${props.value}`}
      </Typography>
    </ToggleButton>
  )
}
