import { GeneralNavigationSideBar } from "@cmp/sidebar/generalNavigationSidebar"
import { Typography } from "@mui/material"
import useSpecificSearchParam from "@utils/hooks/useSpecificSearchParams"
import { useParams } from "react-router-dom"
import appTheme from "../../theme/appTheme"
import { useEffect } from "react"

export enum ProgramTabIdentifier {
  ProgramOverview = "program overview",
  LegacyProgramHome = "home",
  Syllabus = "syllabus",
  TrackVersion = "track version",
  Students = "students",
  Staff = "staff",
  Slack = "slack",
  Projects = "projects",
  Classes = "classes",
  ProgramCalendar = "calendar",
}

const legacyProgramTabs = [
  ProgramTabIdentifier.LegacyProgramHome,
  ProgramTabIdentifier.Syllabus,
  ProgramTabIdentifier.Students,
  ProgramTabIdentifier.Staff,
  ProgramTabIdentifier.Slack,
  ProgramTabIdentifier.Projects,
  ProgramTabIdentifier.Classes,
]
const newGenProgramTabs = [
  ProgramTabIdentifier.ProgramOverview,
  ProgramTabIdentifier.TrackVersion,
  ProgramTabIdentifier.Students,
  ProgramTabIdentifier.ProgramCalendar,
  ProgramTabIdentifier.Staff,
]

export function defaultProgramTab(isLegacy: boolean) {
  return isLegacy
    ? ProgramTabIdentifier.LegacyProgramHome
    : ProgramTabIdentifier.ProgramOverview
}

function ProgramSidebar(props: { isLegacy: boolean }) {
  const { isLegacy } = props
  const { classId } = useParams()
  const [tab, setTab] = useSpecificSearchParam("tab")
  const showClassesTab = classId === undefined
  const showStaffTab = classId === undefined

  const tabsIdentifiers = isLegacy
    ? legacyProgramTabs
    : newGenProgramTabs
        .filter((tab) => tab !== ProgramTabIdentifier.Classes || showClassesTab)
        .filter((tab) => tab !== ProgramTabIdentifier.Staff || showStaffTab)

  const selectedTab =
    tab && tabsIdentifiers.includes(tab as ProgramTabIdentifier)
      ? tab
      : defaultProgramTab(isLegacy)

  const tabs = tabsIdentifiers.map((tab) => (
    <Typography
      key={tab}
      variant={tab === selectedTab ? "body2_sb" : "body2"}
      sx={{
        display: "block",
        borderRadius: "8px",
        bgcolor: tab === selectedTab ? appTheme.palette.eTypes.sand50 : "none",
        cursor: "pointer",
        padding: "8px 16px",
        textTransform: "capitalize",
      }}
      onClick={() => setTab(tab)}
    >
      {tab}
    </Typography>
  ))

  useEffect(() => {
    if (tab && !tabsIdentifiers.includes(tab as ProgramTabIdentifier)) {
      setTab(defaultProgramTab(isLegacy))
    }
  }, [classId, tab, setTab, tabsIdentifiers, isLegacy])

  return (
    <GeneralNavigationSideBar
      tabs={tabs}
      sx={{
        width: "216px",
        maxWidth: "216px",
        minWidth: "216px",
        minHeight: "100%",
        padding: "16px 24px",
        bgcolor: isLegacy
          ? appTheme.palette.eTypes.sand15
          : appTheme.palette.primary.contrast,
      }}
      listSx={{
        pt: "16px",
      }}
    />
  )
}

export default ProgramSidebar
