import { Box } from "@mui/material"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import { useEditTryHackmeLesson } from "../custom-hooks/useEditElementItem"
import { useSelectedTryHackmeLesson } from "../custom-hooks/useSelectedElementItem"
import EditLessonType from "./EditLessonType"
import { Topic } from "@masterschool/course-builder-api"
import { MultiLanguageTitleTextField } from "../../components/multiLanguageFields/multiLanguageTitleTextField"
import MultiLanguageUrlTextField from "../../components/multiLanguageFields/multipleLanguageUrlTextField"
import { useAppSelector } from "@app/hooks"

function TryHackmeLessonEditor(props: { topic: Topic }) {
  const { topic } = props
  const lesson = useSelectedTryHackmeLesson()
  const editLesson = useEditTryHackmeLesson()
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )

  if (!lesson) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "16px",
        }}
      >
        <MultiLanguageTitleTextField
          elementId={lesson.id}
          language={editingLanguage}
          defaultLanguageValue={lesson.title}
          extraLanguageValueRecord={lesson.extraLanguageTitleRecord}
          debounce
          onChangeDefaultLanguage={(value) => {
            editLesson(lesson, "title", value, topic)
          }}
          onChangeExtraLanguage={(value) => {
            editLesson(lesson, "extraLanguageTitleRecord", value, topic)
          }}
        />
        <EditLessonType topic={topic} />
      </Box>
      <TimeEstimationTextField
        elementId={lesson.id}
        value={lesson.estimatedDuration}
        debounce
        onChange={(e) => {
          editLesson(
            lesson,
            "estimatedDuration",
            parseInt(e.target.value),
            topic,
          )
        }}
      />
      <MultiLanguageUrlTextField
        elementId={lesson.id}
        language={editingLanguage}
        defaultLanguageValue={lesson.url}
        extraLanguageValueRecord={lesson.extraLanguageUrlRecord}
        debounce
        onChangeDefaultLanguage={(value) => {
          editLesson(lesson, "url", value, topic)
        }}
        onChangeExtraLanguage={(value) => {
          editLesson(lesson, "extraLanguageUrlRecord", value, topic)
        }}
      />
    </Box>
  )
}

export default TryHackmeLessonEditor
