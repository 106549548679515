import { CourseLanguage } from "@masterschool/course-builder-api"
import { selectAmongLanguages } from "@utils/courseUtils"
import EditorTextField, { EditorTextFieldProps } from "../editorTextField"
import { MultiLanguageProps } from "./multiLanguage.types"

export type MultiLanguageTextFieldEditorProps = EditorTextFieldProps &
  MultiLanguageProps

export function MultiLanguageEditorTextField(
  props: MultiLanguageTextFieldEditorProps,
) {
  const {
    defaultLanguageValue: defaultValue,
    extraLanguageValueRecord,
    onChangeDefaultLanguage,
    onChangeExtraLanguage,
    label,
    language,
    error,
    helperText,
    ...rest
  } = props
  const { sx: restSx, ...restWithoutSx } = rest

  const isDefaultLanguage = language === CourseLanguage.English

  const value = selectAmongLanguages(
    defaultValue,
    extraLanguageValueRecord,
    language,
  )
  const markSameAsDefault = value === defaultValue && !isDefaultLanguage

  const onEditValue = (newValues: string) => {
    if (isDefaultLanguage) {
      onChangeDefaultLanguage(newValues)
    } else {
      const newExtraLanguageDescriptionRecord = {
        ...extraLanguageValueRecord,
        [language]: newValues,
      }
      onChangeExtraLanguage(newExtraLanguageDescriptionRecord)
    }
  }

  return (
    <EditorTextField
      debounce
      {...restWithoutSx}
      value={value}
      onChange={(event) => {
        onEditValue(event.target.value)
      }}
      error={isDefaultLanguage && error}
      helperText={isDefaultLanguage && helperText}
      label={label}
      size="small"
      InputLabelProps={{
        sx: {
          fontSize: "14px",
        },
      }}
      sx={{
        ...restSx,
        width: "100%",
        ".MuiOutlinedInput-root": {
          bgcolor: markSameAsDefault ? "rgba(237, 108, 2, 0.1)" : "white",
        },
      }}
    />
  )
}
