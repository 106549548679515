import {
  Box,
  Button,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"
import WidgetCard from "./widgetCard"
import { ProgramHomepages } from "@masterschool/course-builder-api"
import appIcons from "@utils/appIcons"
import appTheme from "../../../../theme/appTheme"
import { ProgramClient } from "@clients/programClient"
import { useState } from "react"
import GenericDialog from "@cmp/genericDialog"
import { useAppDispatch } from "@app/hooks"
import { fetchPrograms } from "@features/program/programSliceThunks"
import { showSuccessSnackbar } from "@features/ui/uiSlice"

export const ProgramHomepagesWidget = (props: {
  programId: string
  homepages: ProgramHomepages | undefined
}) => {
  const programId = props.programId
  const [homepages, setHomepages] = useState<ProgramHomepages | undefined>(
    props.homepages,
  )
  const englishUrl = homepages?.english
  const germanUrl = homepages?.german

  const [showAddEnglishPageDialog, setShowAddEnglishPageDialog] =
    useState(false)
  const [showAddGermanPageDialog, setShowAddGermanPageDialog] = useState(false)
  const dispatch = useAppDispatch()

  const onSuccess = (language: string, newUrl: string | undefined) => {
    const action = newUrl ? "added" : "removed"
    dispatch(
      showSuccessSnackbar(`Homepage for ${language} ${action} successfully`),
    )
    dispatch(fetchPrograms())
  }

  const onAddEngish = (newUrl: string | undefined) => {
    setHomepages({ ...homepages, english: newUrl })
    ProgramClient.updateProgramHomepages(programId, {
      english: newUrl,
      german: homepages?.german,
    }).then(() => onSuccess("English", newUrl))
  }

  const onAddGerman = (newUrl: string | undefined) => {
    setHomepages({ ...homepages, german: newUrl })
    ProgramClient.updateProgramHomepages(programId, {
      english: homepages?.english,
      german: newUrl,
    }).then(() => onSuccess("German", newUrl))
  }

  return (
    <WidgetCard title="Homepages">
      <EditHomepageDialog
        open={showAddEnglishPageDialog}
        onClose={() => setShowAddEnglishPageDialog(false)}
        onSubmit={onAddEngish}
        currentUrl={homepages?.english}
        language="english"
      />
      <EditHomepageDialog
        open={showAddGermanPageDialog}
        onClose={() => setShowAddGermanPageDialog(false)}
        onSubmit={onAddGerman}
        currentUrl={homepages?.german}
        language="german"
      />
      <Stack gap="16px">
        <Stack direction="row" alignItems="center" gap="16px">
          <Typography>🇬🇧 EN</Typography>
          {englishUrl && <Typography>{englishUrl}</Typography>}
          <AddButton
            onClick={() => setShowAddEnglishPageDialog(true)}
            edit={englishUrl !== undefined}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap="16px">
          <Typography>🇩🇪 DE</Typography>
          {germanUrl && <Typography>{germanUrl}</Typography>}
          <AddButton
            onClick={() => setShowAddGermanPageDialog(true)}
            edit={germanUrl !== undefined}
          />
        </Stack>
      </Stack>
    </WidgetCard>
  )
}

const AddButton = (props: { onClick: () => void; edit: boolean }) => {
  const { onClick, edit } = props
  return (
    <Button
      variant="contained"
      sx={{
        width: "fit-content",
        flexShrink: 0,
        color: appTheme.palette.text.primary,
        backgroundColor: edit
          ? "transparent"
          : appTheme.palette.sand.containedHoverBackground,
        "&:hover": {
          backgroundColor: edit
            ? appTheme.palette.sand.containedHoverBackground
            : appTheme.palette.sand.outlinedHoverBackground,
        },
      }}
      onClick={onClick}
      size="small"
      startIcon={
        <SvgIcon
          component={edit ? appIcons.edit02 : appIcons.plus}
          sx={{
            stroke: appTheme.palette.text.primary,
            fill: edit ? "none" : "inherit",
            width: "20px",
            height: "20px",
          }}
          inheritViewBox
        />
      }
    >
      {edit ? "Edit" : "Add"}
    </Button>
  )
}

const EditHomepageDialog = (props: {
  open: boolean
  onSubmit: (homepageUrl: string | undefined) => void
  onClose: () => void
  currentUrl: string | undefined
  language: "english" | "german"
}) => {
  const { open, language, currentUrl } = props
  const { onSubmit, onClose } = props
  const languageText = language === "english" ? "English" : "German"
  const [url, setUrl] = useState(currentUrl)

  const isEditing = currentUrl !== undefined
  const titlePrefix = isEditing ? "Edit" : "Add a link to"

  let addButtonText = "Add"
  if (isEditing) {
    addButtonText = url === "" ? "Remove" : "Edit"
  }

  return (
    <GenericDialog
      open={open}
      size="sm"
      icon={
        language === "english" ? (
          <Typography>🇬🇧</Typography>
        ) : (
          <Typography>🇩🇪</Typography>
        )
      }
      onClose={onClose}
      title={`${titlePrefix} this program's ${languageText} notion page`}
      subtitle={`Students will see this page on campus under the "Program" tab`}
      content={
        <Box paddingTop="16px" display="flex" flexDirection="column">
          <TextField
            type="url"
            value={url}
            label="Program page link"
            onChange={(e) => setUrl(e.target.value)}
            error={!url?.includes("www.notion.so")}
            helperText={
              url?.includes("www.notion.so")
                ? undefined
                : "Link must start with 'www.notion.so'"
            }
          />
        </Box>
      }
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: addButtonText === "Remove" ? "danger" : "primary",
          text: addButtonText,
          disabled: !isEditing && !url,
          onClick: () => {
            if (url === "") {
              onSubmit(undefined)
            } else {
              onSubmit(url)
            }
            onClose()
          },
        },
      ]}
    />
  )
}
