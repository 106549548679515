import { useAppSelector } from "@app/hooks"
import { selectPublishValidationsForItem } from "@features/courseEditor/courseValidationsSelectors"
import { MultiLanguageProps } from "./multiLanguage.types"
import { MultiLanguageEditorTextField } from "./multiLanguageEditorTextField"
import { UrlTextFieldProps } from "../urlTextField"
import { generateUrlErrorMessage } from "../urlTextValidation"

export type MultiLanguageTitleTextFieldProps = UrlTextFieldProps &
  MultiLanguageProps

function MultiLanguageUrlTextField(props: MultiLanguageTitleTextFieldProps) {
  const errors = useAppSelector(
    selectPublishValidationsForItem(props.elementId),
  )
  const errorMessage = generateUrlErrorMessage(
    props.value,
    props.label?.toString() ?? "URL",
    props.missingUrl,
    props.invalidUrl,
    errors,
  )
  const { elementId, ...domProps } = props

  return (
    <MultiLanguageEditorTextField
      label={props.label || "URL"}
      error={errorMessage !== undefined}
      helperText={errorMessage}
      {...domProps}
    />
  )
}

export default MultiLanguageUrlTextField
