import { useAppSelector } from "@app/hooks"
import { CourseDto, CourseType } from "@masterschool/course-builder-api"
import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  Typography,
} from "@mui/material"
import { useState } from "react"
import { selectShowCourseInvalidErrorForField } from "@features/courseEditor/courseEditorSelectors"
import { DomainsEnumeration } from "@features/search/searchSlice"
import EditorTextField from "./components/editorTextField"
import { MultipleTagsSelectionInput } from "./components/multipleTagsSelectionInput"
import useUpdateCourse from "./custom-hooks/useUpdateCourse"
import appTheme from "../theme/appTheme"
import { domainToDisplayName } from "@utils/domainUtils"
import { MultiLanguageEditorTextField } from "./components/multiLanguageFields/multiLanguageEditorTextField"

function CourseEditorGeneralInfo(props: { course: CourseDto }) {
  const updateCourse = useUpdateCourse()
  const showInvalidNameError = useAppSelector(
    selectShowCourseInvalidErrorForField("name"),
  )
  const showInvalidTitleError = useAppSelector(
    selectShowCourseInvalidErrorForField("title"),
  )
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )
  const { course } = props

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      {course.type === CourseType.Legacy && (
        <>
          <Typography variant="body2" color="text.secondary" sx={{ mb: "8px" }}>
            Fill in all required fields to publish your course.
          </Typography>
          <EditorTextField
            label="Internal name"
            value={course.name}
            debounce
            onChange={(e) => {
              updateCourse("name", e.target.value)
            }}
            error={showInvalidNameError}
            helperText={showInvalidNameError ? "This field is required" : ""}
          />
          <EditorTextField
            label="Display name"
            value={course.title ?? ""}
            debounce
            onChange={(e) => {
              updateCourse("title", e.target.value)
            }}
            error={showInvalidTitleError}
            helperText={showInvalidTitleError ? "This field is required" : ""}
          />
          <MultipleTagsSelectionForm
            fieldName="Programs"
            selectedTags={course.domains}
            onEdit={(tags: string[]) => updateCourse("domains", tags)}
            sx={{
              bgcolor: appTheme.palette.primary.contrast,
            }}
          />
        </>
      )}

      <MultiLanguageEditorTextField
        label="Course description (optional)"
        rows={4}
        multiline
        fullWidth
        sx={{ width: "inherit" }}
        InputProps={{ sx: { fontSize: "14px", padding: "16px" } }}
        language={editingLanguage}
        defaultLanguageValue={course.description}
        extraLanguageValueRecord={course.extraLanguageDescriptionRecord}
        onChangeDefaultLanguage={(newValue: string) =>
          updateCourse("description", newValue)
        }
        onChangeExtraLanguage={(newExtraLanguageValueRecord) =>
          updateCourse(
            "extraLanguageDescriptionRecord",
            newExtraLanguageValueRecord,
          )
        }
      />
    </Box>
  )
}

export default CourseEditorGeneralInfo

function MultipleTagsSelectionForm(props: {
  fieldName: string
  selectedTags: string[]
  onEdit: (tags: string[]) => void
  sx?: FormControlProps["sx"]
}) {
  const { fieldName, selectedTags, onEdit } = props

  const [isFirstEdit, setIsFirstEdit] = useState(true)
  const isValid = selectedTags.length > 0 || isFirstEdit

  return (
    <FormControl>
      <MultipleTagsSelectionInput
        options={DomainsEnumeration}
        selectedTags={selectedTags}
        onChange={(domains) => {
          setIsFirstEdit(false)
          onEdit(domains)
        }}
        getOptionLabel={domainToDisplayName}
        title={fieldName}
        placeholder="Select domains"
        error={!isValid}
        sx={{
          bgcolor: appTheme.palette.primary.contrast,
        }}
      />
      <FormHelperText
        error={!isValid}
        variant="standard"
        sx={{
          paddingLeft: "14px",
          textTransform: "none",
          letterSpacing: "0px",
        }}
      >
        {isValid ? "" : `${fieldName} are mandatory`}
      </FormHelperText>
    </FormControl>
  )
}
