import { useAppDispatch, useAppSelector } from "@app/hooks"
import Droppable from "@cmp/droppable"
import HighlightableScrollRef from "@cmp/highlightableScrollRef"
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  CourseLanguage,
  MasterschoolLesson,
  MasterschoolTask,
  Topic,
} from "@masterschool/course-builder-api"
import { Box, Typography } from "@mui/material"
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { createPortal } from "react-dom"
import {
  dragTaskEnded,
  dragTaskStarted,
  taskDraggedOver,
} from "@features/courseEditor/courseEditorSlice"
import { useEditMasterschoolLesson } from "../../custom-hooks/useEditElementItem"
import { useSelectedMasterschoolLesson } from "../../custom-hooks/useSelectedElementItem"
import MasterschoolTaskEditor from "./masterschoolTaskEditor"
import TimeEstimationTextField from "../../../components/timeEstimationTextField"
import MultiLanguageUrlTextField from "../../../components/multiLanguageFields/multipleLanguageUrlTextField"

type MasterschoolTasksTasksEditorProps = {
  topic: Topic
  editingLanguage: CourseLanguage
}

const MasterschoolTasksTasksEditor = forwardRef<
  HighlightableScrollRef,
  MasterschoolTasksTasksEditorProps
>((props, ref) => {
  const { topic, editingLanguage } = props
  const lesson = useSelectedMasterschoolLesson()
  const tasksContainerRef = useRef<HTMLDivElement>(null)

  const [highlightLastTask, setHighlightLastTask] = useState(false)
  const scrollToBottomAndHighlightLastTask = useCallback(() => {
    if (!tasksContainerRef.current) {
      return
    }

    tasksContainerRef.current.onscrollend = () => {
      setHighlightLastTask(true)
      setTimeout(() => {
        setHighlightLastTask(false)
      }, 1000)

      if (tasksContainerRef.current) {
        tasksContainerRef.current.onscrollend = null
      }
    }
    tasksContainerRef.current.scrollTo({
      top: tasksContainerRef.current.scrollHeight,
      behavior: "smooth",
    })
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      scrollToBottomAndHighlightLastTask: scrollToBottomAndHighlightLastTask,
    }),
    [scrollToBottomAndHighlightLastTask],
  )

  if (!lesson) {
    return undefined
  }
  const hasMultipleTasks = lesson.tasks.length > 1

  return (
    <Box
      ref={tasksContainerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      {hasMultipleTasks ? (
        <TasksList
          lesson={lesson}
          highlightLastTask={highlightLastTask}
          topic={topic}
          editingLanguage={editingLanguage}
        />
      ) : (
        <SingleTaskMode
          lesson={lesson}
          topic={topic}
          editingLanguage={editingLanguage}
        />
      )}
    </Box>
  )
})

function TasksList(props: {
  lesson: MasterschoolLesson
  highlightLastTask: boolean
  topic: Topic
  editingLanguage: CourseLanguage
}) {
  const { lesson, highlightLastTask, topic, editingLanguage } = props
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  )
  const dispatch = useAppDispatch()
  const isDraggingTasksEnabled = useAppSelector(
    (state) => state.courseEditor.isDraggingTasksEnabled,
  )
  const draggedTaskId = useAppSelector(
    (state) => state.courseEditor.draggedTaskId,
  )
  const draggedTask = lesson.tasks.find((t) => t.id === draggedTaskId)
  const editLesson = useEditMasterschoolLesson()
  const onChange = <K extends keyof MasterschoolTask>(
    task: MasterschoolTask,
    taskIndex: number,
    key: K,
    value: MasterschoolTask[K],
  ) => {
    editLesson(
      lesson,
      "tasks",
      [
        ...lesson.tasks.slice(0, taskIndex),
        {
          ...task,
          [key]: value,
        },
        ...lesson.tasks.slice(taskIndex + 1),
      ],
      topic,
    )
  }

  const [tasksBeingRemoved, setTasksBeingRemoved] = useState<string[]>([])

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {lesson.tasks.length > 0 && (
        <Typography variant="body2_sb">Tasks</Typography>
      )}
      <DndContext
        onDragStart={(start) => {
          dispatch(dragTaskStarted({ id: start.active.id.toString() }))
        }}
        onDragEnd={() => {
          dispatch(dragTaskEnded())
        }}
        onDragCancel={() => {
          dispatch(dragTaskEnded())
        }}
        onDragOver={({ active, over }) => {
          if (!active || !over) {
            return
          }
          dispatch(
            taskDraggedOver({
              editStepId: window.crypto.randomUUID(),
              lessonId: lesson.id,
              sourceIndex: active.data.current?.sortable?.index,
              targetIndex: over.data.current?.sortable?.index,
            }),
          )
        }}
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
      >
        <Droppable
          id={`${lesson.id}-tasks`}
          items={lesson.tasks.map((task, index) => {
            const isLast = index === lesson.tasks.length - 1
            return {
              element: (
                <MasterschoolTaskEditor
                  task={task}
                  index={index}
                  editingLanguage={editingLanguage}
                  onChange={(key, value) => {
                    onChange(task, index, key, value)
                  }}
                  onDeleteClicked={() => {
                    setTasksBeingRemoved([...tasksBeingRemoved, task.id])
                    setTimeout(() => {
                      editLesson(
                        lesson,
                        "tasks",
                        [
                          ...lesson.tasks.slice(0, index),
                          ...lesson.tasks.slice(index + 1),
                        ],
                        topic,
                      )
                    }, 200)
                  }}
                  sx={{
                    opacity:
                      tasksBeingRemoved.includes(task.id) ||
                      task.id === draggedTaskId
                        ? 0
                        : 1,
                    transform:
                      highlightLastTask && isLast
                        ? "scale(1.05)"
                        : task.id === draggedTaskId
                        ? "scale(0.95)"
                        : "scale(1)",
                    transition: "all 0.2s ease-in-out",
                  }}
                />
              ),
              identifier: task.id,
            }
          })}
          placeholder={<></>}
          disabled={!isDraggingTasksEnabled}
        />
        {createPortal(
          <DragOverlay
            dropAnimation={{
              duration: 300,
              easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
            }}
            style={{
              zIndex: 9999999,
            }}
          >
            {draggedTask ? (
              <MasterschoolTaskEditor
                task={draggedTask}
                editingLanguage={editingLanguage}
                index={NaN}
                onChange={() => {}}
                onDeleteClicked={() => {}}
                sx={{
                  transform: "scale(0.95)",
                }}
              />
            ) : null}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>
    </div>
  )
}

export default MasterschoolTasksTasksEditor

const SingleTaskMode = (props: {
  lesson: MasterschoolLesson
  topic: Topic
  editingLanguage: CourseLanguage
}) => {
  const { lesson, topic, editingLanguage } = props

  const task = lesson.tasks[0]
  const editLesson = useEditMasterschoolLesson()
  const onChange = <K extends keyof MasterschoolTask>(
    task: MasterschoolTask,
    key: K,
    value: MasterschoolTask[K],
  ) => {
    editLesson(
      lesson,
      "tasks",
      [
        {
          ...task,
          [key]: value,
        },
      ],
      topic,
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: "16px",
        paddingTop: "8px",
      }}
    >
      <MultiLanguageUrlTextField
        elementId={task.id}
        language={editingLanguage}
        defaultLanguageValue={task.url}
        extraLanguageValueRecord={task.extraLanguageUrlRecord}
        debounce
        onChangeDefaultLanguage={(value) => {
          onChange(task, "url", value)
        }}
        onChangeExtraLanguage={(value) => {
          onChange(task, "extraLanguageUrlRecord", value)
        }}
      />
      <TimeEstimationTextField
        elementId={lesson.id}
        value={task.estimatedDuration}
        debounce
        onChange={(e) => {
          onChange(task, "estimatedDuration", parseInt(e.target.value))
        }}
        sx={{ minWidth: "180px" }}
      />
    </Box>
  )
}
