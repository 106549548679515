import { Box } from "@mui/material"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import { useEditSurvey } from "../custom-hooks/useEditElementItem"
import { useSelectedSurvey } from "../custom-hooks/useSelectedElementItem"
import { Topic } from "@masterschool/course-builder-api"
import { useAppSelector } from "@app/hooks"
import { MultiLanguageTitleTextField } from "../../components/multiLanguageFields/multiLanguageTitleTextField"
import MultiLanguageUrlTextField from "../../components/multiLanguageFields/multipleLanguageUrlTextField"

function SurveyEditor(props: { topic: Topic }) {
  const { topic } = props
  const survey = useSelectedSurvey()
  const editSurvey = useEditSurvey()
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )

  if (!survey) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <MultiLanguageTitleTextField
          elementId={survey.id}
          defaultLanguageValue={survey.title}
          extraLanguageValueRecord={survey.extraLanguageTitleRecord}
          language={editingLanguage}
          debounce
          onChangeDefaultLanguage={(value) =>
            editSurvey(survey, "title", value, topic)
          }
          onChangeExtraLanguage={(value) =>
            editSurvey(survey, "extraLanguageTitleRecord", value, topic)
          }
        />
        <TimeEstimationTextField
          elementId={survey.id}
          value={survey.estimatedDuration}
          debounce
          onChange={(e) => {
            editSurvey(
              survey,
              "estimatedDuration",
              parseInt(e.target.value),
              topic,
            )
          }}
        />
        <MultiLanguageUrlTextField
          elementId={survey.id}
          defaultLanguageValue={survey.url}
          extraLanguageValueRecord={survey.extraLanguageUrlRecord}
          language={editingLanguage}
          debounce
          onChangeDefaultLanguage={(value) =>
            editSurvey(survey, "url", value, topic)
          }
          onChangeExtraLanguage={(value) =>
            editSurvey(survey, "extraLanguageUrlRecord", value, topic)
          }
        />
      </Box>
    </Box>
  )
}

export default SurveyEditor
