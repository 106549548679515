import {
  CourseDto,
  CourseSyllabusElement,
  CourseSyllabusLiveEvent,
  Topic,
} from "@masterschool/course-builder-api"
import {
  Box,
  BoxProps,
  Stack,
  SvgIcon,
  SvgIconProps,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import {
  courseSyllabusDuration,
  durationFormatting,
} from "@utils/syllabus+duration"
import appTheme from "../theme/appTheme"
import CourseElementDescriptor from "./courseElementDescriptor"
import { CourseLiveEventDescriptor } from "./courseLiveEventDescriptior"
import {
  CompletionType,
  elementCompletionTypeValue,
} from "./elements/ElementCompletionType"
import { durationToFormattedString } from "@utils/durationToFormattedString"
import { calculateElementsTotalDuration } from "@utils/courseUtils"

function CoursePreview({
  course,
  syllabusTitlePrefix,
  sx,
}: {
  course: CourseDto
  syllabusTitlePrefix?: string
  sx?: BoxProps["sx"]
}) {
  const defaultSx: BoxProps["sx"] = {
    overflow: "auto",
    height: "100%",
    width: "100%",
    padding: "48px 0px",
    gap: "24px",
    display: "flex",
    flexDirection: "column",
  }
  return (
    <Box sx={{ ...defaultSx, ...sx }}>
      <CourseGeneralView
        course={course}
        syllabusTitlePrefix={syllabusTitlePrefix}
      />
      <CourseTopics course={course} />
    </Box>
  )
}

function CourseGeneralView(props: {
  course: CourseDto
  syllabusTitlePrefix?: string
}) {
  const { course, syllabusTitlePrefix } = props
  const title =
    syllabusTitlePrefix !== undefined
      ? `${syllabusTitlePrefix} · ${course.title}`
      : course.title

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography variant="h4">{title}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "8px",
          gap: "8px",
        }}
      >
        <SvgIcon
          component={appIcons.clock}
          sx={{
            stroke: appTheme.palette.icon.black,
            fill: "none",
            width: "16px",
            height: "16px",
          }}
          inheritViewBox
        />
        <Typography variant="body2">
          {durationFormatting(courseSyllabusDuration(course.syllabus))}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{
          marginTop: "8px",
        }}
      >
        {course.description}
      </Typography>
    </Box>
  )
}

function CourseTopics(props: { course: CourseDto }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "40px",
      }}
    >
      {props.course.syllabus.topics.map((topic) => (
        <TopicView topic={topic} key={topic.id} />
      ))}
    </Box>
  )
}

function TopicView(props: { topic: Topic }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="body2_sb" sx={{ pb: "16px" }}>
        {props.topic.title}
      </Typography>

      <ElementsPreview topic={props.topic} />
      <LiveEventsPreview topic={props.topic} />
    </Box>
  )
}

function ElementsPreview(props: { topic: Topic }) {
  const lastElementBorderRadius = {
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
  }

  const shouldRenderLiveEvents = props.topic.liveEvents.length > 0
  const duration = calculateElementsTotalDuration(props.topic.elements)

  const elementExtraText = (completionType: CompletionType) => {
    switch (completionType) {
      case "mandatory":
        return undefined
      case "optional":
        return "Optional"
      case "advancedLearning":
        return "Advanced"
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header title="Self Learn" duration={duration} position="top" />
      {props.topic.elements.map(
        (element: CourseSyllabusElement, index: number) => (
          <CourseElementDescriptor
            key={element.item.id}
            element={element}
            topic={props.topic}
            editMode={false}
            extraText={elementExtraText(elementCompletionTypeValue(element))}
            sx={{
              cursor: "default",
              borderRadius: "0px",
              bgcolor: appTheme.palette.primary.contrast,
              ...(index === props.topic.elements.length - 1 &&
              !shouldRenderLiveEvents
                ? lastElementBorderRadius
                : {}),
              ...(index === props.topic.elements.length - 1
                ? {}
                : { borderBottom: "none" }),
            }}
          />
        ),
      )}
    </Box>
  )
}

function LiveEventsPreview(props: { topic: Topic }) {
  const lastElementBorderRadius = {
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
  }
  const extraIcons: (SvgIconProps & { id: string })[] = [
    {
      id: "hidden",
      component: appIcons.eyeOff,
      sx: { stroke: appTheme.palette.icon.disabled },
    },
  ]

  if (props.topic.liveEvents.length === 0) {
    return null
  }

  const durations = props.topic.liveEvents.map(
    (liveEvent) => liveEvent.estimatedDuration,
  )
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        title="Live Sessions"
        duration={durations.msSum()}
        position="middle"
      />

      {props.topic.liveEvents.map(
        (liveEvent: CourseSyllabusLiveEvent, index: number) => (
          <CourseLiveEventDescriptor
            key={liveEvent.id}
            liveEvent={liveEvent}
            topic={props.topic}
            editMode={false}
            sx={{
              cursor: "default",
              bgcolor: appTheme.palette.primary.contrast,
              borderRadius: "0px",
              ...(index === props.topic.liveEvents.length - 1
                ? lastElementBorderRadius
                : {}),
              ...(index === props.topic.liveEvents.length - 1
                ? {}
                : { borderBottom: "none" }),
            }}
            extraIcons={extraIcons}
          />
        ),
      )}
    </Box>
  )
}

function Header({
  title,
  duration,
  position,
}: {
  title: string
  duration: number
  position: "top" | "middle"
}) {
  const middleBorders = {
    borderLeft: `1px solid ${appTheme.palette.other.outlineBorder}`,
    borderRight: `1px solid ${appTheme.palette.other.outlineBorder}`,
  }
  const topBorders = {
    borderTop: `1px solid ${appTheme.palette.other.outlineBorder}`,
    ...middleBorders,
    borderRadius: "8px 8px 0px 0px",
  }
  const borderStyle = position === "top" ? topBorders : middleBorders

  const formattedDuration = durationToFormattedString(duration)
  return (
    <Stack
      bgcolor="eTypes.sand25"
      direction="row"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        p: "5px 24px",
        ...borderStyle,
      }}
    >
      <Typography variant="body2">{title}</Typography>
      <Typography variant="body2" color={appTheme.palette.text.disabled}>
        {formattedDuration}
      </Typography>
    </Stack>
  )
}

export default CoursePreview
