import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { Divider, Stack } from "@mui/material"
import { useState } from "react"
import { setCourseInstanceMajorVersion } from "@features/courseInstance/courseInstanceSlice"
import { parseStringVersion } from "@utils/versionUtils"
import {
  CourseVersionsMenu,
  SelectedVersionPanel,
} from "./courseVersionHistoryPopup"
import { showSnackbarItem } from "@features/ui/uiSlice"
import { selectCourseMajorRepresentatives } from "@features/coursesMenu/coursesSelectors"

const CourseInstanceSwitchVersionPopup = (props: {
  courseId: string
  courseInstanceId: string
  version: string
  onClose: () => void
}) => {
  const { courseId, courseInstanceId, version, onClose } = props

  const { majorVersions, selectedVersion, setSelectedVersion, switchVersion } =
    useCourseMajorVersionManager(courseId, courseInstanceId, version)

  return (
    <>
      <GenericDialog
        open
        onClose={onClose}
        title="Switch Course Version"
        size="lg"
        fullHeight
        disableContentPadding
        content={
          <Stack overflow="auto" height={1}>
            <Divider />
            <Stack direction="row" flex={1} overflow="hidden">
              <CourseVersionsMenu
                versionsHistory={majorVersions ?? []}
                onVersionSelected={(version) => setSelectedVersion(version)}
                selectedCourseVersion={selectedVersion}
                courseId={courseId}
              />
              <SelectedVersionPanel
                courseId={courseId}
                selectedVersion={selectedVersion}
                showRevertButton={true}
                shouldVerifyBeforeRevert={false}
                onRevertClicked={switchVersion}
              />
            </Stack>
            <Divider />
          </Stack>
        }
      />
    </>
  )
}

const useSwitchMajorVersion = (courseInstanceId: string) => {
  const dispatch = useAppDispatch()

  const switchVersion = async (selectedVersion: string | undefined) => {
    if (!selectedVersion) {
      return
    }
    const { major } = parseStringVersion(selectedVersion)
    return dispatch(
      setCourseInstanceMajorVersion({
        courseInstanceId,
        majorVersion: major,
      }),
    )
  }
  return switchVersion
}

const useCourseMajorVersionManager = (
  courseId: string,
  courseInstanceId: string,
  initialVersion: string,
) => {
  const dispatch = useAppDispatch()
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(
    initialVersion,
  )
  const majorVersions = useAppSelector((state) =>
    selectCourseMajorRepresentatives(state, courseId),
  )

  const switchMajorVersion = useSwitchMajorVersion(courseInstanceId)

  const switchVersion = async () => {
    await switchMajorVersion(selectedVersion)
    dispatch(
      showSnackbarItem({ message: "Course version switched", type: "success" }),
    )
  }

  return {
    majorVersions,
    setSelectedVersion,
    selectedVersion,
    switchVersion,
  }
}

export default CourseInstanceSwitchVersionPopup
