import { RootState } from "@app/store"
import { createSelector } from "@reduxjs/toolkit"

export const selectCourseInstances = createSelector(
  [(state: RootState) => state.courseInstance.instances],
  (instances) => {
    if (instances.status !== "success") {
      return []
    }
    return instances.data
  },
)

export const selectInstancesByCourseId = createSelector(
  [(_, courseId: string | undefined) => courseId, selectCourseInstances],
  (courseId, instances) => {
    return instances.filter((ci) => ci.courseId === courseId)
  },
)

export const selectCourseIdsWithInstances = createSelector(
  [selectCourseInstances],
  (instances) => {
    const courseIdsWithRepetitions = instances.map((ci) => ci.courseId)
    return Array.from(new Set(courseIdsWithRepetitions))
  },
)

export const selectCourseClasses = createSelector(
  [(state: RootState) => state.courseInstance.courseClasses],
  (courseClasses) => {
    if (courseClasses.status !== "success") {
      return []
    }
    return courseClasses.data
  },
)

export const selectCourseInstance = createSelector(
  [
    (_, courseInstanceId: string | undefined) => courseInstanceId,
    selectCourseInstances,
  ],
  (courseInstanceId, instances) => {
    return instances.find((ci) => ci.id === courseInstanceId)
  },
)

export const selectCourseInstanceClasses = createSelector(
  [
    (_, courseInstanceId: string | undefined) => courseInstanceId,
    selectCourseClasses,
  ],
  (courseInstanceId, courseClasses) => {
    return courseClasses.filter(
      (cc) => cc.courseInstanceId === courseInstanceId,
    )
  },
)

export const selectCourseInstancesClasses = createSelector(
  [(_, courseInstanceIds: string[]) => courseInstanceIds, selectCourseClasses],
  (courseInstanceIds, courseClasses) => {
    return courseClasses.filter((cc) =>
      courseInstanceIds.includes(cc.courseInstanceId),
    )
  },
)

export const selectCourseInstanceByIdentifiers = createSelector(
  [
    (_, courseId: string | undefined, _1, _2) => courseId,
    (_, _1, termId: string | undefined, _2) => termId,
    (_, _1, _2, majorVersion: number | undefined) => majorVersion,
    selectCourseInstances,
  ],
  (courseId, termId, majorVersion, instances) => {
    const instancesWithDifferentMajors = instances.filter(
      (ci) => ci.courseId === courseId && ci.termId === termId,
    )
    if (majorVersion) {
      return instancesWithDifferentMajors.find(
        (ci) => ci.majorVersion === majorVersion,
      )
    }
    if (instancesWithDifferentMajors.length === 0) {
      return undefined
    }

    const maxMajorInstance = instancesWithDifferentMajors.reduce((prev, curr) =>
      (prev.majorVersion || 0) > (curr.majorVersion || 0) ? prev : curr,
    )
    return maxMajorInstance
  },
)

export const selectCourseInstanceIdsByIdentifiers = createSelector(
  [
    (
      _,
      identifiers: {
        courseId: string
        termId: string
        majorVersion?: number
      }[],
    ) => identifiers,
    selectCourseInstances,
  ],
  (identifiers, courseInstances) => {
    return identifiers.msCompactMap((cii) => {
      const { courseId, termId, majorVersion } = cii
      const instancesForTerm = courseInstances.filter(
        (ci) => ci.courseId === courseId && ci.termId === termId,
      )
      const instanceForMajorVersion = instancesForTerm.find((ci) =>
        majorVersion ? ci.majorVersion === majorVersion : true,
      )
      return instanceForMajorVersion ?? instancesForTerm[0]
    })
  },
)

export const selectClassesByCourseInstances = createSelector(
  [(_, courseInstanceIds: string[]) => courseInstanceIds, selectCourseClasses],
  (courseInstanceIds, courseClasses) => {
    return courseClasses.filter((cc) =>
      courseInstanceIds.includes(cc.courseInstanceId),
    )
  },
)
