import { GridColDef } from "@mui/x-data-grid-pro"
import { StudentAcademicStatusDTO } from "../../studentAcademicStatus.dto"
import { ColumnKeys } from "./column.keys"

export const CONTACT_DETAILS_COLUMNS: GridColDef<StudentAcademicStatusDTO>[] = [
  {
    field: ColumnKeys.NAME,
    headerName: "Name",
    width: 200,
    valueGetter: (value, row) => row.contactDetails.name,
  },
  {
    field: ColumnKeys.EMAIL,
    headerName: "Email",
    width: 200,
    valueGetter: (value, row) => row.contactDetails.email,
  },
  {
    field: ColumnKeys.HUBSPOT_ID,
    headerName: "Hubspot ID",
    width: 200,
    type: "string",
    valueGetter: (value, row) => row.contactDetails.hubspotContactId,
  },
]
