import { DialogProps } from "@mui/material"
import React from "react"
import { PopupState, PopupType } from "@features/ui/uiSlice"
import ArchiveCourseConfirmationPopup from "../popups/archiveCourseConfirmationPopup"
import CampusPreviewPopup from "../popups/campusCoursePreviewPopup"
import CoursePreviewPopup from "../popups/coursePreviewPopup"
import SyllabusDependenciesPopup from "../popups/syllabusDependenciesPopup"
import CourseVersionHistoryPopup from "../popups/courseVersionHistoryPopup"
import SyllabusDeleteConfirmationPopup from "../popups/syllabusDeleteConfirmationPopup"
import { CampusSyllabusJSONPreview } from "../popups/campusSyllabusJSONPreview"
import ArchiveSyllabusConfirmationPopup from "../popups/archiveSyllabusConfirmationPopup"
import { SyllabusVersionHistoryPopup } from "../popups/syllabusVersionHistoryPopup"
import { EditCourseDetailsPopup } from "../../main/popups/editCourseDetailsPopup"
import { EditTrackDetailsPopup } from "../../main/popups/editTrackDetailsPopup"
import CourseInstanceSwitchVersionPopup from "../popups/courseInstanceSwitchVersionPopup"

function popupFactory(
  popup: PopupState,
  onClose: () => void,
): React.ReactElement<DialogProps> | null {
  switch (popup.type) {
    case PopupType.Preview:
      return <CoursePreviewPopup course={popup.course} />
    case PopupType.CampusCoursePreview:
      return (
        <CampusPreviewPopup
          name={popup.course.name}
          id={popup.course.id}
          idType="course"
          version={popup.course.version}
          onClose={onClose}
        />
      )
    case PopupType.CourseVersionsHistory:
      return (
        <CourseVersionHistoryPopup
          courseId={popup.courseId}
          majorVersion={popup.majorVersion}
          onClose={onClose}
          context={popup.context}
        />
      )
    case PopupType.DependentSyllabuses:
      return (
        <SyllabusDependenciesPopup
          syllabusIDs={popup.syllabusDependencies}
          onClose={onClose}
        />
      )
    case PopupType.ArchiveCourseConfirmation:
      return (
        <ArchiveCourseConfirmationPopup
          courseId={popup.courseId}
          version={popup.version}
          onClose={onClose}
        />
      )
    case PopupType.EditCourseDetails:
      return <EditCourseDetailsPopup course={popup.course} onClose={onClose} />
    case PopupType.EditTrackDetails:
      return <EditTrackDetailsPopup track={popup.track} onClose={onClose} />
    case PopupType.CampusSyllabusPreview:
      return (
        <CampusPreviewPopup
          name={popup.syllabus.name ?? ""}
          id={popup.syllabus.id ?? ""}
          idType="syllabus"
          version={popup.syllabus.version}
          programId={popup.programId}
          onClose={onClose}
        />
      )

    case PopupType.ArchiveSyllabusConfirmation:
      return (
        <ArchiveSyllabusConfirmationPopup
          syllabusId={popup.syllabusId}
          onClose={onClose}
        />
      )

    case PopupType.SyllabusDeleteConfirmation:
      return <SyllabusDeleteConfirmationPopup syllabusId={popup.syllabusId} />
    case PopupType.CampusSyllabusJSONPreview:
      return (
        <CampusSyllabusJSONPreview
          syllabusId={popup.syllabusId}
          version={popup.version}
        />
      )
    case PopupType.SyllabusVersionsHistory:
      return (
        <SyllabusVersionHistoryPopup
          syllabusId={popup.syllabusId}
          onClose={onClose}
        />
      )
    case PopupType.CourseInstanceVersionSwitch:
      return (
        <CourseInstanceSwitchVersionPopup
          courseId={popup.courseId}
          courseInstanceId={popup.courseInstanceId}
          version={popup.version}
          onClose={onClose}
        />
      )
  }
}

export default popupFactory
