import { store } from "@app/store"
import { selectAccessToken } from "@features/login/loginSelectors"
import {
  Configuration,
  TrackApi,
  TrackDto,
  TrackMetadataDto,
} from "@masterschool/course-builder-api"

export class TrackClient {
  private static trackClient: TrackApi

  private static getTrackClient() {
    if (!this.trackClient) {
      this.trackClient = new TrackApi(
        new Configuration({
          basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
        }),
      )
    }
    return this.trackClient
  }

  private static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static getTrack(id: string, version?: number) {
    return TrackClient.getTrackClient()
      .trackControllerV1Get(id, version, TrackClient.makeHeaders())
      .then((response) => response.data)
  }

  static list() {
    return TrackClient.getTrackClient()
      .trackControllerV1List(TrackClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static save(trackId: string, track: TrackDto) {
    return TrackClient.getTrackClient()
      .trackControllerV1Update(track, trackId, TrackClient.makeHeaders())
      .then((response) => response.data)
  }

  static async updateSyllabusMetadata(
    request: TrackMetadataDto,
    id: string,
  ): Promise<TrackMetadataDto> {
    return await TrackClient.trackClient
      .trackControllerV1UpdateTrackMetadata(
        request,
        id,
        TrackClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static publish(trackId: string) {
    return TrackClient.getTrackClient()
      .trackControllerV1Publish(trackId, TrackClient.makeHeaders())
      .then((response) => response.data)
  }

  static listVersions(id: string) {
    return TrackClient.getTrackClient()
      .trackControllerV1ListTrackVersions(id, TrackClient.makeHeaders())
      .then((response) => response.data)
  }

  static createNewDraft(id: string) {
    return TrackClient.getTrackClient().trackControllerV1CreateNewDraftVersion(
      id,
      TrackClient.makeHeaders(),
    )
  }
}
