import { useAppSelector } from "@app/hooks"
import {
  CalendarHubClient,
  CourseClassCalendarDto,
  MsOfferingCalendarDto,
  MsPrivateCalendarDto,
} from "@clients/calendarHubClient"
import { UsersClient } from "@clients/usersClient"
import {
  selectCourseInstanceIdsByIdentifiers,
  selectCourseInstancesClasses,
} from "@features/courseInstance/courseInstanceSelectors"
import { selectLatestPublishedCoursesByIds } from "@features/coursesMenu/coursesSelectors"
import { Role } from "@features/login/loginSlice"
import {
  selectClassesForProgram,
  selectProgram,
  selectProgramCourseInstanceIdentifiers,
} from "@features/program/programSliceSelectors"
import { selectTermsByFilter } from "@features/term/termSelector"
import { useEffect, useMemo, useState } from "react"

export const useCourseInstances = (programId: string) => {
  const courseInstanceIdentifiers = useAppSelector((state) =>
    selectProgramCourseInstanceIdentifiers(state, programId),
  )

  const courseInstances = useAppSelector((state) =>
    selectCourseInstanceIdsByIdentifiers(state, courseInstanceIdentifiers),
  )

  return { courseInstances, courseInstanceIdentifiers }
}

export const useProgramTerms = (programId: string, numberOfTerms: number) => {
  const program = useAppSelector((state) => selectProgram(state, programId))

  const terms = useAppSelector((state) =>
    selectTermsByFilter(state, program?.startDate, numberOfTerms),
  )

  return terms
}

const useCalendars = (courseClassIds: string[], schoolIds: string[]) => {
  const [courseClassCalendars, setCourseClassCalendars] = useState<
    CourseClassCalendarDto[]
  >([])
  const [programSchoolCalendars, setProgramSchoolCalendars] = useState<
    MsOfferingCalendarDto[]
  >([])

  useEffect(() => {
    CalendarHubClient.getCourseClassesCalendar(courseClassIds).then(
      (calendars) => setCourseClassCalendars(calendars),
    )
  }, [courseClassIds])

  useEffect(() => {
    CalendarHubClient.getSchoolCalendars(schoolIds).then((calendars) =>
      setProgramSchoolCalendars(calendars),
    )
  }, [schoolIds])

  return { courseClassCalendars, programSchoolCalendars }
}

const useGetStaffCalendars = () => {
  const [staffCalendars, setStaffCalendars] = useState<MsPrivateCalendarDto[]>(
    [],
  )

  useEffect(() => {
    UsersClient.getUsersWithRoles([
      Role.Mentor,
      Role.Instructor,
      Role.ProgramManager,
      Role.CourseManager,
    ])
      .then((users) =>
        CalendarHubClient.getCalendarsByUserClientId(users.map((u) => u.id)),
      )
      .then((calendars) => {
        setStaffCalendars(calendars)
      })
  }, [])

  return staffCalendars
}

export const useGetCourseClassCalendars = (programId: string) => {
  const { courseInstances, courseInstanceIdentifiers } =
    useCourseInstances(programId)

  const numberOfTerms = useMemo(
    () => courseInstanceIdentifiers.map((ids) => ids.termId).msUnique().length,
    [courseInstanceIdentifiers],
  )
  const terms = useProgramTerms(programId, numberOfTerms)

  const courseIds = useMemo(
    () => courseInstances.map((ci) => ci.courseId),
    [courseInstances],
  )

  const courseDescriptors = useAppSelector((state) =>
    selectLatestPublishedCoursesByIds(state, courseIds),
  )

  const courseInstanceIds = useMemo(
    () => courseInstances.map((ci) => ci.id),
    [courseInstances],
  )

  const courseClasses = useAppSelector((state) =>
    selectCourseInstancesClasses(state, courseInstanceIds),
  )

  const courseClassIds = useMemo(
    () => courseClasses.map((cc) => cc.id),
    [courseClasses],
  )

  const schools = useAppSelector(selectClassesForProgram(programId))
  const schoolIds = useMemo(() => schools.map((s) => s.id), [schools])

  const { courseClassCalendars, programSchoolCalendars } = useCalendars(
    courseClassIds,
    schoolIds,
  )

  const staffCalendars = useGetStaffCalendars()

  return {
    courseClassCalendars,
    programSchoolCalendars,
    terms,
    courseInstanceIdentifiers,
    courseDescriptors,
    staffCalendars,
  }
}
