import { Box } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { coursesColumns } from "./courseMajorsColumns"
import appTheme from "../../../theme/appTheme"
import {
  CourseDescriptorDto,
  CourseStatus,
} from "@masterschool/course-builder-api"
import { CourseMajorDescriptorDto, createUniqueIdForCourse } from "../utils"
import { useNavigate } from "react-router-dom"
import { parseStringVersion } from "@utils/versionUtils"

export function CourseMajorsTable({
  courseMajors,
}: {
  courseMajors: CourseDescriptorDto[]
}) {
  const coursesWithUniqueIds = courseMajors.map(createUniqueIdForCourse)
  const navigate = useNavigate()

  const onOpenClicked = (course: CourseMajorDescriptorDto) => {
    const { courseId, status, version } = course
    const { major } = parseStringVersion(version)
    if (status === CourseStatus.Draft) {
      navigate(`/course-edit/${courseId}?majorVersion=${major}`)
    } else {
      navigate(`/course/${courseId}?version=${version}`)
    }
  }
  return (
    <Box
      sx={{
        maxWidth: "1240px",
        maxHeight: "100%",
        bgcolor: "eTypes.sand15",
      }}
    >
      <DataGridPro
        rows={coursesWithUniqueIds}
        columns={coursesColumns}
        rowHeight={78}
        disableColumnMenu
        disableRowSelectionOnClick
        disableColumnReorder
        onRowClick={(params) => onOpenClicked(params.row)}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAtTimestamp", sort: "desc" }], // Sort by date descending (newest first)
          },
        }}
        sx={{
          border: "none",
          "& .MuiDataGrid-row": {
            backgroundColor: "white",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: appTheme.palette.action.hover,
            cursor: "pointer",
          },
          "& .MuiDataGrid-cell": {
            p: "0px 0px 0px 16px !important",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader": {
            p: "0px 0px 0px 16px !important",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-footerContainer": {
            display: "none",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  )
}
