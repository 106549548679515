import { CourseStatus } from "@masterschool/course-builder-api"
import { Badge, Chip, Stack, Typography } from "@mui/material"
import { minimalFormatTimestamp } from "@utils/formatTimestamp"
import AppIcon from "@utils/appIcon"
import appIcons from "@utils/appIcons"
import CustomTooltip from "@cmp/customTooltip"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import useCourseMajorsMenuActions from "./useCourseMajrosMenu"
import { IconTextMenuItem } from "../course-table/coursesCells"
import { CourseMajorDescriptorDto, uuidToShortId } from "../utils"
import { useAppSelector } from "@app/hooks"
import { durationToFormattedString } from "@utils/durationToFormattedString"
import {
  selectMinorChanges,
  selectMinorVersionCourse,
} from "@features/coursesMenu/coursesSelectors"
import { parseStringVersion } from "@utils/versionUtils"

export function CourseDateCell({
  courseId,
  version,
}: {
  courseId: string
  version: string
}) {
  const minMinorCourse = useAppSelector((state) =>
    selectMinorVersionCourse(state, { courseId, version, position: "First" }),
  )
  const date = minimalFormatTimestamp(minMinorCourse?.createdAtTimestamp || 0)
  return (
    <Stack sx={{ height: "100%", justifyContent: "center" }}>
      <Typography variant="body2">{date}</Typography>
    </Stack>
  )
}

export function CourseVersionCell({
  displayId,
  courseId,
  version,
}: {
  displayId: string | undefined
  courseId: string
  version: string
}) {
  const defaultDisplayId = uuidToShortId(courseId)
  return (
    <Stack sx={{ height: "100%", justifyContent: "center" }}>
      <Typography variant="body2">{`${
        displayId || defaultDisplayId
      }.${version}`}</Typography>
    </Stack>
  )
}

export function CourseSizeCell({
  ItemsDuration,
  liveEventsDuration,
}: {
  ItemsDuration: number
  liveEventsDuration: number
}) {
  const ItemDurationForDisplay = durationToFormattedString(ItemsDuration)
  const LiveEventDurationForDisplay =
    durationToFormattedString(liveEventsDuration)

  return (
    <Stack
      direction="row"
      sx={{
        height: "100%",
        gap: "4px",
        alignItems: "center",
      }}
    >
      <Chip
        label={ItemDurationForDisplay}
        size="small"
        variant="pastel"
        color={"default"}
        icon={<AppIcon icon={appIcons.bookOpen01} />}
      />
      <Chip
        label={LiveEventDurationForDisplay}
        size="small"
        variant="pastel"
        color={"default"}
        icon={<AppIcon icon={appIcons.playCircle} />}
      />
    </Stack>
  )
}

export function CourseStatusCell({
  course,
}: {
  course: CourseMajorDescriptorDto
}) {
  const { major } = parseStringVersion(course.version)
  const hasChanges = useAppSelector(selectMinorChanges(course.courseId, major))

  const chip = (
    <Chip
      label={course.status}
      size="small"
      variant="pastel"
      color={statusToColor(course.status)}
    />
  )
  return hasChanges ? (
    <Badge
      variant="dot"
      color="info"
      sx={{
        "& .MuiBadge-badge": {
          right: 3,
          top: 3,
          border: "1px solid white",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        },
      }}
    >
      {chip}
    </Badge>
  ) : (
    chip
  )
}

export function CourseNotesCell({ notes }: { notes: string | undefined }) {
  if (!notes) {
    return null
  }
  return (
    <Stack sx={{ height: "100%", justifyContent: "center" }}>
      <CustomTooltip title={notes} leaveDelay={0}>
        <Stack width="fit-content">
          <AppIcon icon={appIcons.file02} />
        </Stack>
      </CustomTooltip>
    </Stack>
  )
}

export function ActionsCell({ course }: { course: CourseMajorDescriptorDto }) {
  const {
    onOpenClicked,
    onEditClicked,
    onPreviewInCampusClicked,
    onVersionHistoryClicked,
    onDiscardDraftVersionClicked,
  } = useCourseMajorsMenuActions(course)

  const { major, minor } = parseStringVersion(course.version)

  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          sx: {
            marginLeft: "auto",
          },
          size: "small",
          children: <AppIcon icon={appIcons.dotsPretty} />,
        },
      }}
      items={[
        {
          type: "menu-item",
          props: {
            key: "open",
            children: <IconTextMenuItem text="Open" icon={appIcons.logIn02} />,
            onClick: onOpenClicked,
          },
        },
        {
          type: "menu-item",
          props: {
            key: "edit-course",
            children: (
              <IconTextMenuItem text="Edit" icon={appIcons.pencilLine} />
            ),
            onClick: () => onEditClicked(course.courseId, major, course.status),
            disabled: course.status === CourseStatus.Archived,
          },
        },
        {
          type: "menu-item",
          props: {
            key: "preview-course-in-campus",
            children: (
              <IconTextMenuItem text="Preview in campus" icon={appIcons.eye} />
            ),
            onClick: onPreviewInCampusClicked,
          },
        },
        {
          type: "menu-item",
          props: {
            key: "course-history",
            children: (
              <IconTextMenuItem
                text="History"
                icon={appIcons.calendarMinusTime}
              />
            ),
            onClick: onVersionHistoryClicked,
          },
        },
        {
          type: "menu-item",
          props: {
            key: "discard-course-draft-version",
            children: (
              <IconTextMenuItem text="Discard" icon={appIcons.trash03} />
            ),
            onClick: onDiscardDraftVersionClicked,
            disabled: course.status !== CourseStatus.Draft || minor === 1,
          },
        },
      ]}
    />
  )
}

function statusToColor(status: CourseStatus) {
  switch (status) {
    case CourseStatus.Archived:
      return "error"
    case CourseStatus.Draft:
      return "default"
    case CourseStatus.Published:
      return "success"
  }
}
