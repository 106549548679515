import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import { useEditMasterschoolProject } from "../custom-hooks/useEditElementItem"
import { useSelectedMasterschoolProject } from "../custom-hooks/useSelectedElementItem"
import {
  ProjectSubmissionMethod,
  Topic,
} from "@masterschool/course-builder-api"
import EditProjectType from "./editProjectType"
import { useAppSelector } from "@app/hooks"
import { MultiLanguageTitleTextField } from "../../components/multiLanguageFields/multiLanguageTitleTextField"
import MultiLanguageUrlTextField from "../../components/multiLanguageFields/multipleLanguageUrlTextField"

function MasterschoolProjectEditor(props: { topic: Topic }) {
  const { topic } = props
  const masterschoolProject = useSelectedMasterschoolProject()
  const editMasterschoolProject = useEditMasterschoolProject()
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )
  if (!masterschoolProject) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "16px",
          }}
        >
          <MultiLanguageTitleTextField
            elementId={masterschoolProject.id}
            defaultLanguageValue={masterschoolProject.title}
            extraLanguageValueRecord={
              masterschoolProject.extraLanguageTitleRecord
            }
            language={editingLanguage}
            debounce
            onChangeDefaultLanguage={(newValue) => {
              editMasterschoolProject(
                masterschoolProject,
                "title",
                newValue,
                topic,
              )
            }}
            onChangeExtraLanguage={(newExtraLanguageValueRecord) => {
              editMasterschoolProject(
                masterschoolProject,
                "extraLanguageTitleRecord",
                newExtraLanguageValueRecord,
                topic,
              )
            }}
          />
          <EditProjectType topic={topic} />
        </Box>
        <MultiLanguageUrlTextField
          elementId={masterschoolProject.id}
          label="Project URL"
          defaultLanguageValue={masterschoolProject.url}
          extraLanguageValueRecord={masterschoolProject.extraLanguageUrlRecord}
          language={editingLanguage}
          debounce
          onChangeDefaultLanguage={(newValue) => {
            editMasterschoolProject(masterschoolProject, "url", newValue, topic)
          }}
          onChangeExtraLanguage={(newExtraLanguageValueRecord) => {
            editMasterschoolProject(
              masterschoolProject,
              "extraLanguageUrlRecord",
              newExtraLanguageValueRecord,
              topic,
            )
          }}
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        gap={3}
      >
        <SelectProjectSubmissionType
          value={masterschoolProject.submissionMethod}
          onChange={(value: ProjectSubmissionMethod) => {
            editMasterschoolProject(
              masterschoolProject,
              "submissionMethod",
              value,
              topic,
            )
          }}
        />
        <TimeEstimationTextField
          elementId={masterschoolProject.id}
          value={masterschoolProject.estimatedDuration}
          debounce
          onChange={(e) => {
            editMasterschoolProject(
              masterschoolProject,
              "estimatedDuration",
              parseInt(e.target.value),
              topic,
            )
          }}
        />
      </Stack>
    </Box>
  )
}

const SelectProjectSubmissionType = ({
  value,
  onChange,
}: {
  value: ProjectSubmissionMethod
  onChange: (value: ProjectSubmissionMethod) => void
}) => {
  return (
    <FormControl sx={{ minWidth: "180px", width: "100%" }} size="small">
      <InputLabel>Submission method</InputLabel>
      <Select
        label="Submission method"
        value={value}
        onChange={(e) => onChange(e.target.value as ProjectSubmissionMethod)}
      >
        <MenuItem value={ProjectSubmissionMethod.FileUpload} key={"fileUpload"}>
          Link & file upload
        </MenuItem>
        <MenuItem
          value={ProjectSubmissionMethod.TextSubmission}
          key={"textSubmission"}
        >
          Text submission
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default MasterschoolProjectEditor
