import { useAppDispatch } from "@app/hooks"
import {
  CourseClassRegistrationClient,
  CourseRegistrationEndStatus,
} from "@clients/courseClassRegistrationClient"
import { ProgramManualUnlockClient } from "@clients/ProramManualUnlockClient"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import { fetchCourseClasses } from "@features/courseInstance/courseInstanceSlice"
import { showSnackbarItem, SnackbarDuration } from "@features/ui/uiSlice"
import { Stack, SvgIcon, Typography } from "@mui/material"
import AppIcon from "@utils/appIcon"
import appIcons from "@utils/appIcons"

export function CourseInstanceStudentsActionMenu({
  courseInstanceId,
  courseClassId,
  studentId,
  operatingUserClientId,
}: {
  courseInstanceId: string
  courseClassId: string
  studentId: string
  operatingUserClientId: string
}) {
  const dispatch = useAppDispatch()
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        height: "100%",
        mr: "22px",
      }}
    >
      <OptionsButton2
        buttonModel={{
          type: "icon",
          props: {
            sx: {
              marginLeft: "auto",
              display: "flex",
            },
            size: "small",
            children: (
              <SvgIcon
                component={appIcons.dotsPretty}
                inheritViewBox
                sx={{ color: "primary.main" }}
              />
            ),
          },
        }}
        items={[
          {
            type: "menu-item",
            props: {
              key: "remove-student",
              children: (
                <Stack direction="row" gap="8px" alignItems="center">
                  <AppIcon icon={appIcons.xCircle} />
                  <Typography variant="body2">Remove Student</Typography>
                </Stack>
              ),
              onClick: () => {
                CourseClassRegistrationClient.unregisterStudentFromClass(
                  courseClassId,
                  studentId,
                  new Date(),
                  {
                    status: CourseRegistrationEndStatus.Removed,
                    removedBy: operatingUserClientId,
                  },
                ).then(() => dispatch(fetchCourseClasses()))
              },
            },
          },
          {
            type: "menu-item",
            props: {
              key: "unlock next week",
              children: (
                <Stack direction="row" gap="8px" alignItems="center">
                  <AppIcon icon={appIcons.lockUnlocked01} />
                  <Typography variant="body2">Unlock Next Week</Typography>
                </Stack>
              ),
              onClick: () =>
                ProgramManualUnlockClient.unlockCourseNextWeek(
                  studentId,
                  courseInstanceId,
                )
                  .then(() =>
                    dispatch(
                      showSnackbarItem({
                        message: "Unlocked next week",
                        type: "success",
                        duration: SnackbarDuration.short,
                      }),
                    ),
                  )
                  .catch(() =>
                    dispatch(
                      showSnackbarItem({
                        message: "Failed to unlock next week",
                        type: "error",
                        duration: SnackbarDuration.short,
                      }),
                    ),
                  ),
            },
          },
        ]}
      />
    </Stack>
  )
}
