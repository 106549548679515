import { useEffect, useMemo, useState } from "react"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import {
  ConsultantToShifts,
  fetchSalesTeams,
  fetchShifts,
} from "@features/sales-management/salesManagement.slice"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { selectSalesTeams } from "@features/sales-management/salesManagement.selectors"
import {
  ConsultantDto,
  TeamLeadConsultants,
} from "@masterschool/course-builder-api"
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"

type RowData = {
  [day in DayOfWeek]: ConsultantDto[]
} & {
  id: string
  timeSlot: string
}

type DayOfWeek =
  | "Sunday"
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"

const ShiftSchedulePage = () => {
  const consultantToShifts = useAppSelector(
    (state) => state.salesManagement.shifts,
  )

  const consultantsByTeams = useAppSelector(selectSalesTeams)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchSalesTeams())
  }, [dispatch])

  const salesTeams = useAppSelector(selectSalesTeams)
  useEffect(() => {
    dispatch(fetchShifts())
  }, [salesTeams, dispatch])

  if (
    consultantToShifts.status !== "success" ||
    consultantsByTeams === "pending"
  ) {
    return <LoadingComponent loadedComponents="shifts" />
  }

  return (
    <ShiftScheduleGrid
      consultantToShifts={consultantToShifts.data}
      consultantsByTeams={consultantsByTeams}
    />
  )
}

const ShiftScheduleGrid = (props: {
  consultantToShifts: ConsultantToShifts
  consultantsByTeams: TeamLeadConsultants[]
}) => {
  const { consultantToShifts, consultantsByTeams: teamLeads } = props
  const [acceptedQtfs, setAcceptedQtfs] = useState<number[]>([1, 2, 3, 4, 5])
  const [acceptedLanguages, setAcceptedLanguages] = useState<string[]>([
    "English",
    "German",
  ])
  const [showPlacementExperts, setShowPlacementExperts] = useState(false)
  const [selectedTeamLeads, setSelectedTeamLeads] =
    useState<TeamLeadConsultants[]>(teamLeads)
  const consultantsInSelectedTeams = useMemo(() => {
    return selectedTeamLeads
      .flatMap((teamLead) => teamLead.consultants)
      .reduce((acc, consultant) => {
        acc[consultant.id] = consultant
        return acc
      }, {} as Record<string, ConsultantDto>)
  }, [selectedTeamLeads])

  const timeSlots = useMemo(() => {
    const slots = []
    for (let hour = 8; hour < 24; hour++) {
      for (let minute of [0, 30]) {
        slots.push(
          `${hour.toString().padStart(2, "0")}:${minute
            .toString()
            .padStart(2, "0")}`,
        )
      }
    }
    return slots
  }, [])

  const daysOfWeek: DayOfWeek[] = useMemo(
    () => [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    [],
  )

  // Process shifts data to determine which agents are working at each time slot
  const rows = useMemo(() => {
    return timeSlots.map((timeSlot) => {
      const [hour, minute] = timeSlot.split(":").map(Number)
      const currentTime = hour * 60 + minute

      const rowData: RowData = {
        id: timeSlot,
        timeSlot,
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
      }

      // For each day, check which agents are working
      daysOfWeek.forEach((day, dayIndex) => {
        const consultantsInSlot: ConsultantDto[] = []

        Object.entries(consultantToShifts).forEach(([ccId, shifts]) => {
          const consultant = consultantsInSelectedTeams[ccId]
          const isInQTFs = consultant?.acceptedQtfs.msIncludesAny(acceptedQtfs)
          const isInFilteredLanguages =
            consultant?.languages.msIncludesAny(acceptedLanguages)
          const isPlacementExpert = consultant?.isPlacementExpert === true

          if (
            !consultant ||
            (!isInQTFs && !isPlacementExpert) ||
            !isInFilteredLanguages ||
            (isPlacementExpert && !showPlacementExperts)
          ) {
            return
          }
          shifts.forEach((shift) => {
            if (shift.days.includes(dayIndex)) {
              const [startHour, startMinute] = shift.startTime
                .split(":")
                .map(Number)
              const [endHour, endMinute] = shift.endTime.split(":").map(Number)
              const shiftStart = startHour * 60 + startMinute
              const shiftEnd = endHour * 60 + endMinute

              if (currentTime >= shiftStart && currentTime < shiftEnd) {
                consultantsInSlot.push(consultant)
              }
            }
          })
        })

        rowData[day] = consultantsInSlot
      })

      return rowData
    })
  }, [
    consultantToShifts,
    timeSlots,
    consultantsInSelectedTeams,
    acceptedQtfs,
    acceptedLanguages,
    showPlacementExperts,
    daysOfWeek,
  ])

  // Define columns
  const columns: GridColDef<RowData>[] = useMemo(() => {
    return [
      {
        field: "timeSlot",
        headerName: "Time",
        sortable: false,
        align: "center",
      },
      ...daysOfWeek.map(
        (day) =>
          ({
            field: day,
            headerName: day,
            height: 60,
            sortable: false,
            renderCell: (params) => {
              const consultants = params.row[day] || []
              return <SlotCell consultants={consultants} />
            },
          } as GridColDef<RowData>),
      ),
    ]
  }, [daysOfWeek])

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (acceptedQtfs.includes(value)) {
      setAcceptedQtfs(acceptedQtfs.filter((qtf) => qtf !== value))
    } else {
      setAcceptedQtfs([...acceptedQtfs, value])
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      padding="30px"
      overflow={"auto"}
      width={"100%"}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Consultants Schedule</Typography>
        <Box display="flex">
          <Stack>
            <FormGroup row>
              <FormControlLabel
                key="English"
                control={
                  <Checkbox
                    checked={acceptedLanguages.includes("English")}
                    onChange={() =>
                      setAcceptedLanguages((prev) =>
                        prev.includes("English")
                          ? prev.filter((lang) => lang !== "English")
                          : [...prev, "English"],
                      )
                    }
                  />
                }
                label="English"
              />
              <FormControlLabel
                key="German"
                control={
                  <Checkbox
                    checked={acceptedLanguages.includes("German")}
                    onChange={() =>
                      setAcceptedLanguages((prev) =>
                        prev.includes("German")
                          ? prev.filter((lang) => lang !== "German")
                          : [...prev, "German"],
                      )
                    }
                  />
                }
                label="German"
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                key="experts"
                control={
                  <Checkbox
                    checked={showPlacementExperts}
                    onChange={(e) => setShowPlacementExperts((prev) => !prev)}
                  />
                }
                label="Include Golden Leads"
              />
            </FormGroup>
          </Stack>
          <Stack>
            <FormGroup row>
              {teamLeads.map((teamLead) => (
                <FormControlLabel
                  key={teamLead.teamLead.salesTeamLeadUserId}
                  control={
                    <Checkbox
                      value={teamLead.teamLead.salesTeamLeadUserId}
                      checked={selectedTeamLeads.includes(teamLead)}
                      onChange={(e) => {
                        if (selectedTeamLeads.includes(teamLead)) {
                          setSelectedTeamLeads(
                            selectedTeamLeads.filter((tl) => tl !== teamLead),
                          )
                        } else {
                          setSelectedTeamLeads([...selectedTeamLeads, teamLead])
                        }
                      }}
                    />
                  }
                  label={teamLead.teamLead.name.split(" ")[0] + "'s Team"}
                />
              ))}
            </FormGroup>
            <FormGroup row>
              <FormLabel sx={{ alignSelf: "center", pr: "8px" }}>
                QTFs:{" "}
              </FormLabel>
              {[1, 2, 3, 4, 5].map((qtf) => (
                <FormControlLabel
                  key={qtf}
                  control={
                    <Checkbox
                      value={qtf}
                      checked={acceptedQtfs.includes(qtf)}
                      onChange={(e) => onCheckboxChange(e)}
                    />
                  }
                  label={qtf}
                />
              ))}
            </FormGroup>
          </Stack>
        </Box>
      </Box>
      <DataGridPro
        rows={rows}
        columns={columns}
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooter
        density="compact"
        sx={{
          "& .MuiDataGrid-cell": {
            padding: 0,
          },
        }}
        className="border-none"
      />
    </Box>
  )
}

const SlotCell = (props: { consultants: ConsultantDto[] }) => {
  const availableConsultants = props.consultants.filter((c) => c.isAvailable)

  const intensity =
    availableConsultants.length > 0
      ? Math.min((availableConsultants.length / 30) * 0.8 + 0.2, 1)
      : 0

  let tooltipTitle = availableConsultants
    .map(
      (c) =>
        `${c.name}: ${
          c.isPlacementExpert ? "Golden Leads" : c.acceptedQtfs.join(",")
        }`,
    )
    .join(", ")

  return (
    <Box
      sx={{
        backgroundColor: `rgba(0, 210, 0, ${intensity})`,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {availableConsultants.length > 0 && (
        <Tooltip title={tooltipTitle} arrow>
          <Typography variant="body2">{availableConsultants.length}</Typography>
        </Tooltip>
      )}
    </Box>
  )
}

export default ShiftSchedulePage
