import { useAppDispatch, useAppSelector } from "@app/hooks"
import { CourseClassRegistrationClient } from "@clients/courseClassRegistrationClient"
import GenericDialog from "@cmp/genericDialog"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { registerStudentToCourseUpdated } from "@features/program/programSlice"
import { selectLatestPublishedCoursesByIds } from "@features/coursesMenu/coursesSelectors"
import { Autocomplete, TextField, Stack, Typography } from "@mui/material"
import { formatDateAsYearAndLongMonth } from "@utils/date"
import { useState } from "react"
import { selectTermsByFilter } from "@features/term/termSelector"
import {
  selectCourseInstanceByIdentifiers,
  selectCourseIdsWithInstances,
  selectCourseInstanceClasses,
} from "@features/courseInstance/courseInstanceSelectors"
import { uuidToShortId } from "../../../main/courses-table/utils"

function RegisterStudentToCourseClassPopup() {
  const model = useAppSelector(
    (state) => state.program.studentsPage.registerStudentToCourseClassPopup,
  )
  const dispatch = useAppDispatch()
  const [selectedCourse, setSelectedCourse] = useState<any | null>(null)
  const [selectedTerm, setSelectedTerm] = useState<any | null>(null)
  const [selectedClass, setSelectedClass] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const user = useAppSelector(selectLoggedInUser)
  const justBeforeWeStartUsingTerms = new Date("2024-11-01")
  const terms = useAppSelector((state) =>
    selectTermsByFilter(state, justBeforeWeStartUsingTerms.toISOString(), 10),
  )
  const courseIds = useAppSelector(selectCourseIdsWithInstances)
  const courses = useAppSelector((state) =>
    selectLatestPublishedCoursesByIds(state, courseIds),
  )
  const courseInstance = useAppSelector((state) =>
    selectCourseInstanceByIdentifiers(
      state,
      selectedCourse?.id || "",
      selectedTerm?.id || "",
      undefined,
    ),
  )
  const classes = useAppSelector((state) =>
    selectCourseInstanceClasses(state, courseInstance?.id),
  )

  const onClose = () => dispatch(registerStudentToCourseUpdated(undefined))

  const onRegister = () => {
    if (!user || !model || !selectedClass) return
    setIsLoading(true)

    CourseClassRegistrationClient.registerStudentToClassManually(
      selectedClass.id,
      model.studentId,
      new Date(),
      user.clientId,
    )
      .then(() => {
        dispatch(registerStudentToCourseUpdated(undefined))
        setSelectedCourse(null)
        setSelectedTerm(null)
        setSelectedClass(null)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const fullName = model?.firstName + " " + model?.lastName

  if (!model) return null

  return (
    <GenericDialog
      open
      size="xs"
      onClose={onClose}
      title={`Register ${fullName} to a course`}
      content={
        <Stack direction="column" sx={{ pt: "16px", gap: "24px" }}>
          <Autocomplete
            fullWidth
            options={courses}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedCourse}
            onChange={(_, newValue) => {
              setSelectedCourse(newValue)
              setSelectedTerm(null)
              setSelectedClass(null)
            }}
            renderInput={(params) => <TextField {...params} label="Course" />}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <Stack>
                    <Typography variant="body2">{option.title}</Typography>
                    <Typography variant="body3" color="text.secondary">
                      {option.name}
                    </Typography>
                  </Stack>
                  <Typography variant="body3" color="text.disabled">
                    {option.displayId || uuidToShortId(option.id)}
                  </Typography>
                </Stack>
              </li>
            )}
          />
          <Autocomplete
            fullWidth
            options={terms}
            getOptionLabel={(option) =>
              formatDateAsYearAndLongMonth(option.startDate)
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedTerm}
            onChange={(_, newValue) => {
              setSelectedTerm(newValue)
              setSelectedClass(null)
            }}
            disabled={!selectedCourse}
            renderInput={(params) => <TextField {...params} label="Term" />}
          />
          <Autocomplete
            fullWidth
            options={classes}
            getOptionLabel={(option) => option.language}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedClass}
            onChange={(_, newValue) => setSelectedClass(newValue)}
            disabled={!selectedCourse || !selectedTerm}
            renderInput={(params) => <TextField {...params} label="Class" />}
          />
        </Stack>
      }
      buttons={[
        {
          type: "primary",
          text: "Register",
          disabled: !user || !model || !selectedClass,
          loading: isLoading,
          onClick: onRegister,
        },
      ]}
    />
  )
}

export default RegisterStudentToCourseClassPopup
