export function generateUrlErrorMessage(
  value: unknown,
  label: string,
  missingUrl: boolean | undefined,
  invalidUrl: boolean | undefined,
  errors: string[] | undefined,
) {
  const isMissingUrl =
    missingUrl ?? (isUrlMissing(value) && missingUrlBackendValidations(errors))
  const isInvalidUrl =
    invalidUrl ?? (isUrlInvalid(value) && errors?.includes("INVALID_URL"))
  const isInvalidCodioUrl =
    isCodioUrlInvalid(value, label?.toString() ?? "URL") &&
    errors?.includes("INVALID_CODIO_TASK_URL")

  return isMissingUrl
    ? "This field is required"
    : isInvalidUrl || isInvalidCodioUrl
    ? "Invalid URL"
    : undefined
}

function isUrlMissing(value: unknown) {
  return value === undefined || value === null || value === ""
}

function isUrlInvalid(value: unknown) {
  if (typeof value !== "string") {
    return true
  }
  try {
    new URL(value)
    return false
  } catch {
    return true
  }
}

function isCodioUrlInvalid(value: unknown, label: string) {
  if (typeof value !== "string") {
    return true
  }

  try {
    new URL(value)
  } catch {
    return true
  }

  if (label.toLocaleLowerCase().includes("project")) {
    const urlElements = value.split("lti/")
    return urlElements.length !== 2
  }

  if (label.toLocaleLowerCase().includes("task")) {
    const urlWithoutProtocol = value.replace(/^https:\/\//, "")
    const taskUrlElements = urlWithoutProtocol.split("/")
    return taskUrlElements.length !== 3
  }
}

function missingUrlBackendValidations(errors: string[] | undefined) {
  return (
    errors?.includes("MISSING_URL") ||
    errors?.includes("MISSING_CODIO_TASK_URL")
  )
}
