import { RootState } from "@app/store"
import {
  CourseDescriptorDto,
  CourseStatus,
} from "@masterschool/course-builder-api"
import { createSelector } from "@reduxjs/toolkit"
import { selectLastVersionSyllabuses } from "@features/syllabus/syllabusSelectors"

import { parseStringVersion } from "@utils/versionUtils"

export const selectLatestCourseVersion = (
  courseId: string | undefined,
  majorVersion: number | "latest" | undefined,
) =>
  createSelector([selectCourses], (courses) => {
    if (!courseId || !courses || !majorVersion) {
      return undefined
    }

    return getLatestCourseVersion(courses, courseId, majorVersion)
  })

export const selectLatestPublishedCourse = (
  courseId: string | undefined,
  majorVersion: number | "latest" | undefined,
) =>
  createSelector([selectCourses], (courses) => {
    if (!courseId || !majorVersion || !courses) {
      return undefined
    }

    return findLatestCourseVersionForStatus(
      courses,
      courseId,
      majorVersion,
      CourseStatus.Published,
    )
  })

export const selectCourses = (
  state: RootState,
): CourseDescriptorDto[] | undefined => {
  if (
    state.coursesMenu.courses === "pending" ||
    state.coursesMenu.courses === "rejected"
  ) {
    return undefined
  }

  return state.coursesMenu.courses
}

export const selectLatestPublishedCoursesByIds = createSelector(
  [(_, courseIds: string[]) => courseIds, selectCourses],
  (courseIds, courses) => {
    if (!courses) {
      return []
    }

    return courseIds.msCompactMap((courseId) =>
      findLatestCourseVersionForStatus(
        courses,
        courseId,
        "latest",
        CourseStatus.Published,
      ),
    )
  },
)

export const selectCourseVersion = (courseId: string, version: string) =>
  createSelector([selectCourses], (courses) => {
    if (!courses) {
      return undefined
    }

    return courses.find(
      (course) => course.id === courseId && course.version === version,
    )
  })

export const selectLatestDraftCourse = (
  courseId: string | undefined,
  majorVersion: number | "latest" | undefined,
) =>
  createSelector([selectCourses], (courses) => {
    if (!courseId || !courses || !majorVersion) {
      return undefined
    }

    return findLatestCourseVersionForStatus(
      courses,
      courseId,
      majorVersion,
      CourseStatus.Draft,
    )
  })

export const selectIsEditingPublishedCourse = (
  courseId: string,
  majorVersion: number | "latest",
) =>
  createSelector(
    [
      selectLatestPublishedCourse(courseId, majorVersion),
      selectLatestDraftCourse(courseId, majorVersion),
    ],
    (published, draft) => {
      return published !== undefined && draft !== undefined
    },
  )

function findLatestCourseVersionForStatus(
  courses: CourseDescriptorDto[],
  courseId: string,
  majorVersion: number | "latest",
  status: CourseStatus,
) {
  return getLatestCourseVersion(
    courses.filter((course) => course.status === status),
    courseId,
    majorVersion,
  )
}

function getLatestCourseVersion(
  courses: CourseDescriptorDto[],
  courseId: string,
  majorVersion: number | "latest",
): CourseDescriptorDto | undefined {
  const coursesById = courses.filter((course) => course.id === courseId)

  if (coursesById.length === 0) {
    return undefined
  }
  const latestCourse = coursesById.reduce((prev, curr) =>
    parseFloat(curr.version) > parseFloat(prev.version) ? curr : prev,
  )
  const { major: latestCourseMajor } = parseStringVersion(latestCourse.version)
  const chosenMajorVersion =
    majorVersion === "latest" ? latestCourseMajor : majorVersion
  const coursesByIdAndMajor = coursesById.filter((course) => {
    const { major } = parseStringVersion(course.version)
    return major === chosenMajorVersion
  })

  if (coursesByIdAndMajor.length === 0) {
    return undefined
  }

  return coursesByIdAndMajor.reduce((prev, curr) =>
    parseFloat(curr.version) > parseFloat(prev.version) ? curr : prev,
  )
}

export const selectCoursesMainPageTab = (state: RootState) =>
  state.coursesMenu.tab

export const selectPublishedCourses = createSelector(
  selectCourses,
  (courses) => {
    if (!courses) {
      return undefined
    }

    return courses.filter((course) => course.status === CourseStatus.Published)
  },
)

export const selectLatestVersionsPublishedCourses = createSelector(
  selectPublishedCourses,
  (courses) => {
    if (!courses) {
      return undefined
    }

    const latestCoursesByVersion = courses.reduce((acc, curr) => {
      const course = acc[curr.id]
      if (!course || parseFloat(course.version) < parseFloat(curr.version)) {
        acc[curr.id] = curr
      }
      return acc
    }, {} as { [key: string]: CourseDescriptorDto })

    return Object.values(latestCoursesByVersion)
  },
)

export const selectCourseMajorRepresentatives = createSelector(
  [(_, courseId: string) => courseId, selectCourses],
  (courseId, courses) => {
    if (!courses) {
      return undefined
    }
    const allVersions = courses.filter((course) => course.id === courseId)
    const ascendingVersions = allVersions.sort(
      (a, b) => parseFloat(a.version) - parseFloat(b.version),
    )
    const majorMap = ascendingVersions.reduce((acc, course) => {
      const { major } = parseStringVersion(course.version)
      if (!acc[major]) {
        acc[major] = course
        return acc
      }
      if (
        parseFloat(acc[major].version) < parseFloat(course.version) &&
        course.status !== CourseStatus.Draft
      ) {
        acc[major] = course
        return acc
      }
      return acc
    }, {} as Record<number, CourseDescriptorDto>)
    return Object.values(majorMap)
  },
)

export const selectCourseByMajorVersion = createSelector(
  [
    (_, courseId: string, majorVersion: number) => ({ courseId, majorVersion }),
    selectCourseMajorRepresentatives,
  ],
  ({ courseId, majorVersion }, majorsCourses) => {
    if (!majorsCourses) {
      return undefined
    }
    const allCourseMajors = majorsCourses.filter(
      (course) => course.id === courseId,
    )
    return allCourseMajors.find(
      (course) => parseStringVersion(course.version).major === majorVersion,
    )
  },
)

export const selectMinorVersionCourse = createSelector(
  [
    (
      _,
      identifiers: {
        courseId: string
        version?: string
        position: "First" | "Last"
      },
    ) => identifiers,
    selectCourses,
  ],
  ({ courseId, version, position }, courses) => {
    if (!courses || !version) {
      return undefined
    }
    const { major } = parseStringVersion(version)

    const relevantCourses = courses.filter((course) => {
      const { major: courseMajor } = parseStringVersion(course.version)
      return course.id === courseId && courseMajor === major
    })
    if (position === "First") {
      return relevantCourses.reduce((acc, currentCourse) => {
        if (parseFloat(currentCourse.version) < parseFloat(acc.version)) {
          return currentCourse
        }
        return acc
      }, relevantCourses[0])
    }
    return relevantCourses.reduce((acc, currentCourse) => {
      if (parseFloat(currentCourse.version) > parseFloat(acc.version)) {
        return currentCourse
      }
      return acc
    }, relevantCourses[0])
  },
)

export const selectMinorChanges = (
  courseId: string | undefined,
  majorVersion: number | "latest" | undefined,
) =>
  createSelector(
    [
      selectLatestDraftCourse(courseId, majorVersion),
      selectLatestPublishedCourse(courseId, majorVersion),
    ],
    (draft, published) => {
      return draft !== undefined && published !== undefined
    },
  )

export const selectCoursesFetchingStatus = (state: RootState) => {
  if (state.coursesMenu.courses === "pending") {
    return "loading"
  } else if (state.coursesMenu.courses === "rejected") {
    return "error"
  } else {
    return "idle"
  }
}

export const selectCoursesSortOption = (state: RootState) =>
  state.coursesMenu.sortOption

export const selectCoursesType = (state: RootState) => state.coursesMenu.type

export const selectCourseDisplayName = (courseId: string) =>
  createSelector([selectCourses], (courses) => {
    if (!courses) {
      return undefined
    }

    const course = courses.find((course) => course.id === courseId)

    return course?.title
  })

export const selectDependentSyllabuses = (courseId: string | undefined) =>
  createSelector([selectLastVersionSyllabuses], (syllabuses) => {
    return syllabuses
      .filter((syllabus) => {
        return syllabus.units.some((unit) =>
          unit.courseDescriptors.some((cd) => cd.courseId === courseId),
        )
      })
      .map((syllabus) => syllabus.id)
  })
