import { useAppDispatch } from "@app/hooks"
import { RootState } from "@app/store"
import { ShiftsClient } from "@clients/shiftsClient"
import BackButton from "@cmp/buttons/backButton"
import {
  availableShiftsSelector,
  salesTeamWithShiftsSelector,
  selectConsultant,
  selectConsultantShifts,
} from "@features/sales-management/salesManagement.selectors"
import {
  updateConsultantAcceptedQtfs,
  updateConsultantLanguages,
  updateConsultantAvailability,
  updateConsultantIsPlacementExpert,
  fetchShifts,
  editShift,
} from "@features/sales-management/salesManagement.slice"
import { CreateShiftRequest, ShiftDto } from "@masterschool/course-builder-api"
import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { ConsultantShifts } from "./consultantShifts"
import { CreateOrAttachShift } from "./createOrAttachShift"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import GenericDialog from "@cmp/genericDialog"
import { CreateOrEditShiftForm } from "./createOrEditShiftForm"

export const ShiftManagement = () => {
  const { consultantId } = useParams()
  const dispatch = useAppDispatch()
  const selectConsultantShiftsMemo = useMemo(() => {
    return (state: RootState) => selectConsultantShifts(state, consultantId)
  }, [consultantId])
  const selectConsultantMemo = useMemo(() => {
    return (state: RootState) => selectConsultant(state, consultantId)
  }, [consultantId])
  const [languagesArray, setLanguagesArray] = useState<string[] | undefined>(
    undefined,
  )
  const shifts = useSelector(selectConsultantShiftsMemo)
  const orderedShifts = shifts.toSorted((a, b) => a.name.localeCompare(b.name))
  const consultant = useSelector(selectConsultantMemo)
  const salesTeamsWithShifts = useSelector(salesTeamWithShiftsSelector)
  const availableShifts = useSelector(availableShiftsSelector)
  const filteredAvailableShifts =
    availableShifts !== "pending"
      ? availableShifts.filter(
          (availableShift) =>
            shifts.find((shift) => shift.id === availableShift.id) ===
            undefined,
        )
      : []
  const [acceptedQtfsState, setAcceptedQtfsState] = useState<
    number[] | undefined
  >(undefined)
  const [shiftToEdit, setShiftToEdit] = useState<ShiftDto | undefined>(
    undefined,
  )

  const flattenConsultants =
    salesTeamsWithShifts !== "pending"
      ? salesTeamsWithShifts.flatMap((team) => team.consultants)
      : []
  const otherConsultants = flattenConsultants.filter(
    (consultantWithShift) => consultantWithShift.id !== consultantId,
  )

  const otherConsultantsWithSameShift = shiftToEdit
    ? otherConsultants.filter((consultant) =>
        consultant.shifts.find((shift) => shift.id === shiftToEdit.id),
      )
    : []
  useEffect(() => {
    if (!consultant) {
      return
    }

    setAcceptedQtfsState(consultant.acceptedQtfs)
    setLanguagesArray(consultant.languages)
  }, [setAcceptedQtfsState, consultant, setLanguagesArray])

  const updateConsultantQtfs = (
    consultantId: string,
    acceptedQtfs: number[],
  ) => {
    dispatch(updateConsultantAcceptedQtfs({ consultantId, acceptedQtfs }))
  }

  const navigate = useNavigate()
  const handleAvailableChange = (params: {
    consultantId: string
    newIsAvailable: boolean
  }) => {
    dispatch(
      updateConsultantAvailability({
        consultantId: params.consultantId,
        isAvailable: params.newIsAvailable,
      }),
    )
  }
  const handleIsPlacementExpertChange = (params: {
    consultantId: string
    newIsPlacementExpert: boolean
  }) => {
    dispatch(
      updateConsultantIsPlacementExpert({
        consultantId: params.consultantId,
        isPlacementExpert: params.newIsPlacementExpert,
      }),
    )
  }
  const onDeleteShift = (shiftId: string) => {
    if (!consultantId) {
      return
    }
    ShiftsClient.unattach(shiftId, consultantId).then(() => {
      dispatch(fetchShifts())
    })
  }

  const handleEditShiftSubmit = (editedShiftRequest: CreateShiftRequest) => {
    if (!shiftToEdit) {
      return
    }

    const editedShift = {
      id: shiftToEdit.id,
      ...editedShiftRequest,
    }
    dispatch(editShift(editedShift))
    setShiftToEdit(undefined)
  }

  const onEditButtonClicked = (shiftId: string) => {
    const shift = shifts.find((shift) => shift.id === shiftId)
    setShiftToEdit(shift)
  }

  const onAttachShift = (shiftDto: ShiftDto) => {
    if (!consultantId) {
      return
    }
    ShiftsClient.attach(shiftDto.id, consultantId).then(() => {
      dispatch(fetchShifts())
    })
  }

  const onCreateCustomShift = (createShiftRequest: CreateShiftRequest) => {
    if (!consultantId) {
      return
    }

    ShiftsClient.createAndAttach(createShiftRequest, consultantId).then(() => {
      dispatch(fetchShifts())
    })
  }

  const calculateNewAcceptedQtfs = (
    currentState: number[],
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.checked && !currentState.includes(parseInt(e.target.value))) {
      return [...currentState, parseInt(e.target.value)]
    } else if (
      !e.target.checked &&
      currentState.includes(parseInt(e.target.value))
    ) {
      return currentState.filter((qtf) => qtf !== parseInt(e.target.value))
    }
    return currentState
  }

  const calculateNewLanguages = (
    currentState: string[],
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.checked && !currentState.includes(e.target.value)) {
      return [...currentState, e.target.value]
    } else if (!e.target.checked && currentState.includes(e.target.value)) {
      return currentState.filter((language) => language !== e.target.value)
    }
    return currentState
  }

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!consultantId || acceptedQtfsState === undefined) {
      return
    }
    const newState = calculateNewAcceptedQtfs(acceptedQtfsState, e)
    setAcceptedQtfsState(newState)
    updateConsultantQtfs(consultantId, newState)
  }

  const onLanguageCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!consultantId || languagesArray === undefined) {
      return
    }

    const newState = calculateNewLanguages(languagesArray, e)
    setLanguagesArray(newState)
    dispatch(updateConsultantLanguages({ consultantId, languages: newState }))
  }

  if (!consultant) {
    return <Navigate to="/sales-management" />
  }

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
      paddingX="48px"
      paddingBottom="24px"
    >
      <Box display="flex" flexDirection="column" gap="32px" overflow="scroll">
        <Box paddingTop="32px">
          <BackButton
            onClick={() => {
              navigate("/sales-management")
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          paddingTop="32px"
        >
          <Typography variant="h4" textAlign="left" textTransform="capitalize">
            {consultant.name}
          </Typography>
          <Box display="flex" alignItems="center">
            <Switch
              checked={consultant.isAvailable}
              onChange={(e) =>
                handleAvailableChange({
                  consultantId: consultant.id,
                  newIsAvailable: e.target.checked,
                })
              }
            />
            <Typography variant="body1" color="textSecondary">
              Available
            </Typography>
            <Switch
              checked={consultant.isPlacementExpert}
              onChange={(e) =>
                handleIsPlacementExpertChange({
                  consultantId: consultant.id,
                  newIsPlacementExpert: e.target.checked,
                })
              }
            />
            <Typography variant="body1" color="textSecondary">
              Golden Leads
            </Typography>
          </Box>
        </Box>
        {!consultant.isAvailable && (
          <Alert severity="error">
            {consultant.name} is set as unavailable so he won't receive any new
            leads
          </Alert>
        )}
        <Box display="flex" flexDirection="row" gap="24px" width="100%">
          <ConsultantShifts
            shifts={orderedShifts}
            onDeleteShift={onDeleteShift}
            onEditShift={(shiftId: string) => onEditButtonClicked(shiftId)}
          />
          <CreateOrAttachShift
            availableShifts={filteredAvailableShifts}
            onAttachShift={onAttachShift}
            onCreateShift={onCreateCustomShift}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          bgcolor="white"
          gap="24px"
          padding="24px"
        >
          <Typography variant="h6">Preferences</Typography>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
              <Typography variant="body1" paddingBottom="8px">
                Select the QTFs you would like this consultant to receive
              </Typography>
              {acceptedQtfsState === undefined ? (
                <LoadingComponent loadedComponents="" />
              ) : (
                <FormGroup row>
                  {[1, 2, 3, 4, 5].map((qtf) => (
                    <FormControlLabel
                      key={qtf}
                      control={
                        <Checkbox
                          value={qtf}
                          checked={acceptedQtfsState.includes(qtf)}
                          onChange={(e) => onCheckboxChange(e)}
                        />
                      }
                      label={qtf}
                    />
                  ))}
                </FormGroup>
              )}
            </Box>
            <Box>
              <Divider orientation="vertical" />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="body1" paddingBottom="8px">
                What languages does this consultant speak fluently?
              </Typography>
              {languagesArray === undefined ? (
                <LoadingComponent loadedComponents="" />
              ) : (
                <FormGroup row>
                  {["English", "German"].map((language) => (
                    <FormControlLabel
                      key={language}
                      control={
                        <Checkbox
                          value={language}
                          checked={languagesArray.includes(language)}
                          onChange={(e) => onLanguageCheckboxChange(e)}
                        />
                      }
                      label={language}
                    />
                  ))}
                </FormGroup>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <GenericDialog
        open={shiftToEdit !== undefined}
        size="sm"
        onClose={() => setShiftToEdit(undefined)}
        title={`Edit shift`}
        content={
          <Box paddingTop="16px" display="flex" flexDirection="column">
            <CreateOrEditShiftForm
              onSubmit={handleEditShiftSubmit}
              initialShift={shiftToEdit}
            />
            {otherConsultantsWithSameShift.length > 0 && (
              <Typography variant="body2" textAlign="center" paddingTop="16px">
                Pay attention, this Shift is also being used by other
                consultants:
                {otherConsultantsWithSameShift.map(
                  (c, index) =>
                    " " +
                    c.name +
                    (index === otherConsultantsWithSameShift.length - 1
                      ? "."
                      : ","),
                )}
              </Typography>
            )}
          </Box>
        }
        buttons={[]}
      />
    </Box>
  )
}
