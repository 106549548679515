import { useAppDispatch, useAppSelector } from "@app/hooks"
import Droppable from "@cmp/droppable"
import {
  useSensors,
  useSensor,
  PointerSensor,
  DndContext,
  DragOverlay,
} from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  CourseLanguage,
  DatacampLesson,
  Topic,
} from "@masterschool/course-builder-api"
import { createPortal } from "react-dom"
import {
  dragTaskStarted,
  dragTaskEnded,
  taskDraggedOver,
} from "@features/courseEditor/courseEditorSlice"
import DatacampTasksGroupEditor from "./datacampTasksGroupEditor"
import HighlightableScrollRef from "@cmp/highlightableScrollRef"
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { useSelectedDatacampLesson } from "../../custom-hooks/useSelectedElementItem"
import { Box, Typography } from "@mui/material"

type DatacampGroupsEditorProps = {
  topic: Topic
  editingLanguage: CourseLanguage
}

const DatacampGroupsEditor = forwardRef<
  HighlightableScrollRef,
  DatacampGroupsEditorProps
>((props, ref) => {
  const { topic, editingLanguage } = props
  const lesson = useSelectedDatacampLesson()
  const tasksContainerRef = useRef<HTMLDivElement>(null)
  const [highlightLastTask, setHighlightLastTask] = useState(false)
  const scrollToBottomAndHighlightLastTask = useCallback(() => {
    if (!tasksContainerRef.current) {
      return
    }

    tasksContainerRef.current.onscrollend = () => {
      setHighlightLastTask(true)
      setTimeout(() => {
        setHighlightLastTask(false)
      }, 1000)

      if (tasksContainerRef.current) {
        tasksContainerRef.current.onscrollend = null
      }
    }
    tasksContainerRef.current.scrollTo({
      top: tasksContainerRef.current.scrollHeight,
      behavior: "smooth",
    })
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      scrollToBottomAndHighlightLastTask: scrollToBottomAndHighlightLastTask,
    }),
    [scrollToBottomAndHighlightLastTask],
  )

  if (!lesson) {
    return undefined
  }

  return (
    <Box
      ref={tasksContainerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        overflowY: "scroll",
      }}
    >
      <GroupsList
        lesson={lesson}
        highlightLastTask={highlightLastTask}
        topic={topic}
        editingLanguage={editingLanguage}
      />
    </Box>
  )
})

function GroupsList(props: {
  lesson: DatacampLesson
  highlightLastTask: boolean
  topic: Topic
  editingLanguage: CourseLanguage
}) {
  const { lesson, highlightLastTask, topic, editingLanguage } = props
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  )
  const dispatch = useAppDispatch()
  const isDraggingTasksEnabled = useAppSelector(
    (state) => state.courseEditor.isDraggingTasksEnabled,
  )
  const dragTaskStartedId = useAppSelector(
    (state) => state.courseEditor.draggedTaskId,
  )
  const draggedGroup = lesson.tasks.find((t) => t.id === dragTaskStartedId)

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {lesson.tasks.length > 0 && (
        <Typography variant="body2_sb">Tasks Groups</Typography>
      )}
      <DndContext
        onDragStart={(start) => {
          dispatch(dragTaskStarted({ id: start.active.id.toString() }))
        }}
        onDragEnd={() => {
          dispatch(dragTaskEnded())
        }}
        onDragCancel={() => {
          dispatch(dragTaskEnded())
        }}
        onDragOver={({ active, over }) => {
          if (!active || !over) {
            return
          }
          dispatch(
            taskDraggedOver({
              editStepId: window.crypto.randomUUID(),
              lessonId: lesson.id,
              sourceIndex: active.data.current?.sortable?.index,
              targetIndex: over.data.current?.sortable?.index,
            }),
          )
        }}
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
      >
        <Droppable
          id={`${lesson.id}-tasks`}
          items={lesson.tasks.map((group, index) => {
            const isLast = index === lesson.tasks.length - 1
            return {
              element: (
                <DatacampTasksGroupEditor
                  lesson={lesson}
                  groupId={group.id}
                  index={index}
                  highlight={isLast && highlightLastTask}
                  hide={group.id === draggedGroup?.id}
                  topic={topic}
                  editingLanguage={editingLanguage}
                />
              ),
              identifier: group.id,
            }
          })}
          placeholder={<></>}
          disabled={!isDraggingTasksEnabled}
        />
        {createPortal(
          <DragOverlay
            dropAnimation={{
              duration: 300,
              easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
            }}
            style={{
              zIndex: 9999999,
            }}
          >
            {draggedGroup ? (
              <DatacampTasksGroupEditor
                lesson={lesson}
                groupId={draggedGroup.id}
                index={NaN}
                highlight={false}
                hide={false}
                topic={topic}
                editingLanguage={editingLanguage}
              />
            ) : null}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>
    </div>
  )
}

export default DatacampGroupsEditor
