import GenericDialog, { GenericDialogProps } from "@cmp/genericDialog"
import { FormControl, SvgIcon } from "@mui/material"
import { useEffect, useState } from "react"
import { UrlFormField } from "../../../../../projects/manageProjectDialog"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { AssignedClassesSelector } from "../../../staff/assignedClassesSelector"
import { closeManageHomepageUrlPopup } from "@features/program/programSlice"
import { CampusClient } from "@clients/campusClient"
import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"
import { fetchClasses } from "@features/program/programSliceThunks"
import appIcons from "@utils/appIcons"

export const ManageProgramHomepageDialog = ({
  programId,
}: {
  programId: string
}) => {
  const [url, setUrl] = useState("")
  const model = useAppSelector((state) => state.program.manageHomepageUrlPopup)
  const classes = useAppSelector(selectClassesForProgram(programId))
  const [selectedClassesIDs, setSelectedClassesIDs] = useState<string[]>([])

  const isOpen = model !== undefined
  const dispatch = useAppDispatch()
  const onClose = () => {
    dispatch(closeManageHomepageUrlPopup())
  }

  useEffect(() => {
    if (!isOpen) return
    setUrl(model?.homepageUrl || "")
    setSelectedClassesIDs(model?.classIds || [])
  }, [isOpen, model])

  const onAddClicked = (
    classIDs: string[],
    homepageUrl: string | undefined,
  ) => {
    CampusClient.put(`school/homepage`, {
      offeringClientIDs: classIDs,
      homepageUrl,
    })
      .then(() => {
        const actionName = homepageUrl ? "added" : "removed"
        dispatch(showSuccessSnackbar(`Program page link ${actionName}`))
        dispatch(fetchClasses({ programId }))
        onClose()
      })
      .catch((e) => {
        const actionName = homepageUrl ? "add" : "remove"
        dispatch(
          showErrorSnackbar(
            `Failed to ${actionName} program page link: ${e.message}`,
          ),
        )
      })
  }
  const action = model?.homepageUrl ? "Edit" : "Add"
  const title =
    action === "Add"
      ? "Add a link to this program's notion page"
      : "Edit program notion page"

  return (
    <GenericDialog
      open={model !== undefined}
      onClose={onClose}
      title={title}
      subtitle={`Students will see this page on campus under the "Program" tab`}
      size="sm"
      content={
        <FormControl
          sx={{
            gap: "16px",
            paddingTop: "8px",
          }}
        >
          <UrlFormField
            label="Program page link"
            value={url}
            error={!url.includes("www.notion.so")}
            helperText={
              url.includes("www.notion.so")
                ? undefined
                : "Link must start with 'www.notion.so'"
            }
            onChange={(e) => setUrl(e.target.value)}
          />
          <AssignedClassesSelector
            selectedClassesIDs={selectedClassesIDs}
            onSelectedClassesChange={setSelectedClassesIDs}
            programClasses={classes}
            label="Select classes that will see this page"
          />
        </FormControl>
      }
      buttons={
        [
          action === "Edit"
            ? {
                text: "Remove link",
                type: "danger",
                disabled: selectedClassesIDs.length === 0,
                align: "start",
                icon: (
                  <SvgIcon
                    component={appIcons.trash03}
                    inheritViewBox
                    sx={{
                      width: 20,
                      height: 20,
                      fill: "none",
                      color: "icon.white",
                      stroke: "currentColor",
                    }}
                  />
                ),
                onClick: () => onAddClicked(selectedClassesIDs, undefined),
              }
            : null,
          {
            text: "Cancel",
            type: "secondary",
            onClick: onClose,
          },
          {
            text: "Add",
            type: "primary",
            onClick: () => onAddClicked(selectedClassesIDs, url),
            disabled: !url || selectedClassesIDs.length === 0,
          },
        ].msCompact() as GenericDialogProps["buttons"]
      }
    />
  )
}
