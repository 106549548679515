import {} from "@masterschool/course-builder-api"
import { GridColDef, GridComparatorFn } from "@mui/x-data-grid-pro"
import {
  CourseVersionCell,
  CourseSizeCell,
  CourseDateCell,
  CourseStatusCell,
  CourseNotesCell,
  ActionsCell,
} from "./courseMajorsCells"
import { CourseMajorDescriptorDto } from "../utils"

const getDateColumn: () => GridColDef<CourseMajorDescriptorDto> = () => {
  return {
    field: "createdAtTimestamp",
    headerName: "Date",
    flex: 1,
    renderCell: (params) => {
      return (
        <CourseDateCell
          courseId={params.row.courseId}
          version={params.row.version}
        />
      )
    },
    sortComparator: dateComparator,
  }
}

const getCourseVersionColumn: () => GridColDef<CourseMajorDescriptorDto> =
  () => {
    return {
      field: "version",
      headerName: "Version ID",
      flex: 1,
      renderCell: (params) => {
        return (
          <CourseVersionCell
            displayId={params.row.displayId}
            courseId={params.row.courseId}
            version={params.row.version}
          />
        )
      },
      sortComparator: versionComparator,
    }
  }

const getCourseSizeColumn: () => GridColDef<CourseMajorDescriptorDto> = () => {
  return {
    field: "domain",
    headerName: "Size",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const { elementsDuration, liveEventsDuration } = params.row
      return (
        <CourseSizeCell
          ItemsDuration={elementsDuration}
          liveEventsDuration={liveEventsDuration}
        />
      )
    },
  }
}

const getStatusColumn: () => GridColDef<CourseMajorDescriptorDto> = () => {
  return {
    field: "status",
    headerName: "Status",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return <CourseStatusCell course={params.row} />
    },
  }
}

const getNotesColumn: () => GridColDef<CourseMajorDescriptorDto> = () => {
  return {
    field: "notes",
    headerName: "Notes",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return <CourseNotesCell notes={undefined} />
    },
  }
}

const getMenuActions: () => GridColDef<CourseMajorDescriptorDto> = () => {
  return {
    field: "actions",
    headerClassName: "course-versions-actions-header",
    headerName: "",
    sortable: false,
    renderCell: (params) => {
      return <ActionsCell course={params.row} />
    },
  }
}

const versionComparator: GridComparatorFn<string> = (version1, version2) => {
  return version1 > version2 ? 1 : -1
}

const dateComparator: GridComparatorFn<number> = (date1, date2) => {
  return date1 - date2
}

export const coursesColumns = [
  getDateColumn(),
  getCourseVersionColumn(),
  getCourseSizeColumn(),
  getNotesColumn(),
  getStatusColumn(),
  getMenuActions(),
]
