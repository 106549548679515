import { useAppSelector } from "@app/hooks"
import { selectFilteredStudents } from "@features/program/programSliceSelectors"
import { ProgramDto } from "@masterschool/course-builder-api"
import { Box, Stack, Typography } from "@mui/material"
import {
  getProgramNoSyllabusWarning,
  getProgramHasUnassignedStudentsWarning,
  programDisplayName,
} from "../ui/program+ui"
import StudentsWidget from "./widgets/studentsWidget"
import SyllabusPagePopupsPresenter from "../syllabus/popups/popupsPresenter"
import ProgramIssueWarning from "../ui/programIssueWarning"
import TrackWidget from "./widgets/trackWidget"
import { selectTrackById } from "@features/tracks/trackSelectors"
import { DomainChip } from "@utils/domainChip"
import appIcons from "@utils/appIcons"
import { IconChip } from "@utils/iconChip"
import { ProgramHomepagesWidget } from "./widgets/programHomepagesWidget"
import StaffWidget from "./widgets/staffWidget"

function ProgramOverview(props: { program: ProgramDto }) {
  const program = props.program
  const programStudents = useAppSelector(selectFilteredStudents(program.id))

  const noSyllabusWarning = getProgramNoSyllabusWarning(program)
  const unassignedStudentsWarning = getProgramHasUnassignedStudentsWarning(
    program,
    programStudents,
  )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
        width: "100%",
        maxWidth: "1240px",
      }}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <SyllabusPagePopupsPresenter />
        {noSyllabusWarning && (
          <ProgramIssueWarning
            alertText={noSyllabusWarning}
            programId={program.id}
            targetTab="syllabus"
          />
        )}
        {unassignedStudentsWarning && (
          <ProgramIssueWarning
            alertText={unassignedStudentsWarning}
            programId={program.id}
            targetTab="students"
          />
        )}
        <Box pb={4}>
          <ProgramInfo program={program} />
        </Box>
        <Stack overflow="auto" gap={2} paddingBottom={2} width={"100%"}>
          <Stack direction="row" alignItems="center" gap={1} width={"100%"}>
            <Box flex={1}>
              <StudentsWidget program={program} />
            </Box>
            <Box flex={1}>
              <TrackWidget program={program} />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="start" gap={1} width={"100%"}>
            <Box flex={1}>
              <ProgramHomepagesWidget
                programId={program.id}
                homepages={program.homepages}
              />
            </Box>
            <Box flex={1}>
              <StaffWidget program={program} />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

const ProgramInfo = ({ program }: { program: ProgramDto }) => {
  const track = useAppSelector((state) =>
    selectTrackById(state, program.syllabusId || ""),
  )
  const numberOfTerms = program.durationInMonths
  const numberOfCourses =
    track?.terms.flatMap((term) => term.courseDescriptors).length ?? 0

  return (
    <Stack gap={1}>
      <Typography variant="h5">
        {programDisplayName(track, program.startDate)}
      </Typography>
      <Stack direction="row" gap={1}>
        <DomainChip domain={program.domain} />
        <NumberOfTermsChip value={numberOfTerms} />
        <NumberOfCoursesChip value={numberOfCourses} />
      </Stack>
    </Stack>
  )
}

const NumberOfTermsChip = ({ value }: { value: number }) => {
  return (
    <IconChip icon={appIcons.calendar} title={`${value} terms`} size={16} />
  )
}
const NumberOfCoursesChip = ({ value }: { value: number }) => {
  return (
    <IconChip icon={appIcons.bookClosed} title={`${value} courses`} size={16} />
  )
}

export default ProgramOverview
