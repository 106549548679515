import MsCalendar from "@masterschool/ui-calendar"

import { Stack } from "@mui/material"
import { useEventActions } from "../../main/calendar/useEventActions"
import { useGetCalendarById } from "../../main/calendar/useGetCalendar"
import {
  CalendarHubClient,
  MsPrivateCalendarDto,
} from "@clients/calendarHubClient"
import {
  CalendarGroupings,
  MsCalendarProps,
} from "@masterschool/ui-calendar/dist/src/types"
import { mapToMsCalendarProps } from "../../main/calendar/calendarMappers"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { useAppSelector } from "@app/hooks"
import { useEffect, useMemo, useState } from "react"
import { TutorClient, TutorDto } from "@clients/tutorClient"
import { FetchEventSnackbar } from "../../main/calendar/FetchEventSnackbar"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { DateRange, defaultDateRange } from "@utils/date"

export const BookSessionWithTutor = () => {
  const user = useAppSelector(selectLoggedInUser)

  const [dateRange, setDateRange] = useState<DateRange>(defaultDateRange)

  const { tutors } = useGetTutorList()
  const { calendars: tutorCalendars, isFetching: isFetchingTutorCalendars } =
    useGetTutorsCalendars()
  const tutorCalendarIds = useMemo(
    () => tutorCalendars.personalCalendars.map((c) => c.id),
    [tutorCalendars],
  )
  const {
    calendars: studentCalendars,
    isFetching: isFetchingStudentCalendars,
  } = useGetStudentsCalendars()

  const tutorIds = tutors.map((t) => t.userClientId)
  const studentCalendarsFiltered = useMemo(() => {
    const filtered = studentCalendars.personalCalendars.filter(
      (c) => !tutorIds.includes(c.userClientId),
    )
    return { personalCalendars: filtered }
  }, [studentCalendars, tutorIds])

  const {
    msEvents,
    onChange,
    onDelete,
    addCalendarIdsToFetch,
    loading,
    onCalendarDatesChange,
  } = useEventActions(tutorCalendarIds, dateRange, setDateRange)

  const tutorByUserClientId = useMemo(
    () =>
      tutors.reduce((acc, tutor) => {
        acc[tutor.userClientId] = tutor
        return acc
      }, {} as Record<string, TutorDto>),
    [tutors],
  )

  const getCalendarById = useGetCalendarById(msEvents, [
    ...tutorCalendars.personalCalendars,
    ...studentCalendarsFiltered.personalCalendars,
  ])

  const hasOwnCalendarIncluded =
    tutorCalendars.personalCalendars.some(
      (c) => c.userClientId === user?.clientId,
    ) ||
    studentCalendarsFiltered.personalCalendars.some(
      (c) => c.userClientId === user?.clientId,
    )
  const willSufferFromHiddenParticipantBug = hasOwnCalendarIncluded
  const shouldSwitchToDraftModeAsWorkaround = willSufferFromHiddenParticipantBug

  if (isFetchingStudentCalendars || isFetchingTutorCalendars) {
    return (
      <Stack width={1} height={1} alignItems="center" justifyContent="center">
        <LoadingComponent loadedComponents="" />
      </Stack>
    )
  }

  return (
    <Stack width={1} height={1} px={3} pt={8}>
      <Stack flex={1} overflow="auto">
        {loading && <FetchEventSnackbar />}
        <MsCalendar
          events={msEvents}
          calendars={buildCalendarGroupings(
            studentCalendarsFiltered,
            tutorCalendars,
            tutorByUserClientId,
          )}
          getCalendar={getCalendarById}
          userId={user?.clientId || ""}
          onEventChange={onChange}
          supportCreateEventTypes={["tutoring-session"]}
          onDeleteEvent={onDelete}
          canJoinLiveSessions={false}
          showDraftEvents={shouldSwitchToDraftModeAsWorkaround}
          onSelectedCalendarChange={(calendarIds) => {
            const calendarIdsExcludeDraft = calendarIds.filter(
              (c) => !c.includes("draft"),
            )
            addCalendarIdsToFetch(calendarIdsExcludeDraft)
          }}
          onDatesRangeChange={onCalendarDatesChange}
          customCalendarFilters={{
            filters: [
              {
                field: "language",
                label: "Language",
                type: "string",
                isMultiSelection: true,
                operators: ["equals"],
                isRequired: false,
                excludedGroupingTypes: ["students"],
              },
              {
                field: "subjects",
                label: "Subjects",
                type: "string",
                isMultiSelection: true,
                operators: ["equals"],
                isRequired: false,
                excludedGroupingTypes: ["students"],
              },
              {
                field: "domains",
                label: "Domains",
                type: "string",
                isMultiSelection: true,
                operators: ["equals"],
                isRequired: false,
                excludedGroupingTypes: ["students"],
              },
            ],
            relations: "AND",
          }}
        />
      </Stack>
    </Stack>
  )
}

const buildCalendarGroupings = (
  studentCalendars: {
    personalCalendars: MsPrivateCalendarDto[]
  },
  tutorCalendars: {
    personalCalendars: MsPrivateCalendarDto[]
  },
  tutorsMap: Record<string, TutorDto>,
): CalendarGroupings => {
  return [
    {
      title: "Tutors",
      type: "single",
      groupingsType: "staff",
      groupingsAction: "all",
      calendars: tutorCalendars.personalCalendars.map((c) =>
        mapToCalendarPropsForTutor(c, tutorsMap[c.userClientId]),
      ),
    },
    {
      title: "Students",
      type: "single",
      groupingsType: "students",
      groupingsAction: "all",
      calendars: studentCalendars.personalCalendars.map((c) =>
        mapToMsCalendarProps(c),
      ),
    },
  ]
}

const mapToCalendarPropsForTutor = (
  calendar: MsPrivateCalendarDto,
  tutor: TutorDto,
): MsCalendarProps => {
  const calendarProps = mapToMsCalendarProps(calendar)
  const tags = {
    language: tutor.properties.languages,
    subjects: tutor.properties.supportedSubjects,
    domains: tutor.properties.domains,
  }
  return {
    ...calendarProps,
    tags,
  }
}

const useGetTutorsCalendars = () => {
  const [isFetching, setIsFetching] = useState(true)
  const [calendars, setCalendars] = useState<{
    personalCalendars: MsPrivateCalendarDto[]
  }>({
    personalCalendars: [],
  })
  const { tutors, isFetching: isFetchingTutors } = useGetTutorList()
  const tutorUserClientIds = useMemo(
    () => tutors.map((t) => t.userClientId),
    [tutors],
  )

  useEffect(() => {
    if (isFetchingTutors) return
    CalendarHubClient.getCalendarsByUserClientId(tutorUserClientIds).then(
      (res) => {
        setIsFetching(false)
        setCalendars({ personalCalendars: res })
      },
    )
  }, [tutorUserClientIds, isFetchingTutors])

  return {
    calendars,
    isFetching,
  }
}

const useGetTutorList = () => {
  const [fetchingState, setFetchingState] = useState<
    undefined | "fetching" | "loaded"
  >()
  const [tutors, setTutors] = useState<TutorDto[]>([])

  useEffect(() => {
    setFetchingState("fetching")
    TutorClient.listTutors().then((res) => {
      setFetchingState("loaded")
      setTutors(res)
    })
  }, [])

  return {
    tutors,
    isFetching: fetchingState !== "loaded",
  }
}

const useGetStudentsCalendars = () => {
  const [isFetching, setIsFetching] = useState(false)
  const studentClientIds = useGetEligibleStudents()
  const [calendars, setCalendars] = useState<{
    personalCalendars: MsPrivateCalendarDto[]
  }>({
    personalCalendars: [],
  })

  useEffect(() => {
    setIsFetching(true)
    CalendarHubClient.getCalendarsByUserClientId(studentClientIds).then(
      (res) => {
        setIsFetching(false)
        setCalendars({ personalCalendars: res })
      },
    )
  }, [studentClientIds])

  return {
    calendars,
    isFetching,
  }
}

const useGetEligibleStudents = () => {
  const [studentClientId, setStudentsClientId] = useState<string[]>([])
  useEffect(() => {
    TutorClient.listEligibleStudentsClientID().then((res) => {
      setStudentsClientId(res)
    })
  }, [])
  return studentClientId
}
