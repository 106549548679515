import { useAppSelector } from "@app/hooks"
import { CourseDescriptorDto } from "@masterschool/course-builder-api"
import { selectMinorChanges } from "@features/coursesMenu/coursesSelectors"
import StatusChip from "./statusChip"
import { courseStatusTextAndColor } from "./courseStatus+Ui"
import { parseStringVersion } from "@utils/versionUtils"

function CourseViewStatus(props: { course: CourseDescriptorDto }) {
  const { course } = props
  const { major } = parseStringVersion(course.version)
  const hasChanges = useAppSelector(selectMinorChanges(course.id, major))

  const statusTextAndColor = courseStatusTextAndColor(course.status)

  return (
    <StatusChip
      text={statusTextAndColor.text}
      color={statusTextAndColor.color}
      showIndicator={hasChanges}
      isActive={undefined}
    />
  )
}

export default CourseViewStatus
