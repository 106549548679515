import {
  CourseDescriptorDto,
  TermDto,
  TrackDto,
  UnitCourseDescriptor,
} from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import appTheme from "../../theme/appTheme"
import { selectLatestPublishedCourse } from "@features/coursesMenu/coursesSelectors"
import { useAppSelector } from "@app/hooks"
import appIcons from "@utils/appIcons"
import {
  LiveSessionHours,
  SelfLearningHours,
  sumCoursesHours,
  useGetTermHours,
} from "./contentHours"
import { TrackPageBodyWrapper } from "./trackPage"
import { TrackViewHeader } from "./trackViewHeader"
import { IconChip } from "@utils/iconChip"
import { useNavigate } from "react-router-dom"

export const TrackContentView = (props: {
  track: TrackDto
  onClickEdit: () => void
  hideHeader?: boolean
  showLinkToTrack?: boolean
}) => {
  const { track, onClickEdit, hideHeader, showLinkToTrack } = props
  const navigate = useNavigate()
  return (
    <>
      {!hideHeader && (
        <TrackViewHeader
          track={track}
          isEditMode={false}
          onClickEdit={onClickEdit}
        >
          <HeaderActions onClickEdit={onClickEdit} />
        </TrackViewHeader>
      )}
      <TrackPageBodyWrapper>
        <Stack width="600px">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h4" pb={2}>
              {track.externalName}
            </Typography>
            {showLinkToTrack && (
              <Button
                variant="contained"
                size="small"
                onClick={() => navigate(`/track/${track.id}`)}
              >
                Go to track
              </Button>
            )}
          </Stack>
          <Typography variant="body1_sb" pb={2}>
            {track.internalName}
          </Typography>

          <Stack gap={2}>
            {track.terms.map((term, index) => (
              <Term key={term.id} term={term} index={index + 1} />
            ))}
            {track.terms.length === 0 && (
              <Typography variant="body2">No terms found</Typography>
            )}
          </Stack>
          <Stack sx={{ width: "100%", p: 4 }} />
        </Stack>
      </TrackPageBodyWrapper>
    </>
  )
}

const HeaderActions = (props: { onClickEdit: () => void }) => {
  const { onClickEdit } = props
  return (
    <Stack direction="row" gap={2}>
      <Button
        onClick={onClickEdit}
        size="small"
        variant="contained"
        color="sand50"
      >
        Edit
      </Button>
    </Stack>
  )
}

const Term = (props: { term: TermDto; index: number }) => {
  const { term, index } = props
  const { liveSessionHours, selfLearningHours } = useGetTermHours(term)
  return (
    <Stack
      bgcolor="primary.contrast"
      border="1px solid"
      borderColor="other.outlineBorder"
      borderRadius={2}
    >
      <Stack
        direction="row"
        sx={{
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
          backgroundColor: appTheme.palette.eTypes.sand15,
        }}
        px={3}
        py={1}
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography variant="body2" flex={1}>
            Term {index}
          </Typography>
          <Stack direction="row" gap={1}>
            <LiveSessionHours hours={liveSessionHours} />
            <SelfLearningHours hours={selfLearningHours} />
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        {term.courseDescriptors.map((courseDescriptor, index) => (
          <Box key={courseDescriptor.courseId}>
            <Course
              courseDescriptor={courseDescriptor}
              key={courseDescriptor.courseId}
            />
            {index < term.courseDescriptors.length - 1 && <Divider />}
          </Box>
        ))}
      </Stack>
    </Stack>
  )
}

const Course = (props: { courseDescriptor: UnitCourseDescriptor }) => {
  const { courseDescriptor } = props
  const course = useAppSelector(
    selectLatestPublishedCourse(courseDescriptor.courseId, "latest"),
  )
  const { courseId } = courseDescriptor
  const handleClick = () => {
    window.open(`/course/${courseId}?version=${course?.version}`, "_blank")
  }

  if (!course) {
    return <EmptyCourseSkeleton />
  }

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      justifyContent={"space-between"}
      p={3}
    >
      <Stack gap={2}>
        <Typography variant="body2">{course.title}</Typography>
        <CourseOverviewBar course={course} />
      </Stack>
      <Button size="small" onClick={handleClick}>
        <Typography variant="body2">View</Typography>
      </Button>
    </Stack>
  )
}

export const CourseOverviewBar = (props: { course: CourseDescriptorDto }) => {
  const { course } = props
  const { selfLearningHours, liveSessionHours } = sumCoursesHours([course])
  return (
    <Stack direction="row" gap={1}>
      <IconChip
        icon={appIcons.playCircle}
        title={`${liveSessionHours}h`}
        size={16}
      />
      <IconChip
        icon={appIcons.bookOpen01}
        title={`${selfLearningHours}h`}
        size={16}
      />
    </Stack>
  )
}

const EmptyCourseSkeleton = () => {
  return (
    <Stack gap={2}>
      <Box width={"600px"} height={"108px"} alignContent="center" gap={2} p={3}>
        <Skeleton variant="rectangular" />
      </Box>
    </Stack>
  )
}
