import { store } from "@app/store"
import { selectAccessToken } from "@features/login/loginSelectors"
import {
  Configuration,
  CreateShiftRequest,
  ShiftDto,
  ShiftsApi,
} from "@masterschool/course-builder-api"
export class ShiftsClient {
  private static shiftsClient = new ShiftsApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  private static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static list() {
    return ShiftsClient.shiftsClient
      .shiftsControllerGet(this.makeHeaders())
      .then((response) => response.data)
  }

  static getConsultantShifts(consultantId: string) {
    return ShiftsClient.shiftsClient
      .shiftsControllerGetConsultantShifts(consultantId, this.makeHeaders())
      .then((response) => response.data)
  }

  static getShiftsForConsultants(consultantIds: string[]) {
    return ShiftsClient.shiftsClient
      .shiftsControllerGetConsultantToShiftsMap(
        consultantIds,
        this.makeHeaders(),
      )
      .then((response) => response.data.shiftsMap)
  }

  static create(shift: CreateShiftRequest) {
    return ShiftsClient.shiftsClient
      .shiftsControllerCreate(shift, this.makeHeaders())
      .then((response) => response.data)
  }

  static createAndAttach(shift: CreateShiftRequest, consultantId: string) {
    const createAndAttachShiftRequest = {
      ...shift,
      consultantId,
    }
    return ShiftsClient.shiftsClient
      .shiftsControllerCreateAndAttachShift(
        createAndAttachShiftRequest,
        this.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static attach(shiftId: string, consultantId: string) {
    return ShiftsClient.shiftsClient
      .shiftsControllerAttach({ shiftId, consultantId }, this.makeHeaders())
      .then((response) => response.data)
  }

  static unattach(shiftId: string, consultantId: string) {
    return ShiftsClient.shiftsClient
      .shiftsControllerUnattach({ shiftId, consultantId }, this.makeHeaders())
      .then((response) => response.data)
  }

  static editShift(shiftDto: ShiftDto) {
    return ShiftsClient.shiftsClient
      .shiftsControllerEdit(shiftDto, this.makeHeaders())
      .then((response) => response.data)
  }
}
