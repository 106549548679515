import OptionsButton2, { ItemModel } from "@cmp/buttons/optionsButton2"
import { ListItemIcon, ListItemText, SvgIcon } from "@mui/material"
import AppIcon from "@utils/appIcon"
import appIcons from "@utils/appIcons"

export const CourseOptionsMenu = (props: { onClickRemove: () => void }) => {
  const { onClickRemove } = props

  const removeCourseItem: ItemModel = {
    type: "menu-item",
    props: {
      key: "remove",
      children: (
        <>
          <ListItemIcon>
            <AppIcon icon={appIcons.trash03} />
          </ListItemIcon>
          <ListItemText primary="Remove" />
        </>
      ),
      onClick: onClickRemove,
    },
  }

  const trackMenuOptions = [removeCourseItem]
  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          children: <SvgIcon inheritViewBox component={appIcons.dots} />,
        },
      }}
      items={trackMenuOptions}
    />
  )
}
