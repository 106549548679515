import { store } from "@app/store"
import { selectAccessToken } from "@features/login/loginSelectors"
import {
  Configuration,
  CreateConsultantDto,
  ConsultantApi,
  ConsultantDto,
  TeamLeadConsultants,
} from "@masterschool/course-builder-api"
export class ConsultantClient {
  private static consultantClient = new ConsultantApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  private static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static getSalesTeams(): Promise<TeamLeadConsultants[]> {
    return ConsultantClient.consultantClient
      .consultantControllerListSalesTeams(this.makeHeaders())
      .then((response) => response.data)
  }

  static create(consultantDto: CreateConsultantDto): Promise<ConsultantDto> {
    return ConsultantClient.consultantClient
      .consultantControllerCreate(consultantDto, this.makeHeaders())
      .then((response) => response.data)
  }

  static updateConsultantAvailability(
    consultantId: string,
    isAvailable: boolean,
  ): Promise<ConsultantDto> {
    return ConsultantClient.consultantClient
      .consultantControllerUpdateConsultantAvailability(
        { consultantId, isAvailable },
        this.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static updateConsultantIsPlacementExpert(
    consultantId: string,
    isPlacementExpert: boolean,
  ): Promise<ConsultantDto> {
    console.log("consultantId", consultantId)
    console.log("isPlacementExpert", isPlacementExpert)

    return ConsultantClient.consultantClient
      .consultantControllerUpdateConsultantIsPlacementExpert(
        { consultantId, isPlacementExpert },
        this.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static updateConsultantAcceptedQtfs(
    consultantId: string,
    acceptedQtfs: number[],
  ): Promise<ConsultantDto> {
    return ConsultantClient.consultantClient
      .consultantControllerUpdateConsultantAcceptedQtfs(
        { consultantId, acceptedQtfs },
        this.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static updateConsultantLanguages(
    consultantId: string,
    languages: string[],
  ): Promise<ConsultantDto> {
    return ConsultantClient.consultantClient
      .consultantControllerUpdateConsultantLanguages(
        { consultantId, languages },
        this.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static archiveConsultant(consultantId: string): Promise<boolean> {
    return ConsultantClient.consultantClient
      .consultantControllerArchiveConsultant(
        { consultantId },
        this.makeHeaders(),
      )
      .then((response) => response.data)
  }
}
