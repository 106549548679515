import { useAppDispatch } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { TrackDto } from "@masterschool/course-builder-api"
import { Stack } from "@mui/material"
import { useState } from "react"
import EditorTextField from "../../editor/components/editorTextField"
import { updateTrackMetadata } from "@features/tracks/trackSlice"
import { showSuccessSnackbar } from "@features/ui/uiSlice"

export const EditTrackDetailsPopup = (props: {
  track: TrackDto
  onClose: () => void
}) => {
  const { track, onClose } = props
  const dispatch = useAppDispatch()
  const [durationInMonths, setDurationInMonths] = useState<number | undefined>(
    track.durationInMonths,
  )
  const [displayId, setDisplayId] = useState<string | undefined>(
    track.displayId,
  )

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = () => {
    if (!durationInMonths || !displayId) {
      return
    }
    setIsLoading(true)
    return dispatch(
      updateTrackMetadata({
        trackId: track.id,
        metadata: {
          durationInMonths,
          displayId,
        },
      }),
    )
      .then(() => {
        onClose()
        dispatch(showSuccessSnackbar("Track details updated"))
      })
      .finally(() => setIsLoading(false))
  }

  const hasChanged =
    displayId !== track.displayId || durationInMonths !== track.durationInMonths

  return (
    <GenericDialog
      title="Edit track details"
      onClose={onClose}
      size="xs"
      content={
        <Stack gap={2}>
          <EditorTextField
            label="Display Id"
            value={displayId || ""}
            onChange={(e) => {
              setDisplayId(e.target.value)
            }}
          />
          <EditorTextField
            type="number"
            label="Duration in months"
            value={durationInMonths || ""}
            onChange={(e) => {
              setDurationInMonths(parseInt(e.target.value))
            }}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Stack>
      }
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: onClose,
        },
        {
          loading: isLoading,
          text: "Save",
          type: "primary",
          onClick: onSubmit,
          disabled: !hasChanged,
        },
      ]}
      open={true}
    />
  )
}
