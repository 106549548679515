import { useAppDispatch, useAppSelector } from "@app/hooks"
import Droppable from "@cmp/droppable"
import { selectIsTopicOpen } from "@features/courseEditor/courseEditorSelectors"
import {
  elementAdded,
  liveEventAdded,
  topicEdited,
  topicOpenStateChanged,
} from "@features/courseEditor/courseEditorSlice"
import {
  selectIsTopicInvalid,
  selectPublishValidationsForItem,
} from "@features/courseEditor/courseValidationsSelectors"
import {
  Topic,
  TopicItemValidationErrorsEnum,
} from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  Stack,
  SvgIcon,
  SxProps,
  Typography,
} from "@mui/material"
import SyllabusElementFactory from "@utils/syllabusElementFactory"
import { useSearchParams } from "react-router-dom"
import appTheme from "../../theme/appTheme"
import AddItemButton from "../components/addItemButton"
import CourseSyllabusElementView from "./courseSyllabusElementView"
import { CourseSyllabusLiveEventView } from "./courseSyllabusLiveEventsView"
import Placeholder from "./placeholder"
import TopicHeader from "./topicHeader"
import { doesTopicContainMandatoryItem } from "./topicValidations"
import appIcons from "@utils/appIcons"
import { Theme } from "@emotion/react"
import { calculateElementsTotalDuration } from "@utils/courseUtils"
import { durationToFormattedString } from "@utils/durationToFormattedString"
import { MultiLanguageEditorTextField } from "../components/multiLanguageFields/multiLanguageEditorTextField"

function TopicView(props: {
  topic: Topic
  availableTopics?: Topic[]
  shouldShowAsWeek: boolean
}) {
  const { topic, shouldShowAsWeek } = props
  const isOpen = useAppSelector(selectIsTopicOpen(topic.id))
  const dispatch = useAppDispatch()

  const errors = useAppSelector(selectPublishValidationsForItem(topic.id))
  const hasMandatoryItem = doesTopicContainMandatoryItem(topic)
  const editingLanguage = useAppSelector(
    (state) => state.courseEditor.editingLanguage,
  )

  return (
    <Box
      id={topic.id}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: `1px solid ${appTheme.palette.other.outlineBorder}`,
        borderRadius: "8px",
        backgroundColor: "#F5F5F0",
        boxSizing: "border-box",
        position: "relative",
        bgcolor: appTheme.palette.primary.contrast,
      }}
    >
      <TopicHeader
        topic={topic}
        onClick={() =>
          dispatch(
            topicOpenStateChanged({
              topicId: topic.id,
            }),
          )
        }
        isTopicOpen={isOpen}
        shouldShowAsWeek={shouldShowAsWeek}
      />
      <Collapse in={isOpen} sx={{ width: "100%" }}>
        <Box width="100%" py="16px">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginBottom: "16px",
            }}
          >
            <MultiLanguageEditorTextField
              label={shouldShowAsWeek ? "Week name" : "Topic name"}
              language={editingLanguage}
              defaultLanguageValue={topic.title}
              extraLanguageValueRecord={topic.extraLanguageTitleRecord}
              onChangeDefaultLanguage={(newValue: string) =>
                dispatch(
                  topicEdited({
                    editStepId: window.crypto.randomUUID(),
                    topicId: topic.id,
                    key: "title",
                    value: newValue,
                  }),
                )
              }
              onChangeExtraLanguage={(newExtraLanguageValueRecord) =>
                dispatch(
                  topicEdited({
                    editStepId: window.crypto.randomUUID(),
                    topicId: topic.id,
                    key: "extraLanguageTitleRecord",
                    value: newExtraLanguageValueRecord,
                  }),
                )
              }
              error={errors?.includes("MISSING_TITLE") && topic.title === ""}
              helperText={
                errors?.includes("MISSING_TITLE")
                  ? "This field is required"
                  : ""
              }
              multiline
              maxRows={2}
              sx={{ px: "16px" }}
            />
            <SelfLearnContainer
              topic={topic}
              availableTopics={props.availableTopics}
              error={
                errors?.includes("MISSING_MANDATORY_ITEM") && !hasMandatoryItem
              }
            />
            <LiveEventsContainer
              topic={topic}
              availableTopics={props.availableTopics}
            />
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

export default TopicView

const SelfLearnContainer = ({
  topic,
  availableTopics,
  error,
}: {
  topic: Topic
  availableTopics?: Topic[]
  error?: boolean
}) => {
  const elementsNumber = topic.elements.length ?? 0
  const duration = calculateElementsTotalDuration(topic.elements)
  const placeholder = elementsNumber === 0 ? <Placeholder /> : <></>
  return (
    <Stack gap="16px">
      <ElementsBanner title="Self-learn" duration={duration} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          px: "16px",
        }}
      >
        <Droppable
          id={`${topic.id}-elements`}
          items={topic.elements.map((e, index) => {
            return {
              identifier: e.item.id,
              element: (
                <CourseSyllabusElementView
                  element={e}
                  topic={topic}
                  index={index}
                  availableTopics={availableTopics}
                  sx={{ bgcolor: "white" }}
                />
              ),
            }
          })}
          placeholder={placeholder}
          style={(isDragging) => ({
            opacity: isDragging ? 0 : 1,
          })}
        />
      </Box>
      {error && (
        <FormHelperText
          error
          sx={{
            alignSelf: "center",
            textTransform: "none",
          }}
        >
          Add at least one mandatory item, quiz or survey.
        </FormHelperText>
      )}
      <AddItemFormButton topic={topic} sx={{ pl: "40px" }} />
    </Stack>
  )
}

const LiveEventsContainer = ({
  topic,
  availableTopics,
}: {
  topic: Topic
  availableTopics?: Topic[]
}) => {
  const elementsNumber = topic.liveEvents.length ?? 0
  const duration = topic.liveEvents.map((le) => le.estimatedDuration).msSum()
  const placeholder =
    elementsNumber === 0 ? (
      <Placeholder
        text={"Drag & drop live sessions here or click the + button below"}
      />
    ) : (
      <></>
    )
  return (
    <Stack gap="16px">
      <ElementsBanner title="Live sessions" duration={duration} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          px: "16px",
        }}
      >
        <Droppable
          id={`${topic.id}-liveEvents`}
          items={topic.liveEvents.map((le, index) => {
            return {
              identifier: le.id,
              element: (
                <CourseSyllabusLiveEventView
                  liveEvent={le}
                  topic={topic}
                  index={index}
                  availableTopics={availableTopics}
                  sx={{ bgcolor: "white" }}
                />
              ),
            }
          })}
          placeholder={placeholder}
          style={(isDragging) => ({
            opacity: isDragging ? 0 : 1,
          })}
        />
      </Box>
      <AddLiveSessionButton topic={topic} sx={{ pl: "40px" }} />
    </Stack>
  )
}

const AddItemFormButton = (props: { topic: Topic; sx?: SxProps<Theme> }) => {
  const { topic } = props
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const isInvalidBecauseMissingElements =
    useAppSelector(
      selectIsTopicInvalid(topic, TopicItemValidationErrorsEnum.ELEMENTS),
    ) && topic.elements.length === 0

  return (
    <FormControl sx={{ display: "flex", ...props.sx }}>
      <AddItemButton
        onElementClick={(type) => {
          const element = SyllabusElementFactory.makeElement(type)
          dispatch(
            elementAdded({
              editStepId: window.crypto.randomUUID(),
              topicId: topic.id,
              element: element,
            }),
          )
          searchParams.set("elementId", element.item.id)
          setSearchParams(searchParams, {
            replace: true,
          })
        }}
        onPasteClicked={(element) => {
          dispatch(
            elementAdded({
              editStepId: window.crypto.randomUUID(),
              topicId: topic.id,
              element,
            }),
          )
          searchParams.set("elementId", element.item.id)
          setSearchParams(searchParams, {
            replace: true,
          })
        }}
        onLiveEventClick={() => {
          const liveEvent = SyllabusElementFactory.makeLiveEvent()
          dispatch(
            liveEventAdded({
              editStepId: window.crypto.randomUUID(),
              topicId: topic.id,
              liveEvent: liveEvent,
            }),
          )
          searchParams.set("elementId", liveEvent.id)
          setSearchParams(searchParams, {
            replace: true,
          })
        }}
        sx={{
          color: isInvalidBecauseMissingElements
            ? appTheme.palette.error.main
            : undefined,
        }}
        iconSx={{
          stroke: isInvalidBecauseMissingElements
            ? appTheme.palette.error.main
            : appTheme.palette.icon.black,
        }}
      />
      <FormHelperText
        error={isInvalidBecauseMissingElements}
        variant="standard"
        sx={{
          textTransform: "none",
          paddingTop: "4px",
          paddingLeft: "14px",
        }}
      >
        {isInvalidBecauseMissingElements
          ? "Topic must have at least one element"
          : ""}
      </FormHelperText>
    </FormControl>
  )
}

const AddLiveSessionButton = ({
  topic,
  sx,
}: {
  topic: Topic
  sx?: SxProps<Theme>
}) => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const onLiveEventClick = () => {
    const liveEvent = SyllabusElementFactory.makeLiveEvent()
    dispatch(
      liveEventAdded({
        editStepId: window.crypto.randomUUID(),
        topicId: topic.id,
        liveEvent: liveEvent,
      }),
    )
    searchParams.set("elementId", liveEvent.id)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        ...sx,
      }}
    >
      <Button
        variant="text"
        size="small"
        onClick={onLiveEventClick}
        startIcon={
          <SvgIcon
            component={appIcons.plusCircle}
            inheritViewBox
            sx={{
              width: "20px",
              height: "20px",
              fill: "none",
              stroke: appTheme.palette.icon.black,
            }}
          />
        }
      >
        Add session
      </Button>
    </Box>
  )
}

function ElementsBanner({
  title,
  duration,
}: {
  title: string
  duration: number
}) {
  const formattedDuration = durationToFormattedString(duration)
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        p: "6px 16px",
        bgcolor: "eTypes.sand25",
        cursor: "default",
      }}
    >
      <Typography variant="body2">{title}</Typography>
      <Typography variant="body2" color={appTheme.palette.text.disabled}>
        {formattedDuration}
      </Typography>
    </Stack>
  )
}
